import { type FC, type PropsWithChildren } from 'react';

import { Breadcrumbs } from './components';
import { MainMenu } from './components';

/**
 * 'Shell' component, used throughout the entire application.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Shell: FC<PropsWithChildren> = ({ children }) => (
  <div className={'flex h-screen w-screen overflow-hidden'}>
    <MainMenu />
    <div className={'flex h-screen flex-1 flex-col gap-y-4 p-6 pr-7'}>
      <Breadcrumbs />
      <div className={'h-full'}>{children}</div>
    </div>
  </div>
);

export { Shell };
