import { type RefObject, useEffect } from 'react';

/**
 * useResizeObserver
 *
 * @author Malik Alimoekhamedov
 * @category Hooks
 * @description Observes any element and detects resizing events. Then calls the callback function.
 * @param {React.RefObject.<any>} ref Reference to the element to observe.
 * @param {Function} callback Callback function to execute upon resize.
 * @returns {void}
 * @summary ResizeObserver API hook.
 */
const useResizeObserver = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (target: HTMLElement) => void
): void => {
  useEffect(() => {
    const observable = ref.current;

    if (observable) {
      const observer = new ResizeObserver(() => callback(observable));
      observer.observe(observable);

      return () => observer.unobserve(observable);
    }
  }, [ref, callback]);
};

export { useResizeObserver };
