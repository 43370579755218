import { I18n } from 'aws-amplify/utils';
import { CLUSTERS } from 'constants/global';
import { type FC, type PropsWithChildren } from 'react';
import type { UserAccount } from 'types';

import { replacePmcWithDoi } from '../../../../../utils/replace-pmc-with-doi';
import { Category } from '../components/details/components/node/components/category';
import { buildIncidentRow, handleMSigDBProperties } from '../components/details/utils';
import type { Link, Node } from '../graph.types';
import { formatFindContext } from './format-find-context';
import { isNode } from './is-node';

const RelTable: FC<PropsWithChildren> = ({ children }) => (
  <table>
    <thead>
      <tr>
        <th>{I18n.get('Source')}</th>
        <th>{I18n.get('Relation')}</th>
        <th>{I18n.get('Destination')}</th>
      </tr>
    </thead>

    <tbody>{children}</tbody>
  </table>
);

const getGraphElementDetails = (
  element: Node | Link,
  findNode: string,
  viewedItem: Node,
  user: UserAccount,
  setCurrentItem: (item: Node | Link | null) => void,
  categoryToggle: Record<string, boolean>,
  setCategoryToggle: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
) => {
  if (element) {
    if (!isNode(element)) {
      const link = element;

      let fields: Array<JSX.Element> = [];

      for (let [key, value] of Object.entries(link)) {
        // Filter out keys
        switch (key) {
          // THESE ARE ALL OBJ / metaparameters that cannot be parsed by regex
          case 'source':
          case 'target':
          case 'weight':
          case '__indexColor':
            break;

          // PARAMETERS WITH POSSIBLE STRINGS NEED TO BE TUNED FOR UTF
          case 'id':
          case 'index':
          case '__controlPoints':
          case '__photons':
          case 'Unnamed: 0':
          case 'negated':
          case 'link':
          case 'tag':
          case 'passive':
          case 'label':
          case 'type':
          case 'kind':
          case 'rel':
          case 'confidence':
          case 'context_rel':
            //console.log("view context rel values", key, value)
            value = value.replaceAll('\\n', ' ');
            break;

          case 'context':
          case 'sentence':
            if (link.arg1 && link.arg2 && link.rel) {
              value = value.replaceAll('\\n', ' ');

              // Formatting for highlighting green in nodes
              const formattedString = formatFindContext(
                value,
                link.arg1,
                link.arg2,
                link.rel,
                findNode
              );

              const el = (
                <div className={'text-sm'}>
                  <span className={'mr-2 font-bold'}>{key}:</span>
                  <span>{formattedString}</span>
                </div>
              );

              fields.push(el);
            }

            break;

          default: {
            // REWRITING OF PATH
            if (key === 'path') {
              //console.log("value: ", value)
              key = 'link to manuscript'; //hardcoding adjustment requested by Steven
              //value = value; //removing the .txt, removed "value"

              const PMC_URL = `${replacePmcWithDoi(value)}`; // https://www.ncbi.nlm.nih.gov/labs/pmc/articles/
              //console.log("new value (pmc to doi): ", PMC_URL)
              fields.push(
                <div className={'text-sm'}>
                  <span className={'mr-2 font-bold'}> {key}: </span>
                  <a href={PMC_URL} target={'_blank'} rel={'noreferrer'}>
                    {PMC_URL}
                  </a>
                </div>
              );
              break;
            }

            // THE ARG1 AND ARG2 NEED TO ACCOUNT FOR \n AS WELL
            value = value.replaceAll('\\n', ' ');

            if (key === 'arg1') {
              key = 'source';
            }
            if (key === 'arg2') {
              key = 'destination';
            }

            const el = (
              <div className={'text-sm'}>
                <span className={'mr-2 font-bold'}>{key}:</span>
                <span>{formatFindContext(value)}</span>
              </div>
            );

            fields.push(el);
          }
        }
      }

      if ('tagged_notes' in link) {
        const element = (
          <div className={'text-sm'}>
            <span className={'font-bold'}>{I18n.get('Tagged Notes')}:</span>
            {link['tagged_notes']}
          </div>
        );

        fields = [element, ...fields];
      }

      return (
        <>
          <button onClick={(e) => e.stopPropagation()}>{fields}</button>
          <RelTable>{buildIncidentRow(link, setCurrentItem)}</RelTable>
        </>
      );
    } else if (
      user.subscriptionPlan &&
      CLUSTERS.includes(user.subscriptionPlan.toString())
    ) {
      const node: Node = viewedItem;
      const header: JSX.Element[] = [];
      let body: JSX.Element[] = [];

      // HEADERS.forEach((key: string) => {
      //     header.push(
      //         <StyledField>
      //             {" "}
      //             <span> {key}: </span> {node[key]}{" "}
      //         </StyledField>)
      // })

      header.push(<span>{node.type}</span>);

      if (
        node.type === 'Protein' ||
        node.type === 'Gene' ||
        node.type === 'Disease' ||
        node.type === 'Drug' ||
        node.type === 'Pathogen'
      ) {
        body = Object.keys(node.categories).map((catKey) => (
          <Category
            key={catKey}
            name={catKey as 'Primitive Information'}
            data={node.categories[catKey]}
          />
        ));
      } else if (
        node.type === 'NExTNet: Literature' ||
        node.type === 'Scientific Literature'
      ) {
        // body = handleNextnetLinks(node.links);
        body = [
          <div className={'w-full text-sm'} key={0}>
            {`Click on 'Relation' in the table below to dig deeper into the links between
            scientific literature nodes.`}
          </div>,
        ];
      } else if (node.type === 'Pathway') {
        body = handleMSigDBProperties(node);
      }

      if ('tagged_notes' in node) {
        const element: JSX.Element = (
          <div className={'text-sm'}>
            <span className={'font-bold'}>{I18n.get('Tagged Notes')}: </span>
            {node['tagged_notes']}
          </div>
        );

        body = [element, ...body];
      }

      return (
        <div>
          <>
            {header}
            {body}
            <RelTable>
              {node.links.map((link) => buildIncidentRow(link, setCurrentItem))}
            </RelTable>
          </>
        </div>
      );
    }
  }
};

export { getGraphElementDetails };
