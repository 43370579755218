import { Cache } from 'aws-amplify/utils';
import { PAGE_SETTINGS_DISCOVER } from 'constants/cache';

import { INITIAL_STATE } from './discover.state';
import type { State } from './discover.types';

/**
 * Discover section route loader.
 *
 * @author Malik Alimoekhamedov
 */
const loader = async () => {
  const settings: State = await Cache.getItem(PAGE_SETTINGS_DISCOVER, {
    callback: () => INITIAL_STATE,
  });

  return { settings };
};

export { loader };
