import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { forwardRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Hint } from '../hint';
import type { InputProps } from './input.types';

/**
 * 'Input' component. Single text line input component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see InputProps
 */
const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      className,
      label,
      description,
      icon,
      required = false,
      disabled,
      error,
      hint,
      onFocus,
      onBlur,
      id,
      name,
      ...inputProps
    },
    ref
  ) => {
    const [shouldDisplayHint, setShouldDisplayHint] = useState(!!hint);

    return (
      <div className={className} ref={ref}>
        {label && (
          <div className={'mb-1 flex justify-between'}>
            <label
              htmlFor={id ?? name}
              className={'select-none text-sm font-medium text-neutral-700'}
            >
              {label}
            </label>
            {!required && (
              <span className={'text-sm text-neutral-500'} id={'optional'}>
                {I18n.get('Optional')}
              </span>
            )}
          </div>
        )}

        <div className={'relative rounded-md shadow-sm'}>
          {icon && (
            <div
              className={clsx(
                'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400',
                error && 'text-red-600',
                disabled && 'text-gray-400'
              )}
            >
              {icon}
            </div>
          )}

          <input
            {...inputProps}
            id={id ?? name}
            name={id ?? name}
            className={clsx(
              'block w-full rounded-md border-none text-sm shadow-sm ring-1 ring-inset ring-gray-300 transition-colors focus:ring-2 focus:ring-blue-500',
              icon && 'pl-10',
              error &&
                'text-red-900 ring-red-300 placeholder:text-red-900 focus:ring-red-500',
              disabled && 'cursor-not-allowed bg-gray-100 placeholder:text-gray-400'
            )}
            disabled={disabled}
            onFocus={(event) => {
              onFocus && onFocus(event);
              setShouldDisplayHint(false);
            }}
            onBlur={(event) => {
              onBlur && onBlur(event);
              setShouldDisplayHint(event.target.value.trim().length === 0);
            }}
            type={inputProps.type ?? 'text'}
            tabIndex={0}
            aria-invalid={!!error}
            aria-describedby={
              (error && 'input-error') || (description && 'input-description')
            }
          />

          {error && (
            <div
              className={
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'
              }
            >
              <FiAlertCircle className={'h-5 w-5 text-red-500'} aria-hidden={'true'} />
            </div>
          )}

          {hint && shouldDisplayHint && (
            <div
              className={clsx(
                'absolute inset-y-0 right-0 flex items-center pr-3',
                disabled && 'pointer-events-none'
              )}
            >
              <Hint text={hint} />
            </div>
          )}
        </div>

        {description && !error && (
          <p
            className={'mt-2 select-none text-sm font-light text-gray-500'}
            id={'input-description'}
          >
            {description}
          </p>
        )}

        {error && (
          <p className={'mt-2 text-sm font-light text-red-600'} id={'input-error'}>
            {error}
          </p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
