import { I18n } from 'aws-amplify/utils';
import { SidePopup } from 'pages/components/side-popup';
import { type FC } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import sanitizeHTML from 'sanitize-html';

import { Button } from '../../../../../../components';
import { replacePmcWithDoi } from '../../../../utils';
import { emphasizeSearchTermsWithinAPhrase } from '../../utils';
import { Separator } from '../separator';
import type { MetadataProps } from './metadata.types';

/**
 * Opens a right side popup and fills it with metadata from scientific literature edge.
 *
 * @author Malik Alimoekhamedov, Emmanuel Alofe
 * @see MetadataProps
 */
const Metadata: FC<MetadataProps> = ({
  id,
  sentence,
  context,
  type,
  journalTitle,
  yearPublished,
  volume,
  issue,
  page,
  articleLink,
  onClose,
  isOpen,
  isPrivate = false,
  searchTerms,
}) => (
  <SidePopup
    onClose={onClose}
    isOpen={isOpen}
    title={!searchTerms ? sentence : undefined}
    titleHTML={
      searchTerms ? emphasizeSearchTermsWithinAPhrase(searchTerms, sentence) : undefined
    }
  >
    <div className={'relative flex flex-col gap-y-2'}>
      <span className={'flex flex-wrap items-center gap-2 md:gap-4'}>
        {isPrivate && (
          <p
            className={
              'relative w-fit rounded-md bg-emerald-50 px-1.5 py-0.5 text-xs font-medium capitalize text-emerald-800 ring-1 ring-inset ring-emerald-600/20'
            }
          >
            {I18n.get('Your data')}
          </p>
        )}
        <Separator
          type={type}
          journalTitle={journalTitle}
          yearPublished={yearPublished}
          volume={volume}
          issue={issue}
        />
      </span>

      <div className={'mt-2'}>
        <p
          className={
            'inline appearance-none text-sm text-neutral-500 [&>em]:bg-yellow-200 [&>em]:font-semibold [&>em]:not-italic'
          }
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(
              searchTerms
                ? emphasizeSearchTermsWithinAPhrase(searchTerms, context)
                : context
            ),
          }}
        />
        {page && <p className={'inline px-1 text-xs text-neutral-400'}>(pg. {page})</p>}
      </div>

      <div className={'mt-4'}>
        <Button
          onClick={() =>
            articleLink &&
            window.open(replacePmcWithDoi(articleLink), '_blank', 'noopener noreferrer')
          }
          icon={<FiExternalLink />}
          colorTheme={'BLUE'}
          shape={'FILLED'}
          disabled={!articleLink}
        >
          {I18n.get('Read the Article')}
        </Button>
      </div>
    </div>
  </SidePopup>
);

export { Metadata };
