import { post } from 'aws-amplify/api';
import { ConsoleLogger, I18n } from 'aws-amplify/utils';
import { type ComponentProps } from 'react';
import { toast } from 'react-hot-toast';
import {
  type EntityType,
  type GraphMetricsAPIResponse,
  SubscriptionPlan,
  type UserAccount,
} from 'types';

import { Graph } from '../components';

const logger = new ConsoleLogger('fetchGraphStatistics');

/**
 * Fetches graph statistical metadata from the 'graphmetrics' API endpoint
 * primarily used to display stats and metrics in graph's collapsible side panel.
 *
 * @author Malik Alimoekhamedov
 * @param graphData
 * @param user
 * @param datasets
 * @returns
 */
const fetchGraphStatistics = async (
  graphData: Required<ComponentProps<typeof Graph>>['graphData'],
  user: Pick<UserAccount, 'username' | 'jwtToken' | 'subscriptionPlan'>,
  datasets: ReadonlyArray<EntityType>
): Promise<GraphMetricsAPIResponse | undefined> => {
  try {
    const { body } = await toast.promise(
      post({
        apiName: 'main',
        path: '/graphmetrics',
        options: {
          body: {
            NODE_IDS: graphData.nodes.map((node) => node.id).join(','),
            EDGE_IDS: graphData.links.map((link) => link.id).join(','),
            USER_GROUP: user.subscriptionPlan ?? SubscriptionPlan.FREEMIUM_X_EDU, // TODO: Change that to FREEMIUM when we'll stop showing all nodes to everybody.
            DATASETS: datasets.join(','),
            METRICS: 'centrality,degree_info',
            USER_NAME: user.username,
          },
        },
      }).response,
      {
        error: I18n.get(
          `We couldn't collect graph statistics. You can still use the graph as is.`
        ),
        loading: I18n.get(`Loading graph statistics.`),
        success: I18n.get(
          `Graph statistics loaded. Click on the top-right toggle to see them.`
        ),
      }
    );

    const response = await body.json();

    return response && typeof response === 'object' && !Array.isArray(response)
      ? (response as unknown as GraphMetricsAPIResponse)
      : undefined;
  } catch (error) {
    logger.error(error);

    if (error instanceof Error) {
      toast.error(
        I18n.get(`An error occurred. Retry if you don't see what you're looking for.`)
      );
    }
  }
};

export { fetchGraphStatistics };
