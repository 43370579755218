import { useEffect } from 'react';

//TODO: Phase out? It's not in use.
const useOutsideAlerter = (args: { readonly ref: any; readonly onFire: any }) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (args.ref.current && !args.ref.current.contains(event.target)) {
        args.onFire();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [args]);
};

export { useOutsideAlerter };
