import {
  confirmSignUp as awsAmplifyConfirmSignUp,
  resendSignUpCode as awsAmplifyResendSignUpCode,
  resetPassword as awsAmplifyResetPassword,
  signIn as awsAmplifySignIn,
  signOut as awsAmplifySignOut,
  signUp as awsAmplifySignUp,
  updatePassword as awsAmplifyUpdatePassword,
} from 'aws-amplify/auth';
import { ConsoleLogger, I18n } from 'aws-amplify/utils';
import {
  type AuthorizationValidationForm,
  type ChangePasswordValidationForm,
  type EmailConfirmationValidationForm,
  type ForgotPasswordValidationForm,
  type ResetPasswordValidationForm,
  type SignInValidationForm,
  type SignUpValidationForm,
} from 'pages/auth/auth.validators';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { toast } from 'react-hot-toast';
import { SubscriptionPlan } from 'types';

import type { AuthContextType } from './auth.types';

/**
 * AuthProvider context
 *
 * @author Malik Alimoekhamedov
 */
const logger = new ConsoleLogger('AuthContext');
const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const signIn = async (values: SignInValidationForm, onSuccessCallback: () => void) => {
    try {
      await awsAmplifySignOut();
      await awsAmplifySignIn({
        username: values.email,
        password: values.password,
        options: { authFlowType: 'USER_PASSWORD_AUTH' },
      });

      onSuccessCallback();
    } catch (error) {
      logger.log('Error signing in', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const signUp = async (values: SignUpValidationForm, onSuccessCallback: () => void) => {
    try {
      await awsAmplifySignUp({
        username: values.email.replace('@', '_'),
        password: values.password,
        options: {
          userAttributes: {
            email: values.email,
            'custom:group_id': SubscriptionPlan.FREEMIUM,
          },
          autoSignIn: true,
        },
      });

      onSuccessCallback();
    } catch (error) {
      if (error instanceof Error) {
        logger.log('Error signing up', error);
        toast.error(error.message);
      }
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await awsAmplifySignOut();
    } catch (error) {
      logger.error('Error signing out', error);

      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const sendCode = async (
    values: EmailConfirmationValidationForm & {
      readonly email: string;
    },
    onSuccessCallback: () => void
  ) => {
    try {
      await awsAmplifyConfirmSignUp({
        confirmationCode: values.code,
        username: values.email.replace('@', '_'),
      });
      onSuccessCallback();
    } catch (error) {
      logger.error('Error sending code', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const resendCode = async (username: string, onSuccessCallback: () => void) => {
    try {
      await awsAmplifyResendSignUpCode({ username });
      onSuccessCallback();
    } catch (error) {
      logger.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const forgotPassword = async (
    values: ForgotPasswordValidationForm,
    onSuccessCallback: () => void
  ) => {
    try {
      await awsAmplifyResetPassword({ username: values.email });
      onSuccessCallback();
    } catch (error) {
      logger.error('Error forgot password', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const sendResetPasswordCode = async (
    values: ResetPasswordValidationForm,
    onSuccessCallback: () => void
  ) => {
    try {
      await awsAmplifyResetPassword(
        { username: values.email }
        /* values.code,
        values.password */
      );
      onSuccessCallback();
    } catch (error) {
      logger.error('Error sending reset password code', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const changePassword = async (
    values: ChangePasswordValidationForm,
    onSuccessCallback?: () => void
  ) => {
    try {
      const data = await awsAmplifyUpdatePassword({
        newPassword: values.newPassword,
        oldPassword: values.currentPassword,
      });
      logger.log(data);
      toast.success(I18n.get('Password successfully reset'));
      onSuccessCallback && onSuccessCallback();
    } catch (error) {
      logger.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  // User authorization from the invitation
  const authorize = async (
    values: AuthorizationValidationForm,
    onSuccessCallback: () => void
  ) => {
    const { email, password, newPassword } = values;
    try {
      await awsAmplifySignIn({
        username: email,
        password,
        options: { authFlowType: 'USER_PASSWORD_AUTH' },
      });

      //await Auth.completeNewPassword(cognitoUser, newPassword);

      await awsAmplifySignIn({
        username: email,
        password: newPassword,
        options: { authFlowType: 'USER_PASSWORD_AUTH' },
      });

      onSuccessCallback();
    } catch (error) {
      logger.error('Error authorization', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        forgotPassword,
        signIn,
        signUp,
        signOut,
        sendCode,
        resendCode,
        sendResetPasswordCode,
        changePassword,
        authorize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
