import { Disclosure } from '@headlessui/react';
import { type FC } from 'react';

import type { CategoryProps } from './category.types';

/**
 * Category component.
 *
 * @author Malik Alimoekhamedov
 * @see CategoryProps
 */
const Category: FC<CategoryProps> = ({ name, data }) => (
  <Disclosure as={'details'} className={'cursor-pointer select-none'}>
    <Disclosure.Button as={'summary'} className={'mb-2 w-full text-sm'}>
      {name}
    </Disclosure.Button>
    <Disclosure.Panel as={'dl'} className={'flex flex-col gap-y-1'}>
      {data &&
        Object.entries(data).map(([key, value]) => (
          <div
            key={key}
            className={
              'flex flex-wrap gap-x-2 rounded p-3 text-sm text-gray-600 odd:bg-gray-100'
            }
          >
            <dt className={'font-medium'}>{key}</dt>
            <dd className={'font-light'}>{value}</dd>
          </div>
        ))}
    </Disclosure.Panel>
    {/* {formatFields(catData)} */}
  </Disclosure>
);

export { Category };
