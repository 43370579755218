import { clsx } from 'clsx';
import type { FC } from 'react';

import { type CheckboxProps } from './checkbox.types';

/**
 * Checkbox component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Checkbox: FC<CheckboxProps> = ({
  disabled,
  onChange = () => undefined, // This prevents console errors.
  className,
  state = 'UNCHECKED',
  name,
  label,
}) => (
  <div className={clsx('flex w-max items-center', className)}>
    {state !== 'UNDETERMINED' ? (
      <input
        aria-describedby={`${name}-description`}
        className={clsx(
          'h-4 w-4 cursor-pointer rounded',
          state === 'CHECKED' ? 'border-blue-600' : 'border-gray-300',
          disabled && 'cursor-not-allowed border-gray-100 text-gray-400'
        )}
        checked={state === 'CHECKED'}
        disabled={disabled}
        id={name}
        onChange={onChange}
        name={name}
        type={'checkbox'}
      />
    ) : (
      /** Checkbox for the 'UNDETERMINED' status. */
      <div
        role={'checkbox'}
        aria-checked={true}
        tabIndex={0}
        className={clsx(
          'flex h-4 w-4 items-center justify-center rounded border-2 px-[1px] font-bold',
          disabled
            ? 'cursor-not-allowed border-gray-300 bg-gray-300 text-gray-300'
            : 'cursor-pointer border-blue-600 bg-blue-600 text-blue-600'
        )}
        onClick={onChange}
        onKeyUp={onChange}
      >
        <span className={'flex h-[2px] w-full rounded bg-white'} />
      </div>
    )}
    {label && (
      <label
        className={clsx(
          'truncate whitespace-nowrap px-2 text-sm',
          state === 'CHECKED' ? 'text-neutral-700' : 'text-neutral-500'
        )}
      >
        {label}
      </label>
    )}
  </div>
);

export { Checkbox };
