import './button.css'; //TODO: Switch to .module.scss?

import { clsx } from 'clsx';
import type { FC } from 'react';

import { Loader } from '../loader';
import type { ButtonProps } from './button.types';

/**
 * A typical UI-wide button component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ButtonProps
 */
const Button: FC<ButtonProps> = ({
  children,
  className,
  colorTheme = 'BLUE',
  disabled,
  icon,
  isLoading = false,
  onClick,
  shape = 'FILLED',
  type,
}) => (
  <button
    type={type}
    className={clsx(
      'btn transition-colors',
      colorTheme && `btn-${colorTheme.toLowerCase()}`,
      shape && `btn-${shape.toLowerCase()}`,
      (disabled || isLoading) && 'btn-disabled',
      className
    )}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    {(icon ?? isLoading) && (
      <div className={'flex h-5 w-5 items-center justify-center fill-current'}>
        {isLoading && <Loader color={colorTheme !== 'BLUE' ? 'primary' : 'white'} />}
        {!isLoading && icon}
      </div>
    )}
    {children}
  </button>
);

export { Button };
