import { I18n } from 'aws-amplify/utils';
import { Loader as LoaderComponent } from 'components';
import { type FC } from 'react';

/**
 * A component displayed whenever the search result's are being fetched.
 *
 * @author Malik Alimoekhamedov
 * @see LoaderProps
 */
const Loader: FC = () => (
  <div className={'flex w-full flex-1 flex-nowrap items-center justify-center gap-x-4'}>
    <LoaderComponent />
    <span className={'text-lg text-gray-600'}>{I18n.get('Searching...')}</span>
  </div>
);

export { Loader };
