import { I18n } from 'aws-amplify/utils';
import { Button, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { FiCheck } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  ResetPasswordSchema,
  type ResetPasswordValidationForm,
} from '../auth.validators';
import { Container } from '../components';
import type { ResetPasswordProps } from './reset-password.types';

const initialValues: ResetPasswordValidationForm = {
  email: '',
  code: '',
  password: '',
  repeatPassword: '',
};

/**
 * Password reset dialog.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ResetPassword
 */
const ResetPassword: FC<ResetPasswordProps> = ({ className }) => {
  const { sendResetPasswordCode } = useAuthContext();
  const navigate = useNavigate();

  //FIXME: Get rid of the forced typing once react-router-dom fixed the 'unknown' state typing issue.
  const { state } = useLocation() as { readonly state: { readonly email: string } };

  const { handleSubmit, handleChange, isSubmitting, errors, values } = useFormik({
    initialValues: { ...initialValues, email: (state?.email as string) ?? '' },
    validationSchema: ResetPasswordSchema,
    validateOnChange: false,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await sendResetPasswordCode(values, () => navigate('/auth/sign-in'));
      } catch (error) {
        if (error instanceof Error) setFieldError('email', error.message);
      }
    },
  });

  return (
    <Container className={'w-full'} title={I18n.get('Reset Password')}>
      <form className={'space-y-6 md:w-60'} onSubmit={handleSubmit}>
        <Input
          name={'email'}
          error={errors.email}
          value={values.email}
          label={I18n.get('Email')}
          onChange={handleChange}
        />
        <Input
          name={'code'}
          error={errors.code}
          value={values.code}
          label={I18n.get('Code')}
          onChange={handleChange}
          hint={I18n.get(
            'Enter the verification code that was sent to you email address.'
          )}
        />
        <Input
          type={'password'}
          name={'password'}
          error={errors.password}
          value={values.password}
          label={I18n.get('Password')}
          onChange={handleChange}
        />
        <Input
          type={'password'}
          name={'repeatPassword'}
          error={errors.repeatPassword}
          value={values.repeatPassword}
          label={I18n.get('Repeat password')}
          onChange={handleChange}
        />

        <Button
          type={'submit'}
          className={'flex w-full justify-center'}
          isLoading={isSubmitting}
          icon={<FiCheck />}
        >
          {I18n.get(isSubmitting ? 'Confirming...' : 'Confirm')}
        </Button>
      </form>
      <Link to={'/auth/sign-in'} className={'mt-6 block text-center text-blue-500'}>
        {I18n.get('Get back')}
      </Link>
    </Container>
  );
};

export { ResetPassword };
