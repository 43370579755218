import {
  Disease,
  Drug,
  Gene,
  NExTNet,
  Pathogen,
  Pathway,
  Protein,
} from 'constants/colors';
import { type ForceGraphProps } from 'react-force-graph-2d';

import type { Link, Node } from '../graph.types';
import { rgbToHex } from './rgb-to-hex';

const colorNode: ForceGraphProps<Node, Link>['nodeColor'] = ({ type, ...rest }) => {
  let color = undefined;

  switch (type) {
    case 'NExTNet: Literature':
    case 'Scientific Literature':
      color = NExTNet;
      break;

    case 'Protein':
      color = Protein;
      break;

    case 'Gene':
      color = Gene;
      break;

    case 'Pathway':
      color = Pathway;
      break;

    case 'Disease':
      color = Disease;
      break;

    case 'Drug':
      color = Drug;
      break;

    case 'Pathogen':
      color = Pathogen;
      break;

    default:
      color = [0, 0, 0];
      break;
  }

  return rgbToHex(color[0] ?? 0, color[1] ?? 0, color[2] ?? 0);
};

export { colorNode };
