import { type ReactElement } from 'react';

import type { Link, Node } from '../../../graph.types';
import { formatFindContext, formatLink, isNode } from '../../../utils';

const buildIncidentRow = (
  link: Link,
  setCurrentItem: (item: Node | Link | null) => void
): ReactElement => {
  const formattedRel = formatLink(link);

  formattedRel.forEach((text: string, idx: number) => {
    formattedRel[idx] = text.replaceAll('\\n', ' ');
  });

  const rowEl = (
    <tr>
      <th
        onClick={() => isNode(link.source) && setCurrentItem(link.source)}
        style={{ cursor: 'pointer' }}
      >
        {formatFindContext(formattedRel[0] ?? '')}
      </th>
      <th onClick={() => setCurrentItem(link)} style={{ cursor: 'pointer' }}>
        {formatFindContext(formattedRel[1] ?? '')}
      </th>
      <th
        onClick={() => isNode(link.target) && setCurrentItem(link.target)}
        style={{ cursor: 'pointer' }}
      >
        {formatFindContext(formattedRel[2] ?? '')}
      </th>
    </tr>
  );

  return rowEl;
};

export { buildIncidentRow };
