import type { ForceGraphProps } from 'react-force-graph-2d';

import type { Link, Node } from '../graph.types';
import { isNode } from './is-node';

/**
 * Get adjacent nodes for a given node in a graph.
 *
 * @param node Node to return adjacent nodes for.
 * @param graph The entire graph from which to return adjacent nodes.
 */
const getAdjacentNodes = (
  node: Node,
  graph: Required<ForceGraphProps<Node, Link>>['graphData']
) => {
  const sources = graph.links
    .filter((link) => link.target === node)
    .flatMap<Node>(({ source }) => (isNode(source) ? source : []));

  const targets = graph.links
    .filter((link) => link.source === node)
    .flatMap<Node>(({ target }) => (isNode(target) ? target : []));

  return [...sources, ...targets];
};
export { getAdjacentNodes };
