import { Tab } from '@headlessui/react';
import { loader as rootLoader } from 'app.loader';
import { Cache, ConsoleLogger, I18n } from 'aws-amplify/utils';
import { PAGE_SETTINGS_DISCOVER } from 'constants/cache';
import type { FC } from 'react';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { FiList, FiShare2 } from 'react-icons/fi';
import { useLoaderData, useRouteLoaderData } from 'react-router-typesafe';
import type { UserAccount } from 'types';
import { GraphFetcher } from 'utils';
import { type DisplayErrors, type PathwayType } from 'utils/types';
import { v4 as uuidv4 } from 'uuid';

import { GraphView, ListView, Loader, Placeholder, SearchControls } from './components';
import { graphUtils, type Link, type Node } from './components/graph-view/components';
import { isNode } from './components/graph-view/components/graph/utils';
import type { loader } from './discover.loader';
import { reducer } from './discover.state';
import type { SearchSettings } from './discover.types';

const logger = new ConsoleLogger('Discover');
const getFetcher = (userAccount: UserAccount) => new GraphFetcher(userAccount);

/**
 * The 'Discover' application section.
 *
 * @author Malik Alimoekhamedov
 * @category Pages
 * @see DiscoverProps
 */
const Discover: FC = () => {
  const { user } = useRouteLoaderData<typeof rootLoader>('root');
  const { settings } = useLoaderData<typeof loader>();
  const [state, dispatch] = useReducer(reducer, settings);

  const fetcher = getFetcher(user);

  // TODO: Phase this state out.
  const [searchSettings, setSearchSettings] = useState<SearchSettings>({
    queryStringParameters: {
      actors: state.search.query ?? '',
      expanded_nodeids: [] as Array<string>,
    },
    entityTypes: state.search.settings.scope,
    fetch_type: 'none',
    breadth: state.search.settings.breadth,
    WEIGHT_PERCENTILE_START: 5,
    WEIGHT_PERCENTILE_END: 100000,
  });

  const search = async (searchParameters: SearchSettings) => {
    dispatch({ type: 'SET_IS_SEARCHING', status: true });

    if (fetcher) {
      try {
        await fetcher.fetchFillGraph(searchParameters, () => {
          fetcher.graphData &&
            dispatch({
              type: 'SET_LATEST_SEARCH_RESULTS',
              results: {
                ...state.search.latestSearchResults,
                graphData: fetcher.graphData,
              },
            });
          dispatch({ type: 'SET_IS_SEARCHING' });
        });
      } catch (error) {
        dispatch({ type: 'SET_IS_SEARCHING' });

        if (error instanceof Error) {
          logger.error(error.message);
          toast.error(error.message);
        }
      }
    }
  };

  // Persist search parameters in the browser cache.
  useEffect(() => {
    // The reason we don't store latest search results is because the
    // payload returned is way too big right now.
    Cache.setItem(PAGE_SETTINGS_DISCOVER, {
      ...state,
      search: { ...state.search, latestSearchResults: undefined, isSearching: undefined },
    });
  }, [state]);

  const [currentItem, setCurrentItem] = useState<Node | Link>();

  const [mergeButtonState, setMergeButtonState] = useState(false); // Used to detect whenever the merge button is pressed
  const [displayErrors, setDisplayErrors] = useState<DisplayErrors>({
    ERROR: false,
    error: '',
  });
  // Setup so that in the event of an error, we can propagate a description
  // to the frontend to display on the search pane
  const [mergeNodes, setMergeNodes] = useState<Array<Node>>([]);

  const [pathways, setPathways] = useState<Array<PathwayType> | null | number>(null); // number used for -1 case in the event of no pathways

  const [deletingNode, setDeletingNode] = useState<Node | undefined>(undefined);
  const [expandingNode, setExpandingNode] = useState<Node | undefined>(undefined);

  // TODO: Refactor.
  const handleSearch = () => {
    if (state.search.query.length > 0) {
      setMergeNodes([]);

      const enrichedSearchParameters = {
        ...searchSettings,
        queryStringParameters: {
          expanded_nodeids: [],
          actors: state.search.query,
        },
        entityTypes: state.search.settings.scope,
        breadth: state.search.settings.breadth,
        fetch_type: 'QUERY',
      };

      setSearchSettings(enrichedSearchParameters);
      search(enrichedSearchParameters);
    }
  };

  const sidePaneRefresh = () => {
    setPathways(null);
  };

  const handleNodeDelete = (node: Node) => {
    if (!deletingNode && state.search.latestSearchResults?.graphData) {
      setDeletingNode(node);

      //const graphDataCopy = Object.assign({}, graphData);

      const filteredLinks = state.search.latestSearchResults.graphData.links.filter(
        (link) =>
          graphUtils.isNode(link.source) &&
          link.source.id !== node.id &&
          graphUtils.isNode(link.target) &&
          link.target.id !== node.id
      );

      const filteredNodes = state.search.latestSearchResults.graphData.nodes.map(
        (node) => ({
          ...node,
          neighbors: node.neighbors.filter((neighbor) => neighbor !== node),
          links: node.links.filter(
            (link) =>
              graphUtils.isNode(link.source) &&
              link.source.id !== node.id &&
              graphUtils.isNode(link.target) &&
              link.target.id !== node.id
          ),
        })
      );

      const nodesWithNeighborsAndLinks = filteredNodes.filter(
        ({ neighbors, links }) => neighbors.length > 0 && links.length > 0
      );

      // graphDataCopy.nodes = graphDataCopy.nodes.filter((n) => [new Set(n.neighbors)].length !== 1 || n.neighbors[0] !== node)
      const remainingNodes = nodesWithNeighborsAndLinks.filter((n) => n.id !== node.id);

      dispatch({
        type: 'SET_LATEST_SEARCH_RESULTS',
        results: {
          ...state.search.latestSearchResults,
          graphData: { nodes: remainingNodes, links: filteredLinks },
        },
      });
      sidePaneRefresh();
      setDeletingNode(undefined);
    }
  };

  const handleCtrlNodeClick = (node: Node) => {
    // Augment existing graph data with new search data
    // let searchSettingsCopy = Object.assign({}, searchSettings);
    // PAIN
    const searchSettingsCopy = searchSettings;

    searchSettingsCopy.queryStringParameters.expanded_nodeids.push(`${node.id}`); // searchSettingsCopy.queryStringParameters.nodeids = [`${node.id}`];
    const actors = searchSettingsCopy.queryStringParameters.actors.split(';');
    console.log(actors);
    actors.push(node.name);
    const newSearchTerm = actors.join('; ');
    searchSettingsCopy.queryStringParameters.actors = newSearchTerm;
    //searchSettingsCopy.fetch_type = 'EXPANSION';
    setSearchSettings(searchSettingsCopy);

    // Prevents spamming of multiple search and expands
    if (expandingNode) {
      return;
    }

    setExpandingNode(node);

    // Fetches the graph with a callback only replacing what is necessary
    try {
      const loadingToastId = toast.loading(
        I18n.get("Sit tight. We're loading more results...")
      );

      fetcher?.fetchGraph(
        searchSettingsCopy,
        () => {
          // Get nodes that don't exist in current data nodes
          const newNodes = fetcher.graphData?.nodes.filter(
            (node) =>
              state.search.latestSearchResults?.graphData?.nodes.findIndex(
                (element) => node.id === element.id
              ) === -1
          );

          // And the same for the links...
          const newLinks = fetcher.graphData?.links.filter(
            (link) =>
              state.search.latestSearchResults?.graphData?.links.findIndex(
                (element) => link.id === element.id
              ) === -1
          );

          const graphDataCopy = Object.assign(
            {},
            state.search.latestSearchResults?.graphData
          );

          graphDataCopy.nodes = newNodes
            ? graphDataCopy.nodes.concat(newNodes)
            : graphDataCopy.nodes;

          // Fix links
          const existingNodes = graphDataCopy.nodes;
          newLinks?.forEach((link: Link) => {
            const existingSource = existingNodes.find(
              (n) => graphUtils.isNode(link.source) && n.id === link.source.id
            );
            const existingTarget = existingNodes.find(
              (n) => graphUtils.isNode(link.target) && n.id === link.target.id
            );

            if (existingSource && existingTarget) {
              link.source = existingSource;
              link.target = existingTarget;
            }
          });

          graphDataCopy.links = newLinks
            ? graphDataCopy.links.concat(newLinks)
            : graphDataCopy.links;

          graphDataCopy.links.forEach((link) => {
            const sourceNode = link.source;
            const targetNode = link.target;

            if (graphUtils.isNode(sourceNode) && graphUtils.isNode(targetNode)) {
              sourceNode.neighbors.push(targetNode);
              sourceNode.links.push(link);

              if (sourceNode.id !== targetNode.id) {
                targetNode.neighbors.push(sourceNode);
                targetNode.links.push(link);
              }
            }
          });

          dispatch({
            type: 'SET_LATEST_SEARCH_RESULTS',
            results: {
              ...state.search.latestSearchResults,
              graphData: graphDataCopy,
            },
          });

          const newCurrentNode = graphDataCopy.nodes.find((n) => n.id === node.id);
          if (newCurrentNode !== undefined) {
            setCurrentItem(newCurrentNode);
          }

          sidePaneRefresh();
          setExpandingNode(undefined);
          toast.dismiss(loadingToastId);
        },
        () => {
          //setSearchErrored(true);
          setExpandingNode(undefined);
        }
      );
    } catch (error) {
      logger.error(error);

      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  // These two popovers indicate the popovers for the tagging and merging functions
  const [tagPopover, setTagPopover] = useState(false);
  const [mergePopover, setMergePopver] = useState(false);

  const hidePopovers = () => {
    setTagPopover(false);
    setMergePopver(false);
  };

  const handleMergeButton = () => {
    hidePopovers();
    if (mergeNodes.length < 2) {
      setDisplayErrors({
        ...displayErrors,
        ERROR: true,
        error: 'Merge must include at least two selected nodes.',
      });
    } else {
      setDisplayErrors({ ...displayErrors, ERROR: false, error: '' });
      setMergePopver(!mergePopover);
    }
  };

  const handleMergeSubmit = async (name: string) => {
    hidePopovers();
    let popoverInput = name;

    if (popoverInput === '') {
      //no char entry
      popoverInput = 'Untitled';
    } else if (popoverInput === null) {
      //no entry (cancel)
      return;
    }
    //console.log(popoverInput);

    const mergedNode = Object.create({});

    const sharedType =
      mergeNodes[0]?.type.substring(0, mergeNodes[0]?.type.indexOf(':')) + ': Merged';

    mergedNode.name = popoverInput;
    mergedNode.kind = 'node';
    mergedNode.id = uuidv4();
    mergedNode.neighbors = [];
    mergedNode.links = [];
    mergedNode.weight = 10; // HARD CODED FOR NOW
    mergedNode.type = sharedType;

    mergedNode.merged = true;
    mergedNode.nodelist = []; // array of node metadata, only necessary for merged true
    for (const node of mergeNodes) {
      if (node.merged === true) {
        for (const n of node.nodelist) {
          mergedNode.nodelist.push(n);
        }
      } else {
        const newNode = Object.create({});
        for (const [key, value] of Object.entries(node)) {
          newNode[key] = value;
        }
        mergedNode.nodelist.push(newNode);
      }
    }

    let avgX = 0;
    let avgY = 0;

    const nodeset = new Set();
    const nodelist = [];
    const neighborset = new Set();
    const linklist = [];

    for (const node of mergeNodes) {
      nodeset.add(node);
      nodelist.push(node);
      linklist.push(...node.links);
      avgX += node?.x ?? 0;
      avgY += node?.y ?? 0;
    }

    mergedNode.x = avgX / mergeNodes.length;
    mergedNode.y = avgY / mergeNodes.length;

    for (const node of nodelist) {
      for (const neighbor of node.neighbors) {
        if (!nodeset.has(neighbor)) {
          // If our nodeset does not have the neighbor, not part of selection group
          // It must be an immediate neighbor
          neighborset.add(neighbor);
        }
      }
    }

    // let linkset = new Set(linklist);

    // const nodeMap: Map<Node, Number> = new Map();
    // const linkMap: Map<Link, Number> = new Map();

    // maps nodes and links to their indices for O(1) access when splicing
    const newGraphNodes: Node[] = [];
    const newLinks: Link[] = [];

    // Whenever we call methods on the original object (.item or .push) we act on the reference of this object
    // If you change the internals, the object will change
    // But setting the parameter itself, it will not change
    //TODO: This is very convoluted. I need to understand why we're doing this and rewrite accordingly.
    /* graphData?.links.forEach((link, idx) => {
      if (nodeset.has(link.source) && nodeset.has(link.target)) {
        return; //equivalent to continue in a foreach
      } else if (nodeset.has(link.source)) {
        graphData.links[idx].source = mergedNode;
        graphData.links[idx].arg1 = popoverInput;
        mergedNode.links.push(graphData.links[idx]);
      } else if (nodeset.has(link.target)) {
        graphData.links[idx].target = mergedNode;
        graphData.links[idx].arg2 = popoverInput;
        mergedNode.links.push(graphData.links[idx]);
      }
      newLinks.push(graphData.links[idx]); //pushing a reference to original link object
    }); */

    state.search.latestSearchResults?.graphData?.nodes.forEach((node, idx) => {
      if (!nodeset.has(node)) {
        if (neighborset.has(node)) {
          //if node is in the neighbors of our selection group
          const newNeighborNodes: Node[] = [];
          // let newLinks: Link[] = [];
          // node.neighbors.push(node) NEW
          mergedNode.neighbors.push(node);

          for (const neighbor of node.neighbors) {
            if (!nodeset.has(neighbor)) {
              // we want to include only neighbors not in our selection group
              //node.neighbors.push(neighbor); NEW
              newNeighborNodes.push(neighbor);
            }
          }

          newNeighborNodes.push(mergedNode);
          //node.neighbors = newNeighborNodes;
          newGraphNodes.push({ ...node, neighbors: newNeighborNodes });
        }
      }
    });

    // graphData.links = newLinks; // Removing the unnecessary links
    // graphData.nodes = newGraphNodes; // Removing the unnecessary nodes

    newGraphNodes.push(mergedNode);

    dispatch({
      type: 'SET_LATEST_SEARCH_RESULTS',
      results: {
        ...state.search.latestSearchResults,
        graphData: { nodes: newGraphNodes, links: newLinks },
      },
    });
    // NOTE YOU CANNOT DIRECTLY SET FROM THE STATE!

    // Handles backend merging

    // eslint-disable-next-line no-prototype-builtins
    /* const inGROUP =
      session.signInUserSession.idToken.payload.hasOwnProperty('cognito:groups'); */
    /* const GROUP_ID =
      inGROUP &&
      session.signInUserSession.idToken.payload['cognito:groups'].includes('FREEMIUM')
        ? 'FREEMIUM'
        : inGROUP &&
          session.signInUserSession.idToken.payload['cognito:groups'].includes(
            'FREEMIUM_X_EDU'
          )
        ? 'FREEMIUM_X_EDU'
        : ''; */
    //const USER_NAME = session.username;

    const NODE_ID: Array<string | number | undefined> = [];

    mergeNodes.forEach((node, idx) => {
      NODE_ID.push(node.id);
    });
    //console.log(mergeNodes);

    /* const PAYLOAD = {
      CLUSTER: GROUP_ID,
      USER_GROUP: GROUP_ID,
      USER_NAME: USER_NAME,
      NODE_ID: NODE_ID,
      MODE: 'merging',
      TAG_TEXT: popoverInput,
      // "DESCRIPTION": `${description}`
    }; */

    //console.log(PAYLOAD);

    /* const response = await fetch(API + 'graph/tag', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        Authorization: `${session.signInUserSession.idToken.jwtToken}`,
      },
      body: JSON.stringify(PAYLOAD),
    }); */
    //const mergeReponse = await response.json();
    //console.log(mergeReponse);
    setMergeNodes([]);
  };

  const handleAddToMerge = (node: Node) => {
    //handles the logic behind selection of two nodes and combining them
    setDisplayErrors({ ...displayErrors, ERROR: false, error: '' }); //base case, removes the merge error

    if (mergeNodes.includes(node)) {
      // if node already included, we remove it
      const newMergeNodes = [...mergeNodes];
      newMergeNodes.splice(newMergeNodes.indexOf(node), 1);
      setMergeNodes(newMergeNodes);
    } else {
      setMergeNodes((mergeNodes) => [...mergeNodes, node]);
    }

    // Implementation changed, where we require that setMergeNodes works regardless of type differences

    // else if (mergeNodes.length === 0) { // if no items in mergeNodes, we add the node
    //     setMergeNodes(mergeNodes => [...mergeNodes, node])
    // }
    // else {
    //     // Checking type to ensure only nodes of the same type can be merged
    //     let top = mergeNodes[0]
    //     if (top.type.substring(0, top.type.indexOf(":")) === node.type.substring(0, node.type.indexOf(":"))) {
    //         setMergeNodes(mergeNodes => [...mergeNodes, node])
    //     }
    //     else {
    //         setDisplayErrors({ ...displayErrors, ERROR: true, error: 'Merge can only include nodes of the same type.' })
    //     }
    // }
  };

  const handleTagNode = () => {
    hidePopovers();
    const node = currentItem;
    if (!isNode(node?.kind)) {
      setDisplayErrors({
        ...displayErrors,
        ERROR: true,
        error: 'Node must be currently opened in details for tagging.',
      });
      return;
    } else {
      setDisplayErrors({ ...displayErrors, ERROR: false, error: '' });
      setTagPopover(!tagPopover);
    }
  };

  const handleTagSubmit = async (description: string) => {
    hidePopovers();
    const node = currentItem;
    //const NODE_ID = node?.id;
    //const NODE_NAME = '';

    // if (node?.merged === false) {
    //     NODE_ID.push(node.id)
    //     NODE_NAME = "single: " + node.name
    // }
    // else {
    //     const mergednodes : Array<Node> = node?.nodelist
    //     for (let n of mergednodes) {
    //         NODE_ID.push(n.id)
    //     }
    //     NODE_NAME = "merged: " + node?.name
    // }

    // TODO -> Make a database call to retrieve user cluster group and internal group

    const USER_NAME = user?.email;

    /* const PAYLOAD = {
      CLUSTER: GROUP_ID,
      USER_GROUP: GROUP_ID,
      USER_NAME: USER_NAME,
      NODE_ID: NODE_ID,
      MODE: 'tagging',
      TAG_TEXT: description,
    }; */

    if (node) {
      node['user_group_tagged_by'] = user?.subscriptionPlan;
      node['tagged_notes'] = description;
      node['user_tagged_by'] = USER_NAME;
    }

    /* const response = await fetch(API + 'graph/tag', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        Authorization: `${session.signInUserSession.idToken.jwtToken}`,
      },
      body: JSON.stringify(PAYLOAD),
    }); */
    //const tagResponse = await response.json();
    //console.log(PAYLOAD);
    //console.log(tagResponse);
  };

  // Used to propagate the information about the currently examined nodes in stats
  // and the currently viewed metric
  const [statsHighlightNodes, setStatsHighlightNodes] = useState<any>(null);

  const showUploadedGraphData = (json: any) => {
    setMergeNodes([]);
    const searchSettingsCopy = JSON.parse(
      JSON.stringify(json.searchSettings, (_, v) => (v !== undefined ? v : null))
    );
    searchSettingsCopy.fetch_type = 'UPLOAD';
    setSearchSettings(searchSettingsCopy);

    // Handles searching and retrieving of graphData
    //setSearchErrored(false);
    dispatch({ type: 'SET_IS_SEARCHING', status: true });
    setStatsHighlightNodes(null);
    //reduces the highlighted nodes to null to not show from previous list views
    sidePaneRefresh();

    // //console.log(searchSettings);
    /* fetcher.fetchNodeEdgeInfo(
      json,
      () => {
        setGraphData(fetcher.graphData);
        dispatch({ type: 'SET_IS_SEARCHING', status: false })
      },
      () => {
        // if error occurs when graph is fetched, try fetching graph again before moving to error() function
        fetcher.fetchNodeEdgeInfo(
          json,
          () => {
            setGraphData(fetcher.graphData);
            dispatch({ type: 'SET_IS_SEARCHING', status: false })
          },
          () => {
            //setSearchErrored(true);
            dispatch({ type: 'SET_IS_SEARCHING', status: false })
          }
        );
      }
    ); */
  };

  return (
    <Tab.Group as={'section'} className={'flex h-full flex-1 flex-col gap-y-4'}>
      <div className={'flex flex-none justify-between'}>
        <SearchControls
          onChange={(parameters) =>
            dispatch({ type: 'SET_SEARCH_PARAMETERS', parameters })
          }
          onSearch={handleSearch}
          search={state.search}
          isLoading={state.search.isSearching}
        />
        {state.search.latestSearchResults?.graphData && (
          <Tab.List
            className={
              'flex flex-none justify-end  gap-x-1 text-xs font-medium text-neutral-500'
            }
          >
            <Tab
              key={'graph'}
              className={
                'flex items-center gap-x-2 rounded-md px-4 py-2.5 transition-colors hover:text-neutral-400 focus:outline-none ui-selected:text-neutral-800 ui-selected:shadow-lg ui-selected:ring-1 ui-selected:ring-inset ui-selected:ring-neutral-100'
              }
            >
              <FiShare2 />
              <span className={'select-none uppercase'}>{I18n.get('Graph')}</span>
            </Tab>
            <Tab
              key={'list'}
              className={
                'flex items-center gap-x-2 rounded-md px-4 py-2.5 transition-colors hover:text-neutral-400 focus:outline-none ui-selected:text-neutral-800 ui-selected:shadow-lg ui-selected:ring-1 ui-selected:ring-inset ui-selected:ring-neutral-100'
              }
            >
              <FiList />
              <span className={'select-none uppercase'}>{I18n.get('List')}</span>
            </Tab>
          </Tab.List>
        )}
      </div>
      {state.search.isSearching ? (
        <Loader />
      ) : state.search.latestSearchResults?.graphData ? (
        <Tab.Panels className={'h-full'}>
          <Tab.Panel className={'h-full'}>
            <GraphView
              searchSettings={searchSettings}
              graphData={state.search.latestSearchResults?.graphData}
              handleNodeDelete={handleNodeDelete}
              mergeButtonState={mergeButtonState}
              mergeNodes={mergeNodes} // list of nodes to merge
              handleAddToMerge={handleAddToMerge} // handles validation of node types before appending to the list
              handleMergeButton={handleMergeButton}
              handleMergeSubmit={handleMergeSubmit}
              handleTagNode={handleTagNode}
              handleTagSubmit={handleTagSubmit}
              mergePopover={mergePopover}
              statsHighlightNodes={statsHighlightNodes}
              expandingNode={expandingNode}
              deletingNode={deletingNode}
              onExpandNode={(node) => handleCtrlNodeClick(node)}
            />
          </Tab.Panel>
          <Tab.Panel className={'h-full'}>
            <ListView
              className={'h-[calc(100%-16px-24px-24px)]'}
              items={state.search.latestSearchResults?.graphData.links
                .filter(
                  ({ type }) =>
                    type === 'Scientific Literature' || type === 'NExTNet: Literature'
                )
                .map((literature) => ({
                  id: literature.id?.toString() ?? literature.name,
                  context: literature.context,
                  type: literature.label,
                  sentence: literature.sentence,
                  journalTitle: literature.journal_title,
                  yearPublished: literature.year_published,
                  volume: literature.volume,
                  issue: literature.issue,
                  page: literature.page,
                  articleLink: literature.path,
                }))}
              searchTerms={state.search.query.split(';').map((term) => term.trim())}
            />
          </Tab.Panel>
        </Tab.Panels>
      ) : (
        <Placeholder />
      )}
    </Tab.Group>
  );
};

export { Discover };
