import { Popover, Transition } from '@headlessui/react';
import type { loader } from 'app.loader';
import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { Button } from 'components';
import { Payment } from 'pages/utils';
import { type FC, Fragment } from 'react';
import { FiAward, FiBook, FiFileText, FiLogIn, FiLogOut, FiUser } from 'react-icons/fi';
import { Link, NavLink, redirect, useFetcher } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { SubscriptionPlan } from 'types';

import type { UserMenuProps } from './user-menu.types';

/**
 * User menu with a typical set of settings such as logout or account information.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const UserMenu: FC<UserMenuProps> = ({
  className,
  setIsTutorialOpen,
  setCardPage,
  isCollapsed,
}) => {
  const { user } = useRouteLoaderData<typeof loader>('root');
  const fetcher = useFetcher();

  const handleSignIn = () => redirect('/auth/sign-in');

  return (
    <Popover className={clsx('relative', className)}>
      <Popover.Button
        className={clsx(
          'group flex w-full select-none items-center py-2 text-neutral-600 transition-all duration-500 hover:text-neutral-400 focus:outline-none',
          isCollapsed ? 'px-2' : 'px-4'
        )}
        onClick={handleSignIn}
        onKeyUp={handleSignIn}
      >
        {user ? (
          <>
            <FiUser className={'m-2 flex-none opacity-70 transition-all duration-500'} />
            <span
              className={clsx(
                'overflow-hidden text-ellipsis whitespace-nowrap py-2 text-left text-sm transition-all duration-500 group-hover:translate-x-1 group-focus:rounded-md group-focus:text-blue-500 group-focus:ring-2 group-focus:ring-inset group-focus:ring-blue-500',
                isCollapsed ? 'w-0 px-0 opacity-0' : 'w-36 px-3 opacity-100'
              )}
            >{`${user.firstName ?? user.email}`}</span>
          </>
        ) : (
          <NavLink
            to={'/sign-in'}
            className={clsx(
              'flex flex-nowrap items-center gap-x-4 transition-all duration-500 group-hover:translate-x-1 group-hover:text-neutral-400',
              isCollapsed ? 'w-0 opacity-0' : 'w-36 opacity-100'
            )}
            title={I18n.get('Sign In')}
          >
            <FiLogIn className={'m-2 flex-none opacity-70'} />
            <span className={'text-sm'}>{I18n.get('Sign In')}</span>
          </NavLink>
        )}
      </Popover.Button>
      {user && (
        <Transition
          as={Fragment}
          enter={'transition ease-out duration-200'}
          enterFrom={'opacity-0 translate-y-1'}
          enterTo={'opacity-100 translate-y-0'}
          leave={'transition ease-in duration-150'}
          leaveFrom={'opacity-100 translate-y-0'}
          leaveTo={'opacity-0 translate-y-1'}
        >
          <Popover.Panel
            className={
              'absolute bottom-0 left-full ml-1 grid h-[calc(160px*3)] w-[calc(160px*2)] transform grid-cols-2 grid-rows-3 divide-x divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-neutral-100/60 [&>*:hover]:bg-neutral-50 [&>*]:flex [&>*]:cursor-pointer [&>*]:flex-col [&>*]:items-center [&>*]:justify-center [&>*]:gap-y-4 [&>*]:p-4'
            }
          >
            <div
              className={
                'col-span-2 bg-gradient-to-br from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%'
              }
            >
              <span className={'text-5xl font-black uppercase text-white/40'}>
                {user.subscriptionPlan === SubscriptionPlan.FREEMIUM_X_EDU
                  ? I18n.get('Academic')
                  : I18n.get('Basic Free')}
              </span>
              {user.subscriptionPlan !== SubscriptionPlan.FREEMIUM_X_EDU && (
                <Button
                  icon={<FiAward />}
                  colorTheme={'GRAY'}
                  onClick={() => {
                    user && new Payment(user).createCheckoutSession();
                  }}
                >
                  {I18n.get('Go PRO')}
                </Button>
              )}
            </div>
            <NavLink
              to={'/account'}
              className={'text-neutral-600 transition hover:text-neutral-400'}
            >
              <FiUser className={'h-10 w-10 stroke-1'} />
              <span className={'text-sm'}>{I18n.get('Account')}</span>
            </NavLink>
            <button className={'text-neutral-600 transition hover:text-neutral-400'}>
              <FiBook className={'h-10 w-10 stroke-1'} />
              <span className={'text-sm'}>{I18n.get('Tutorial')}</span>
            </button>
            <Link
              to={'https://getnextnet.com/tou'}
              target={'_blank'}
              className={'text-neutral-600 transition hover:text-neutral-400'}
            >
              <FiFileText className={'h-10 w-10 stroke-1'} />
              <span className={'whitespace-nowrap text-sm'}>
                {I18n.get('Terms & Conditions')}
              </span>
            </Link>
            <fetcher.Form method={'post'} action={'/auth/sign-out'}>
              <button
                className={
                  'flex flex-col items-center gap-y-4 text-neutral-600 transition hover:text-neutral-400'
                }
                type={'submit'}
              >
                <FiLogOut className={'h-10 w-10 stroke-1'} />
                <span className={'text-sm'}>{I18n.get('Sign Out')}</span>
              </button>
            </fetcher.Form>
            {/*  <button
              onClick={signOut}
              className={'text-neutral-600 transition hover:text-neutral-400'}
            >
              <FiLogOut className={'h-10 w-10 stroke-1'} />
              <span className={'text-sm'}>{I18n.get('Sign Out')}</span>
            </button> */}
          </Popover.Panel>
        </Transition>
      )}
    </Popover>
  );
};

export { UserMenu };
