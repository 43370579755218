import { I18n } from 'aws-amplify/utils';
import { Button, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { FiCheck } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import {
  ForgotPasswordSchema,
  type ForgotPasswordValidationForm,
} from '../auth.validators';
import { Container } from '../components';
import type { ForgotPasswordProps } from './forgot-password.types';

const initialValues: ForgotPasswordValidationForm = {
  email: '',
};

/**
 * Password recovery dialog.
 *
 * @author Malik Alimoekhamedov
 * @see ForgotPasswordProps
 */
const ForgotPassword: FC<ForgotPasswordProps> = ({ className }) => {
  const { forgotPassword } = useAuthContext();
  const navigate = useNavigate();
  const { handleChange, handleSubmit, values, errors, isSubmitting } = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await forgotPassword(values, () =>
          navigate('/auth/reset-password', { state: { email: values.email } })
        );
      } catch (error) {
        if (error instanceof Error)
          setFieldError('email', I18n.get('The user with this email does not exist.'));
      }
    },
    validateOnChange: false,
  });

  return (
    <Container title={I18n.get('Forgot Password?')}>
      <form className={'space-y-6'} onSubmit={handleSubmit}>
        <Input
          name={'email'}
          label={I18n.get('Email')}
          value={values.email}
          error={errors.email}
          required
          description={I18n.get(
            'A new verification code will be sent to this email address.'
          )}
          onChange={handleChange}
          autoComplete={'email'}
        />

        <Button
          className={'flex w-full justify-center'}
          type={'submit'}
          isLoading={isSubmitting}
          icon={<FiCheck />}
        >
          {I18n.get(isSubmitting ? 'Submitting...' : 'Submit')}
        </Button>
      </form>
      <Link to={'/auth/sign-in'} className={'mt-6 block text-center text-blue-500'}>
        {I18n.get('Back to sign in')}
      </Link>
    </Container>
  );
};

export { ForgotPassword };
