import { Dialog, Transition } from '@headlessui/react';
import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { Slider } from 'pages/components';
import { type FC, Fragment } from 'react';
import { FiSettings, FiX } from 'react-icons/fi';

import { Button as CircularButton } from '../button';
import type { SettingsProps } from './settings.types';

/**
 * Graph settings panel.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Settings: FC<SettingsProps> = ({
  className,
  isOpen,
  onTogglePanel,
  onClose,
  repulsion,
  onRepulsionChange,
  nodeDistanceMax,
  onNodeDistanceMaxChange,
}) => (
  <div className={className}>
    <div className={'relative flex justify-end'}>
      <CircularButton
        icon={<FiSettings />}
        onClick={onTogglePanel}
        title={I18n.get('Settings')}
      />
      <Transition show={isOpen} as={Fragment}>
        <Dialog as={'div'} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter={'ease-out duration-300'}
            enterFrom={'opacity-0 scale-95'}
            enterTo={'opacity-100 scale-100'}
            leave={'ease-in duration-200'}
            leaveFrom={'opacity-100 scale-100'}
            leaveTo={'opacity-0 scale-95'}
          >
            <Dialog.Panel
              className={
                'absolute bottom-11 right-11 flex w-64 select-none flex-col gap-y-3 rounded-md bg-neutral-50/70 p-3 shadow-lg ring-1 ring-inset ring-neutral-100 backdrop-blur'
              }
            >
              <span className={'flex flex-nowrap items-center justify-between'}>
                <FiSettings className={'mx-3 text-neutral-500'} />
                <Dialog.Title className={'font-semibold'}>
                  {I18n.get('Graph Settings')}
                </Dialog.Title>
                <Button
                  shape={'BORDERLESS'}
                  colorTheme={'GRAY'}
                  icon={<FiX />}
                  onClick={onClose}
                />
              </span>
              <Slider
                className={'rounded bg-white p-4 ring-1 ring-inset ring-neutral-200/60'}
                label={I18n.get('Nodes Repulsion')}
                hint={I18n.get(
                  'The lower the number, the more the nodes repel each other.'
                )}
                min={-2000}
                max={0}
                value={repulsion}
                onChange={onRepulsionChange}
              />
              <Slider
                className={'rounded bg-white p-4 ring-1 ring-inset ring-neutral-200/60'}
                label={I18n.get('Maximum Distance')}
                hint={I18n.get('The farthest nodes can be pulled away from each other.')}
                min={0}
                max={2000}
                value={nodeDistanceMax}
                onChange={onNodeDistanceMaxChange}
              />
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  </div>
);

export { Settings };
