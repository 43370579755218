import { clsx } from 'clsx';
import { useResizeObserver } from 'hooks';
import { type FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import ForceGraph2D, {
  type ForceGraphMethods,
  type ForceGraphProps,
} from 'react-force-graph-2d';
import { FiPenTool } from 'react-icons/fi';

import {
  AnnotationDialog,
  Button,
  Controls,
  Details,
  Legend,
  SearchWithin,
  Settings,
} from './components';
import { INITIAL_STATE, reducer } from './graph.state';
/*
Reference Notes:

let highlighted = node.neighbors.slice(); slice to get a copy of neighbors
highlighted.push(node); add the current node to the highlighted list

Method to control outer nodes linked to current node (Pavan's request for fading)

setHoveredNode(node.id); <-- hoverednode involves the red selection

Hover can control the color of the node
*/
import type { GraphProps, Link, Node } from './graph.types';
import {
  colorNode,
  formatLink,
  getAdjacentNodes,
  isNode,
  paintLink,
  paintNode,
} from './utils';

/**
 * Expressed in ms.
 */
const ANIMATION_DURATION = 750;
const ZOOM_LEVEL_MIN = 0.1;
const ZOOM_LEVEL_MAX = 3;

/**
 * 2D knowledge graph component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see GraphProps
 */
const Graph: FC<GraphProps> = ({
  graphData,
  mergeNodes,
  mergeButtonState,
  handleAddToMerge,
  onNodeClick,
  handleNodeDelete,
  onLinkClick,
  statsHighlightNodes,
  expandingNode,
  deletingNode,
  hoveredElement,
  queriedNodeIds,
  onToggleView,
  onDownload,
  onUpload,
  onSave,
  onExpandNode,
  focusedNode,
  className,
  ...props
}) => {
  const graphRef = useRef<ForceGraphMethods<Node, Link>>();
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE, data: graphData });
  const [ctrlDown, setCtrlDown] = useState(false);
  const [shiftDown, setShiftDown] = useState(false);
  const [delDown, setDelDown] = useState(false);
  const [treeData, setTreeData] = useState<typeof graphData>();

  useEffect(() => {
    focusedNode && dispatch({ type: 'SET_FOCUSED_ELEMENT', focusedElement: focusedNode });
  }, [focusedNode]);

  useEffect(() => {
    // Don't use React.KeyboardEvent here- won't compile!
    //event.key === "Delete" ||

    const onDelDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        setDelDown(true);
        //console.log("delete key pressed")
      }
    };

    const onDelUp = (event: KeyboardEvent) => {
      if (!(event.key === 'Backspace')) {
        setDelDown(false);
        //console.log("delete key not pressed")
      }
    };

    const onCtrlDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        setCtrlDown(true);
      }
    };

    const onCtrlUp = (event: KeyboardEvent) => {
      if (!event.ctrlKey) {
        setCtrlDown(false);
      }
    };

    const onShiftDown = (event: KeyboardEvent) => {
      if (!shiftDown && event.shiftKey) {
        setShiftDown(true);
      }
    };

    const onShiftUp = (event: KeyboardEvent) => {
      if (!event.shiftKey) {
        setShiftDown(false);
      }
    };

    document.addEventListener('keydown', onCtrlDown);
    document.addEventListener('keyup', onCtrlUp);
    document.addEventListener('keydown', onShiftDown);
    document.addEventListener('keyup', onShiftUp);
    document.addEventListener('keydown', onDelDown);
    document.addEventListener('keyup', onDelUp);

    return () => {
      document.removeEventListener('keydown', onCtrlDown);
      document.removeEventListener('keyup', onCtrlUp);
      document.removeEventListener('keydown', onShiftDown);
      document.removeEventListener('keyup', onShiftUp);
      document.removeEventListener('keydown', onDelDown);
      document.removeEventListener('keyup', onDelUp);
    };
  }, [shiftDown]);

  // function imgNode(
  //     nodeObject: NodeObject,
  //     color: string | undefined,
  //     ctx: CanvasRenderingContext2D,
  //     globalscale: number
  // ): void {
  //     // const image = new Image();
  //     // image.src = require('../../assets/protein.png')

  //     // image.onload = (res) => {
  //     //     //console.log("res", res);
  //     //     ctx.drawImage(image, 0, 0, 50, 50)
  //     // };

  //     let node = nodeObject as Node;
  //     const MAX_NEIGHBORS = 6;
  //     const MIN_SIZE = 8;
  //     const MAX_SIZE = 18;
  //     let NODE_SIZE = Math.max(Math.min(Math.log(node.neighbors.length + 1) / MAX_NEIGHBORS, 1) * MAX_SIZE * 1.25, MIN_SIZE);

  //     var img = new Image();
  //     img.onload = () => {
  //         // //console.log('test0')
  //         if (node.x && node.y) {
  //             // //console.log('test1')

  //             // ctx.beginPath();
  //             // ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
  //             // ctx.fillStyle = "green";
  //             // ctx.fill();

  //             ctx.drawImage(img, node.x, node.y, 50, 50)
  //         }
  //     }
  //     img.src = 'https://static.thenounproject.com/png/3393327-200.png'

  //     // if (node.x && node.y){
  //     //     ctx.beginPath();
  //     //     ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
  //     //     ctx.fillStyle = "green";
  //     //     ctx.fill();
  //     // }
  // }

  const highlightAdjacentNodes = useCallback(
    (node: Node) => {
      if (state.data) {
        const adjacentNodes = getAdjacentNodes(node, state.data);
        dispatch({ type: 'SET_HIGHLIGHTED_NODES', nodes: [...adjacentNodes, node] });
        dispatch({ type: 'SET_HIGHLIGHTED_LINKS', links: node.links });
        dispatch({ type: 'SET_HOVERED_ELEMENT', hoveredElement: node });
      }
    },
    [state.data]
  );

  const highlightLinkNeighbors = useCallback((link: Link) => {
    const source = link.source;
    const target = link.target;

    if (isNode(source) && isNode(target)) {
      dispatch({ type: 'SET_HIGHLIGHTED_NODES', nodes: [source, target] });
      dispatch({ type: 'SET_HIGHLIGHTED_LINKS', links: [link] });
    }
  }, []);

  useEffect(() => {
    //TODO: Can't we simply use graph's 'centerAt' callback?
    const centerOnLink = ({ source, target }: Link) => {
      const forceGraph = graphRef.current;

      if (isNode(source) && isNode(target)) {
        if (source.x && source.y && target.x && target.y) {
          const x = (source.x + target.x) / 2;
          const y = (source.y + target.y) / 2;
          /* const d = Math.sqrt(
            Math.pow(source.x - target.x, 2) + Math.pow(source.y - target.y, 2)
          ); */

          if (forceGraph) {
            forceGraph.centerAt(x, y, ANIMATION_DURATION);
            //forceGraph.zoom(110 / d, ANIMATION_DURATION);
          }
        }
      }
    };

    if (state.focusedElement && graphRef.current) {
      if (isNode(state.focusedElement)) {
        graphRef.current.centerAt(
          state.focusedElement.x,
          state.focusedElement.y,
          ANIMATION_DURATION
        );
        highlightAdjacentNodes(state.focusedElement);

        ctrlDown && delDown && handleNodeDelete(state.focusedElement);
      } else {
        centerOnLink(state.focusedElement);
        highlightLinkNeighbors(state.focusedElement);
      }
    }
  }, [
    ctrlDown,
    delDown,
    handleNodeDelete,
    highlightLinkNeighbors,
    highlightAdjacentNodes,
    state.focusedElement,
  ]);

  const handleNodeHover: ForceGraphProps<Node, Link>['onNodeHover'] = (node) => {
    if (node) {
      dispatch({ type: 'SET_HOVERED_ELEMENT', hoveredElement: node });
      highlightAdjacentNodes(node);
    } else if (!state.focusedElement) {
      dispatch({ type: 'SET_HOVERED_ELEMENT' });
      dispatch({ type: 'SET_HIGHLIGHTED_NODES' });
      dispatch({ type: 'SET_HIGHLIGHTED_LINKS' });
    }
  };

  const handleLinkHover: ForceGraphProps<Node, Link>['onLinkHover'] = (link) => {
    if (link) {
      dispatch({ type: 'SET_HOVERED_ELEMENT', hoveredElement: link });
      highlightLinkNeighbors(link);
    } else if (!state.focusedElement) {
      dispatch({ type: 'SET_HOVERED_ELEMENT' });
      dispatch({ type: 'SET_HIGHLIGHTED_NODES' });
      dispatch({ type: 'SET_HIGHLIGHTED_LINKS' });
    }
  };

  const handleLinkLabel = (link: Link) => {
    const rel = formatLink(link);
    return `${rel[0]} [${rel[1]}] ${rel[2]}`;
  };

  const handleNodeClick = (node: Node, event?: MouseEvent) => {
    dispatch({ type: 'SET_FOCUSED_ELEMENT', focusedElement: node });
    //onNodeClick && event && onNodeClick(node, event);
    ctrlDown && onExpandNode(node);
  };

  const handleLinkClick = (link: Link, event?: MouseEvent) => {
    dispatch({ type: 'SET_FOCUSED_ELEMENT', focusedElement: link });
    onLinkClick && event && onLinkClick(link, event);
  };

  const handleNodeRightClick = (node: Node, event: MouseEvent) => {
    if (state.view === 'TREE') {
      // Find the node from the original graph data with the same ID
      const equivalentNode = state.data?.nodes.find(({ id }) => id === node.id);

      if (equivalentNode) {
        node = equivalentNode;
      }
    }

    // BFS
    const nodes: Array<Node> = [];
    const treeLinks: Array<Link> = [];

    const nodeQueue: Array<Node> = [];
    const visitedSet: Set<typeof node.id> = new Set();

    nodeQueue.push(node);
    visitedSet.add(node.id);
    nodes.push({ ...node });

    while (nodeQueue.length !== 0) {
      const tempNode = nodeQueue.shift();

      if (tempNode) {
        // DAG doesn't like to play nice if we consider neighbors in an undirected fashion
        // Of course, that is the correct way to do things, but it wouldn't be so useful
        // So we give it an extra edge in the opposite direction if it needs it
        const bidirectionalLinks: Array<Link> = [];

        tempNode.links.forEach((link) => {
          bidirectionalLinks.push(link);

          const reverseLink = { ...link };
          reverseLink.source = link.target;
          reverseLink.target = link.source;
          reverseLink.id = link.id + 'REV';

          bidirectionalLinks.push(reverseLink);
        });

        bidirectionalLinks.forEach((link) => {
          const neighbor = link.target as Node;

          if (
            neighbor.id !== tempNode.id &&
            (link.source as Node).id !== (link.target as Node).id
          ) {
            if (!visitedSet.has(neighbor.id)) {
              const neighborCopy = { ...neighbor };
              neighborCopy.neighbors = [];
              neighborCopy.links = [];
              nodes.push(neighborCopy);

              treeLinks.push({ ...link });

              visitedSet.add(neighbor.id);
              nodeQueue.push(neighbor);
            }
          }
        });
      }
    }

    // Rebuild node neighbors and links
    const treeNodeMap: Map<typeof node.id, Node> = new Map();

    // Used to propagate to the details pane information about the examined tree
    const currentTreeNodes: Array<typeof node.id> = [];

    for (const n of nodes) {
      treeNodeMap.set(n.id as string, { ...n, neighbors: [], links: [] });
      currentTreeNodes.push(n.id);
    }

    treeLinks.forEach((l) => {
      if (isNode(l.source) && isNode(l.target)) {
        const newSource = treeNodeMap.get(l.source.id);
        const newTarget = treeNodeMap.get(l.target.id);

        if (newSource && newTarget) {
          l.source = newSource;
          l.target = newTarget;

          const sourceNode = l.source;
          const targetNode = l.target;

          sourceNode.neighbors.push(targetNode);
          sourceNode.links.push(l);

          if (sourceNode.id !== targetNode.id) {
            targetNode.neighbors.push(sourceNode);
            targetNode.links.push(l);
          }
        }
      }
    });

    setTreeData({
      nodes: nodes,
      links: treeLinks,
    });

    dispatch({ type: 'TOGGLE_VIEW', view: 'TREE' });
    graphRef.current?.d3ReheatSimulation();
    // Adding a brief delay since loading the tree view sometimes misplaces the onclick
    //setTimeout(() => onNodeClick && onNodeClick(nodeCopy, event), 10);
    onNodeClick && onNodeClick({ ...node }, event);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useResizeObserver(containerRef, (element) =>
    setSize({
      width: element.getBoundingClientRect().width,
      height: element.getBoundingClientRect().height,
    })
  );

  // Tweak graph's force engine.
  useEffect(() => {
    graphRef.current?.d3Force('charge')?.strength(state.settings.repulsion);
    graphRef.current?.d3Force('charge')?.distanceMax(state.settings.nodeDistanceMax);
    graphRef.current?.d3ReheatSimulation();
  }, [state.settings.nodeDistanceMax, state.settings.repulsion]);

  return (
    <div ref={containerRef} className={clsx('relative flex h-full w-full', className)}>
      <ForceGraph2D
        {...props}
        ref={graphRef}
        width={size.width}
        height={size.height}
        graphData={state.view === 'GRAPH' ? state.data : treeData}
        nodeColor={colorNode}
        backgroundColor={'#fdfdfd'}
        nodeVal={(node) =>
          getAdjacentNodes(
            node,
            state.view === 'GRAPH'
              ? state.data
                ? state.data
                : { nodes: [], links: [] }
              : treeData
                ? treeData
                : { nodes: [], links: [] }
          ).length ?? 0
        }
        onBackgroundClick={() => dispatch({ type: 'SET_FOCUSED_ELEMENT' })}
        /* onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }} */
        onNodeClick={handleNodeClick}
        onLinkClick={handleLinkClick}
        onNodeHover={handleNodeHover}
        onLinkHover={handleLinkHover}
        //onNodeRightClick={handleNodeRightClick}
        //dagLevelDistance={250}
        minZoom={ZOOM_LEVEL_MIN}
        maxZoom={ZOOM_LEVEL_MAX}
        //onZoom={(zoom) => dispatch({ type: 'SET_ZOOM', zoom })}
        //autoPauseRedraw={true}
        dagMode={state.view === 'TREE' ? 'lr' : undefined}
        d3AlphaDecay={0.03}
        d3VelocityDecay={0.8}
        cooldownTime={15000}
        linkWidth={(link) => (state.highlightedLinks?.includes(link) ? 5 : 1)}
        linkLabel={handleLinkLabel}
        linkColor={paintLink}
        nodeCanvasObject={(node, ctx, globalScale) =>
          paintNode({
            ctx,
            focusNodeOnHover: true,
            globalScale,
            highlightNodes: state.highlightedNodes,
            mergeNodes,
            node,
            graph:
              state.view === 'GRAPH'
                ? state.data ?? { links: [], nodes: [] }
                : treeData ?? { links: [], nodes: [] },
            queriedNodeIds: queriedNodeIds,
            statsHighlightNodes,
            deletingNode,
            expandingNode,
            hoveredElement,
            isGraphTagged: state.settings.TAGGED,
          })
        }
      />
      <Legend className={'absolute right-24 top-4 z-10'} />
      <div className={'absolute left-4 top-4 z-10 flex flex-nowrap gap-x-4'}>
        <SearchWithin
          onChange={(searchQuery) => {
            if (searchQuery.length > 0) {
              const matchingNodes = state.data?.nodes.filter(({ name }) =>
                name.toLowerCase().includes(searchQuery.toLowerCase())
              );
              dispatch({ type: 'SET_HIGHLIGHTED_NODES', nodes: matchingNodes });
            } else {
              dispatch({ type: 'SET_HIGHLIGHTED_NODES' });
            }
          }}
        />
        {isNode(state.focusedElement) && (
          <>
            <Button
              icon={<FiPenTool />}
              onClick={() => dispatch({ type: 'TOGGLE_ANNOTATION_PANEL' })}
            />
            <AnnotationDialog
              open={state.isAnnotationPanelOpen}
              onClose={() => dispatch({ type: 'TOGGLE_ANNOTATION_PANEL' })}
              onSubmit={(annotation) => console.log(annotation)} //TODO: Save for state.focusedElement.
              isLoading={false}
            />
          </>
        )}
      </div>
      <Settings
        className={'absolute bottom-11 right-6 z-10'}
        isOpen={state.settings.isGraphSettingsPanelOpen}
        onTogglePanel={() =>
          dispatch({
            type: 'TOGGLE_GRAPH_SETTINGS_PANEL',
            isGraphSettingsPanelOpen: true,
          })
        }
        onClose={() =>
          dispatch({
            type: 'TOGGLE_GRAPH_SETTINGS_PANEL',
            isGraphSettingsPanelOpen: false,
          })
        }
        repulsion={state.settings.repulsion}
        onRepulsionChange={(value) =>
          dispatch({
            type: 'SET_SETTINGS',
            settings: { ...state.settings, repulsion: value },
          })
        }
        nodeDistanceMax={state.settings.nodeDistanceMax}
        onNodeDistanceMaxChange={(value) =>
          dispatch({
            type: 'SET_SETTINGS',
            settings: { ...state.settings, nodeDistanceMax: value },
          })
        }
      />
      <Controls
        className={'absolute bottom-11 left-1/2 z-10 -translate-x-1/2'}
        onDownload={onDownload}
        onReframe={() => graphRef.current?.zoomToFit(ANIMATION_DURATION, 50)}
        onSave={onSave}
        onUpload={onUpload}
        onZoomIn={() => {
          const k = state.zoom.k >= ZOOM_LEVEL_MAX ? ZOOM_LEVEL_MAX : state.zoom.k + 0.5;
          graphRef.current?.zoom(k, ANIMATION_DURATION);
          dispatch({ type: 'SET_ZOOM', zoom: { ...state.zoom, k } });
        }}
        onZoomOut={() => {
          const k = state.zoom.k <= ZOOM_LEVEL_MIN ? ZOOM_LEVEL_MIN : state.zoom.k - 0.5;
          graphRef.current?.zoom(k, ANIMATION_DURATION);
          dispatch({ type: 'SET_ZOOM', zoom: { ...state.zoom, k } });
        }}
      />
      {state.focusedElement && (
        <Details
          item={state.focusedElement}
          /* onDelete={() =>
            isNode(state.focusedElement) && handleNodeDelete(state.focusedElement)
          } */
          onClose={() => dispatch({ type: 'SET_FOCUSED_ELEMENT' })}
          onNodeClick={handleNodeClick}
          onLinkClick={handleLinkClick}
          onNavigateForward={
            state.history &&
            state.focusedElement &&
            state.history.indexOf(state.focusedElement) < state.history.length - 1
              ? () =>
                  state.history &&
                  state.focusedElement &&
                  state.history.indexOf(state.focusedElement) <
                    state.history.length - 1 &&
                  dispatch({
                    type: 'HISTORY_SWITCH',
                    element:
                      state.history[state.history.indexOf(state.focusedElement) + 1] ??
                      state.focusedElement,
                  })
              : undefined
          }
          onNavigateBackward={
            state.history &&
            state.focusedElement &&
            state.history.indexOf(state.focusedElement) > 0
              ? () =>
                  state.history &&
                  state.focusedElement &&
                  state.history.indexOf(state.focusedElement) > 0 &&
                  dispatch({
                    type: 'HISTORY_SWITCH',
                    element:
                      state.history[state.history.indexOf(state.focusedElement) - 1] ??
                      state.focusedElement,
                  })
              : undefined
          }
        />
      )}
    </div>
  );
};

export { Graph, ZOOM_LEVEL_MIN, ZOOM_LEVEL_MAX };
