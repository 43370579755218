import { I18n } from 'aws-amplify/utils';

import type { Node } from '../../../graph.types';
import { formatFields } from './format-fields';

const formatMSigDB = (MSigString: string) => {
  const tmp = MSigString.split(', ');
  const MSigArr = [];

  for (const gene of tmp) {
    MSigArr.push({ link: `${gene}`, gene: `${gene}, ` }); // `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`
  }

  const MSigMap = MSigArr.map(({ link, gene }) => (
    <a key={gene} href={link} target={'_blank'} rel={'noreferrer'}>
      {gene}
    </a>
  ));

  return MSigMap;
};

const handleMSigDBProperties = (node: Node) => {
  if ('genes' in node && 'source' in node) {
    const { genes, source } = node;

    const body: JSX.Element[] = [];
    const id = 'gene';

    body.push(
      <div className={'text-sm'}>
        <span className={'font-bold'}>{I18n.get('source')}:</span>
        <a href={source} target={'_blank'} rel={'noreferrer'}>
          {source}
        </a>
      </div>
    );

    body.push(
      <div>
        <button className={'flex content-center items-center'}>
          <div className={'w-full text-sm'}>{I18n.get('genes')}</div>
        </button>
        <div className={'test-sm'}>
          <button onClick={(e) => e.stopPropagation()}>
            {formatMSigDB(genes.replace(/'/g, '').slice(1, -1))}
          </button>
        </div>
      </div>
    );

    return body;
  }
  // More generalized handling built for detailed MSIGDB nodes (Gamma 3-2)
  else {
    const { categories } = node;

    return Object.keys(categories).map((catKey: string) => (
      <div key={catKey}>
        <div>
          <button className={'flex content-center items-center'}>
            <div className={'w-full text-sm'}>{`${catKey}`}</div>
          </button>
          <div
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            {Object.values(formatFields(categories[catKey])).map(
              ({ value, link }, index) =>
                link ? (
                  <a key={index} href={link}>
                    {value}
                  </a>
                ) : (
                  <span key={index}>{value}</span>
                )
            )}
          </div>
        </div>
      </div>
    ));
  }
};

export { handleMSigDBProperties };
