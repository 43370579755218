import { Disclosure } from '@headlessui/react';
import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { Button } from 'components';
import { type FC } from 'react';
import { FiCrosshair } from 'react-icons/fi';

import { Gauge } from './components';
import { type NodeListItemProps } from './node-list-item.types';

/**
 * NodeList component.
 *
 * @author Malik Alimoekhamedov
 * @see NodeListProps
 */
const NodeListItem: FC<NodeListItemProps> = ({
  className,
  name,
  description,
  values,
  onRevealInGraph,
}) => (
  <Disclosure as={'details'} className={clsx('group', className)}>
    <Disclosure.Button
      as={'summary'}
      className={'flex w-full items-center justify-start gap-x-2'}
    >
      <span
        className={'cursor-pointer select-none text-base font-medium text-neutral-600'}
      >
        {name}
      </span>
      <span className={'font-light text-neutral-500'}>({values.value})</span>
      <Gauge className={'ml-auto w-1/3 justify-self-end'} {...values} />
    </Disclosure.Button>
    <Disclosure.Panel>
      {description && <p className={'mt-1 text-sm text-neutral-500'}>{description}</p>}
      {onRevealInGraph && (
        <Button
          className={
            'invisible ml-auto justify-self-end whitespace-nowrap group-hover:visible'
          }
          icon={<FiCrosshair />}
          shape={'BORDERLESS'}
          colorTheme={'GRAY'}
          onClick={() => onRevealInGraph(name)}
        >
          {I18n.get('Reveal in Graph')}
        </Button>
      )}
    </Disclosure.Panel>
  </Disclosure>
);

export { NodeListItem };
