import { type ComponentProps } from 'react';
import type { EntityType, GraphMetricsAPIResponse } from 'types';

import type { Statistics } from '../components';

/**
 * Map 'graphmetrics' API response to the 'categories' prop of the 'Stats' component.
 *
 * @author Malik Alimoekhamedov
 * @param nodeMetrics The 'node_metrics' part of the 'graphmetrics' API endpoint fetch call response.
 */
const parseGraphMetricsAPIResponse = (
  nodeMetrics: GraphMetricsAPIResponse['node_metrics'],
  onRevealInGraph: (nodeId: string) => void
): ComponentProps<typeof Statistics>['categories'] => {
  const centrality: ComponentProps<typeof Statistics>['categories']['centrality'] =
    Object.entries(nodeMetrics).map((entity) => ({
      name: entity[0] as EntityType,
      description: entity[0],
      nodes: entity[1].centrality?.map((record) => ({
        name: record.name,
        description: record.name,
        values: { entityType: entity[0] as EntityType, value: record.metric, outOf: 100 },
        onRevealInGraph,
      })),
    }));

  //@ts-ignore
  const degree: ComponentProps<typeof Statistics>['categories']['degree'] =
    Object.entries(nodeMetrics).map((entity) => ({
      name: entity[0] as EntityType,
      description: entity[0],
      nodes:
        entity[1].degree &&
        Object.entries(entity[1].degree).map((record) => ({
          name: record[1],
          description: record[1],
          values: {
            entityType: record[0],
            value: record[1],
            outOf: 100,
          },
          onRevealInGraph,
        })),
    }));

  //@ts-ignore
  const degreeIn: ComponentProps<typeof Statistics>['categories']['degreeIn'] =
    Object.entries(nodeMetrics).map((entity) => ({
      name: entity[0] as EntityType,
      description: entity[0],
      nodes:
        entity[1].in_degree &&
        Object.entries(entity[1].in_degree).map((record) => ({
          name: record[1],
          description: record[1],
          values: {
            entityType: record[0],
            value: record[1],
            outOf: 100,
          },
          onRevealInGraph,
        })),
    }));

  //@ts-ignore
  const degreeOut: ComponentProps<typeof Statistics>['categories']['degreeOut'] =
    Object.entries(nodeMetrics).map((entity) => ({
      name: entity[0] as EntityType,
      description: entity[0],
      nodes:
        entity[1].out_degree &&
        Object.entries(entity[1].out_degree).map((record) => ({
          name: record[1],
          description: record[1],
          values: {
            entityType: record[0],
            value: record[1],
            outOf: 100,
          },
          onRevealInGraph,
        })),
    }));

  return { centrality, degree, degreeIn, degreeOut };
};

export { parseGraphMetricsAPIResponse };
