import { Listbox } from '@headlessui/react';
import type { FC } from 'react';
import { FiCheck } from 'react-icons/fi';

import type { ItemProps } from './item.types';

/**
 * Listbox item component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ItemProps
 */
const Item: FC<ItemProps> = ({ title, value }) => (
  <Listbox.Option
    className={
      'flex cursor-pointer list-none flex-nowrap items-center gap-x-2 px-4 py-2 font-normal text-neutral-700 transition-colors ui-selected:cursor-not-allowed ui-selected:font-medium ui-active:bg-neutral-100 ui-active:text-neutral-500'
    }
    value={value}
  >
    <span className={'select-none whitespace-nowrap'}>{title}</span>
    <FiCheck
      className={'hidden h-5 w-5 text-neutral-400 ui-selected:block'}
      aria-hidden={'true'}
    />
  </Listbox.Option>
);

export { Item };
