import type { GraphData } from 'react-force-graph-2d';
import type { APIResponseFill } from 'types';

import type { Link, Node } from '../graph.types';
import { radialArrange } from '.';

/**
 * Allow for the "impedance matching" between what comes from the backend
 * and what is expected as arguments to render a graph.
 *
 * @author Malik Alimoekhamedov
 */
const mapRawDataToGraphData = ({
  edges,
  nodes,
}: APIResponseFill): GraphData<Node, Link> => {
  /* nodes.forEach((node) => {
    const newNode = Object.create({});

    for (const [key, value] of Object.entries(node)) {
      // hotfix, later rendering does not like it if values are objects (ex. details)
      // if (typeof key === 'object') {
      //     newNode[key] = JSON.stringify(value);
      // }
      // else {
      //     newNode[key] = value;
      // }

      newNode[key] = value;
    }

    newNode.kind = 'node';
    newNode.merged = false; // flag we implement to detect if merged or not
    newNode.id = node.id;
    newNode.neighbors = [];
    newNode.links = [];
    newNode.name = node.name;
    newNode.type = node.type; //controls the color here

    graphData.nodes.push(newNode);
  }); */

  const mappedNodes: Array<Node> = nodes.map(({ weight, ...rest }) => ({
    ...rest,
    val: weight,
    kind: 'node', //TODO: Unnecessary?
    merged: false, //TODO: Find out what this means.
    neighbors: [], //TODO: Unnecessary?
    links: [], //TODO: Unnecessary?
  }));

  // Construct nodeMap. This is for (roughly) O(1) access
  // Hash with its ID and type- this is because node id's can be the same across types
  /* graphData.nodes.forEach((node) => {
    nodeMap.set(node.id, node);
  }); */

  /* edges.forEach((edge) => {
    const sourceNode = nodeMap.get(edge.source);
    const targetNode = nodeMap.get(edge.target);

    const newLink = Object.create({});

    if (sourceNode && targetNode) {
      // this copies all link entries
      for (const [key, value] of Object.entries(edge)) {
        newLink[key] = value;
      }

      // we overwrite the link entries here
      newLink.kind = 'link';
      newLink.id = edge.id;
      newLink.name = edge.name; //NEED TO INTRODUCE EDGE NAMES IN PAYLOAD
      newLink.source = sourceNode;
      newLink.target = targetNode;
      newLink.type = edge.type;

      graphData.links.push(newLink);
    }
  }); */

  const mappedLinks: Array<Link> = edges.map((edge) => {
    return {
      ...edge,
      kind: 'link', //TODO: Unnecessary?
    };
  });

  /* graphData.links.forEach((link) => {
    linkMap.set(link.id, link);
  }); */

  /* for (const link of graphData.links) {
    const sourceNode: Node = link.source;
    const targetNode: Node = link.target;
    sourceNode.neighbors.push(targetNode);
    sourceNode.links.push(link);

    if (sourceNode.id !== targetNode.id) {
      targetNode.neighbors.push(sourceNode);
      targetNode.links.push(link);
    }
  } */

  // Let's find n
  const CLUSTER_CUTOFF = 5; // If a node has more neighbors than this, it counts as a cluster
  const clusterNodes: Array<Node> = [];

  mappedNodes.forEach((node) => {
    if (node.neighbors.length > CLUSTER_CUTOFF) {
      clusterNodes.push(node);
    }
  });

  clusterNodes.sort((a, b) => {
    if (a.type > b.type) {
      return -1;
    } else if (a.type < b.type) {
      return 1;
    } else {
      return 0;
    }
  });

  radialArrange(400, [0, 0], clusterNodes, true);

  clusterNodes.forEach((cluster) => {
    if (!!cluster.fx && !!cluster.fy) {
      radialArrange(50, [cluster.fx, cluster.fy], cluster.neighbors, false);
    }
  });

  return { nodes: mappedNodes, links: mappedLinks };
};

export { mapRawDataToGraphData };
