import { entityTypes } from 'constants/global';
import type { Reducer } from 'react';

import type { Action, State } from './discover.types';

const INITIAL_STATE = {
  search: {
    query: '',
    settings: { breadth: 300, scope: entityTypes },
    isSearching: false,
  },
  display: { view: 'LIST' },
} satisfies State;

/**
 * The 'Discover' application section state manager.
 *
 * @author Malik Alimoekhamedov
 * @category State
 */
const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return action.state ?? INITIAL_STATE;
    case 'SET_SEARCH_PARAMETERS':
      return { ...state, search: action.parameters ?? INITIAL_STATE.search };
    case 'SET_IS_SEARCHING': {
      const newState = {
        ...state,
        search: { ...state.search, isSearching: action.status ?? false },
      };

      return newState;
    }
    case 'SET_LATEST_SEARCH_RESULTS':
      return {
        ...state,
        search: { ...state.search, latestSearchResults: action.results },
      };
    default:
      return state;
  }
};

export { INITIAL_STATE, reducer };
