import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { Button } from 'components';
import { type FC } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { formatLink, isNode } from '../../../../utils';
import { Default, Literature, Pathway } from './components';
import type { NodeProps } from './node.types';

const Node: FC<NodeProps> = (props) => (
  <>
    <div>
      {(props.type === 'Disease' ||
        props.type === 'Protein' ||
        props.type === 'Drug' ||
        props.type === 'Gene' ||
        props.type === 'Pathogen') && <Default categories={props.categories} />}
      {props.type === 'Pathway' && (
        <Pathway
          categories={props.categories}
          genes={props.genes}
          source={props.source}
        />
      )}
      {(props.type === 'NExTNet: Literature' ||
        props.type === 'Scientific Literature') && (
        <Literature categories={props.categories} doiReferences={props.doiReferences} />
      )}
    </div>
    {props.links && (
      <table
        className={
          'mt-6 w-full border-collapse divide-y divide-gray-100 border border-gray-100 bg-white text-left'
        }
      >
        <thead>
          <tr className={'bg-gray-100'}>
            <th className={'select-none px-2 py-1 text-sm font-medium text-gray-600'}>
              {I18n.get('Source')}
            </th>
            <th
              className={
                'select-none px-2 py-1 text-center text-sm font-medium text-gray-600'
              }
            >
              {I18n.get('Connection')}
            </th>
            <th className={'select-text px-2 py-1 text-sm font-medium text-gray-600'}>
              {I18n.get('Destination')}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.links.map((link, index) => {
            const formattedRel = formatLink(link); //TODO: What is this garbage?

            return (
              <tr key={index} className={'even:bg-gray-50'}>
                <th
                  className={clsx(
                    'px-2 py-1 text-sm font-normal',
                    isNode(link.source) && props.id !== link.source.id
                      ? 'cursor-pointer text-gray-900 hover:text-gray-400'
                      : 'cursor-not-allowed text-gray-300'
                  )}
                  onClick={() =>
                    isNode(link.source) &&
                    props.id !== link.source.id &&
                    props.onNodeClick(link.source)
                  }
                >
                  {formattedRel[0]}
                </th>
                <th className={'px-2 py-1 text-sm font-normal text-gray-400'}>
                  {isNode(link.source) && props.id !== link.source.id ? (
                    <Button
                      colorTheme={'GRAY'}
                      shape={'BORDERLESS'}
                      icon={<FiArrowLeft />}
                      onClick={() => props.onLinkClick(link)}
                    >
                      {I18n.get('Inbound')}
                    </Button>
                  ) : (
                    <Button
                      colorTheme={'GRAY'}
                      shape={'BORDERLESS'}
                      icon={<FiArrowRight />}
                      onClick={() => props.onLinkClick(link)}
                    >
                      {I18n.get('Outbound')}
                    </Button>
                  )}
                </th>
                <th
                  className={clsx(
                    'px-2 py-1 text-sm font-normal',
                    isNode(link.target) && props.id !== link.target.id
                      ? 'cursor-pointer text-gray-900 hover:text-gray-400'
                      : 'cursor-not-allowed text-gray-300'
                  )}
                  onClick={() =>
                    isNode(link.target) &&
                    props.id !== link.target.id &&
                    props.onNodeClick(link.target)
                  }
                >
                  {formattedRel[2]}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    )}
  </>
);

export { Node };
