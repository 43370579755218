import type { Link } from '../graph.types';

const formatLink = (link: Link): Array<string> => {
  // If adjacent nodes are both LITERATURE nodes
  // if(link.source.type.search('LITERATURE:') !== -1 && link.target.type.search('LITERATURE:') !== -1) {
  //     return [link.arg1 , link.rel , link.arg2];
  // }

  // return [formatNode(link.source)  , link.name , formatNode(link.target)];
  return [
    link.arg1 ? link.arg1.replaceAll('_', ' ').replaceAll('MSigDB.', '') : '',
    (link.rel ? link.rel : link.name).replaceAll('_TO_', ' to '),
    link.arg2,
  ];
};

export { formatLink };
