import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { type FC } from 'react';
import { FiEdit } from 'react-icons/fi';

import { PasswordManagement } from './components';

/**
 * User account page.
 *
 * @author Malik Alimoekhamedov
 * @see AccountProps
 */
const Account: FC = () => (
  <div>
    <div className={'mb-4 flex items-center justify-between'}>
      <h1
        className={
          'text-2xl font-semibold leading-7 text-neutral-900 sm:truncate sm:text-3xl sm:tracking-tight'
        }
      >
        {I18n.get('Account')}
      </h1>
      <Button icon={<FiEdit />}>{I18n.get('Edit')}</Button>
    </div>
    <PasswordManagement />
  </div>
);

export { Account };
