import { type FC } from 'react';

import { Category } from '../category';
import type { DefaultProps } from './default.types';

const Default: FC<DefaultProps> = ({ categories }) =>
  Object.entries(categories).map((category) => (
    <Category
      key={category[0]}
      name={category[0] as 'Primitive Information'}
      data={category[1]}
    />
  ));

export { Default };
