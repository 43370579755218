import { Disclosure } from '@headlessui/react';
import { clsx } from 'clsx';
import { type FC } from 'react';

import { type BioentityListItemProps } from './bioentity-list-item.types';
import { NodeListItem } from './components';

/**
 * Bioentity list component.
 *
 * @author Malik Alimoekhamedov
 * @see BioentityListProps
 */
const BioentityListItem: FC<BioentityListItemProps> = ({
  className,
  name,
  description,
  nodes,
}) => (
  <Disclosure as={'details'} className={className}>
    <Disclosure.Button
      as={'summary'}
      className={clsx('cursor-pointer select-none rounded-md px-4 py-2 font-medium', {
        'bg-protein/20 text-protein': name === 'Protein',
        'bg-drug/20 text-drug': name === 'Drug',
        'bg-gene/20 text-gene': name === 'Gene',
        'bg-pathway/20 text-pathway': name === 'Pathway',
        'bg-disease/20 text-disease': name === 'Disease',
        'bg-literature/20 text-literature':
          name === 'Scientific Literature' || name === 'NExTNet: Literature',
      })}
    >
      {name}
    </Disclosure.Button>
    <Disclosure.Panel className={clsx('px-4 pb-4')}>
      {description && <p className={'my-4 text-sm text-gray-500'}>{description}</p>}
      {nodes?.map((node) => <NodeListItem {...node} key={node.name} />)}
    </Disclosure.Panel>
  </Disclosure>
);

export { BioentityListItem };
