import { Divider, IconButton, InputBase, Paper } from '@mui/material';
import { I18n } from 'aws-amplify/utils';
import { SECONDARY_DESATURATED } from 'constants/colors';
import { type FC, useEffect, useState } from 'react';
import { FiBookmark, FiTag } from 'react-icons/fi';
import { Button, Container, Input } from 'reactstrap';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import styled from 'styled-components';

import { type UtilityPaneProps } from './utility-pane.types';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';
// import "./modalBackdrop.css"

const GraphViewWrapperRight = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  height: 50vh;
`;

const UtilityPane: FC<UtilityPaneProps> = ({
  className,
  findNode,
  setFindNode,
  hoveredCoords,
  tagPopover,
  mergePopover,
  handleMergeButton,
  handleMergeSubmit,
  handleTagNode,
  handleTagSubmit,
  handleSaveGraph,
  handleDownload,
  setField,
  graphSettings,
}) => {
  const handleToggleTaggedNodes = () => {
    if (graphSettings['TAGGED']) {
      return <FiTag sx={{ color: 'green' }} />;
    } else {
      return <FiTag sx={{ color: 'red' }} />;
    }
  };

  const handleTag = () => {
    handleTagNode(); // calls function in outer scope (graphpage) to handle tagging if possible
  };

  // Maintains the state of the content of these two popovers
  const [tagContent, setTagContent] = useState<string>('');
  const [mergeContent, setMergeContent] = useState<string>('');

  useEffect(() => {
    if (tagPopover) {
      setTagContent('');
    }
  }, [tagPopover]);

  useEffect(() => {
    if (mergePopover) {
      setMergeContent('');
    }
  }, [mergePopover]);

  return (
    <div>
      <div className={'absolute left-0 z-[2] mb-0 ml-3 mr-0 mt-4 h-auto'}>
        {/* <Form inline onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label for="unmountOnClose">UnmountOnClose value</Label>{' '}
            <Input
              type="select"
              name="unmountOnClose"
              id="unmountOnClose"
              onChange={changeUnmountOnClose}
            >
              <option value="true">true</option>
              <option value="false">false</option>
            </Input>
          </FormGroup>{' '}
          <Button color="danger" onClick={toggle}>
            {' '}
            Hello there
          </Button>
        </Form> */}
        <div className={'flex h-auto w-auto content-between items-center'}>
          <Paper
            sx={{
              marginRight: '10px',
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              height: '35px',
              width: '300px',
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              placeholder={I18n.get('Search Within')}
              inputProps={{ 'aria-label': 'Search Within' }}
              type={'text'}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              value={findNode}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFindNode(e.target.value)
              }
              // value={searchData.queryStringParameters.actors}
              // onInput={(e: React.ChangeEvent<HTMLInputElement>) => setField("actors", e.target.value)}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation={'vertical'} />
            <IconButton
              color={'primary'}
              sx={{ p: '5px' }}
              aria-label={'Tag Nodes'}
              id={'handleTag'}
              onClick={handleTag}
            >
              <FiBookmark sx={{ color: 'grey' }} />
            </IconButton>
            <IconButton
              color={'primary'}
              sx={{ p: '5px' }}
              aria-label={I18n.get('Toggle Tagged Nodes')}
              onClick={() => {
                setField('TAGGED', !graphSettings['TAGGED']);
              }}
            >
              {handleToggleTaggedNodes()}
            </IconButton>
            <div>
              <Popover isOpen={tagPopover} target={'handleTag'} placement={'bottom'}>
                <PopoverHeader>Tagging</PopoverHeader>
                <PopoverBody>
                  <Input
                    type={'textarea'}
                    placeholder={I18n.get(
                      'Note down interesting findings regarding this node.'
                    )}
                    rows={4}
                    style={{ marginBottom: '2vh' }}
                    value={tagContent}
                    onChange={(event) => setTagContent(event.target.value)}
                  />
                  <Button
                    style={{ background: SECONDARY_DESATURATED }}
                    onClick={() => handleTagSubmit(tagContent)}
                  >
                    {I18n.get('Submit')}
                  </Button>
                </PopoverBody>
              </Popover>
            </div>
          </Paper>
        </div>
      </div>

      <GraphViewWrapperRight>
        {/* <Form inline onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label for="unmountOnClose">UnmountOnClose value</Label>{' '}
            <Input
              type="select"
              name="unmountOnClose"
              id="unmountOnClose"
              onChange={changeUnmountOnClose}
            >
              <option value="true">true</option>
              <option value="false">false</option>
            </Input>
          </FormGroup>{' '}
          <Button color="danger" onClick={toggle}>
            {' '}
            Hello there
          </Button>
        </Form> */}
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'auto',
          }}
        >
          {/* <Row>
            <StyledButton
              id="handleMerge"
              title={'Merge Nodes'}
              onClick={handleMergeButton}
            >
              {' '}
              {<BiSitemap size={iconSize} />}{' '}
            </StyledButton>
            <div>
              <Popover isOpen={mergePopover} target="handleMerge" placement="left">
                <PopoverHeader>Merging</PopoverHeader>
                <PopoverBody>
                  <Input
                    type="textarea"
                    placeholder="Title this merged node."
                    style={{ marginBottom: '2vh' }}
                    value={mergeContent}
                    onChange={(event) => setMergeContent(event.target.value)}
                  />
                  <Button
                    style={{ background: SECONDARY_DESATURATED }}
                    onClick={() => handleMergeSubmit(mergeContent)}
                  >
                    Submit
                  </Button>{' '}
                </PopoverBody>
              </Popover>
            </div>
          </Row> */}
        </Container>
      </GraphViewWrapperRight>
    </div>
  );
};

export { UtilityPane };
