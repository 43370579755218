import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import png from './assets/logo-name-tagline-light.png';
import type { ContainerProps } from './container.types';

/**
 * Container component
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ContainerProps
 */
const Container: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  title,
  subtitle,
}) => (
  <div
    className={clsx(
      'flex min-h-screen flex-col items-center justify-center gap-y-4 px-2 py-5 sm:py-0 md:gap-y-6 md:px-0',
      className
    )}
  >
    <div
      className={
        'flex flex-col items-center gap-4  text-center sm:mx-auto sm:w-full sm:max-w-md'
      }
    >
      <img width={100} alt={'logo-name-tagline'} src={png} />
      <h2
        className={
          'select-none text-xl font-bold text-neutral-900 md:text-2xl lg:text-3xl'
        }
      >
        {title}
      </h2>
      {subtitle && (
        <h3 className={'select-none text-lg font-light text-neutral-600'}>{subtitle}</h3>
      )}
    </div>
    <div
      className={
        'mx-auto w-full max-w-2xl rounded-lg bg-white px-4 py-8 shadow sm:px-10 md:w-auto'
      }
    >
      {children}
    </div>
    <p className={'text-sm font-light text-neutral-600'}>
      <span>{I18n.get('By signing-up/in you agree to our ')}</span>
      <Link
        to={'https://getnextnet.com/tou'}
        target={'_blank'}
        className={'whitespace-nowrap text-blue-600 transition hover:text-blue-400'}
      >
        {I18n.get('terms of use')}
      </Link>
      .
    </p>
  </div>
);

export { Container };
