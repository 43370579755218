import { type Node } from '../graph.types';
// Radially arrange clusters- happy viewing! :)
// Say we have n clusters- then we arrange them at the nth roots of unity, radius scaled.
// e^(2kpi i / n), 0 <= k < n
// = cos(2kpi/n) + isin(2kpi/n), 0 <= k < n
// Take x coordinate to be real value, y coordinate to be imaginary value
// Scale by some radius
//TODO: Finish this refactoring.
const radialArrange = (
  radius: number,
  center: [number, number],
  nodes: Array<Node>,
  final: boolean
) => {
  const n = nodes.length;

  for (let k = 0; k < n; k++) {
    const xPos = center[0] + Math.cos((2 * k * Math.PI) / n) * radius;
    const yPos = center[1] + Math.sin((2 * k * Math.PI) / n) * radius;

    const node = nodes[k];
    if (node) {
      if (final) {
        node.fx = xPos;
        node.fy = yPos;
      } else {
        node.x = xPos;
        node.y = yPos;
      }
    }
  }
};

export { radialArrange };
