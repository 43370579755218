import { type ResourcesConfig } from 'aws-amplify';
const DEV_AMPLIFY_CONFIG = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AMPLIFY_USERPOOL_ID ?? '',
      userPoolClientId: process.env.REACT_APP_AMPLIFY_WEBCLIENT_ID ?? '',
    },
  },
  API: {
    REST: {
      main: { endpoint: process.env.REACT_APP_API_ENDPOINT ?? '' },
    },
  },
} satisfies ResourcesConfig;

export { DEV_AMPLIFY_CONFIG };
