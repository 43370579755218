import DiseaseIcon from 'assets/disease.png';
import DrugIcon from 'assets/drug.png';
import GeneIcon from 'assets/gene.png';
import LitIcon3 from 'assets/literature-light-1.png';
import MSigDBIcon from 'assets/msigdb.png';
import PathogenIcon from 'assets/pathogen.png';
import ProteinIcon from 'assets/protein.png';
import {
  Disease,
  Drug,
  Gene,
  GO,
  HGNC,
  HPA,
  LITERATURE,
  NExTNet,
  Pathogen,
  Pathway,
  Protein,
  STRING,
  Uniprot,
} from 'constants/colors';
import type { ForceGraphProps } from 'react-force-graph-2d';
import tinycolor from 'tinycolor2';

import { type Link, type Node } from '../graph.types';
import { formatNode, getAdjacentNodes } from '../utils';
import { rgba2rgb } from './rgba-to-rgb';

type PaintNodeFn = (args: {
  readonly node: Node;
  readonly graph: Required<ForceGraphProps<Node, Link>>['graphData'];
  readonly color?: string; // Switch to some const or enum.
  readonly ctx: CanvasRenderingContext2D;
  readonly globalScale: number;
  readonly focusNodeOnHover: boolean;
  readonly hoveredElement?: Node | Link;
  readonly expandingNode?: Node;
  readonly deletingNode?: Node;
  readonly highlightNodes?: ReadonlyArray<Node>;
  readonly queriedNodeIds?: ReadonlySet<string | number | undefined>;
  readonly mergeNodes: Array<Node>;
  readonly statsHighlightNodes: any;

  /**
   * @default false
   */
  readonly isGraphTagged?: boolean;
}) => void;

/**
 * Graph node custom rendering logic.
 */
const paintNode: PaintNodeFn = ({
  color,
  ctx,
  deletingNode,
  expandingNode,
  globalScale,
  highlightNodes,
  hoveredElement,
  node,
  graph,
  queriedNodeIds,
  mergeNodes,
  isGraphTagged = false,
  statsHighlightNodes,
}) => {
  const MIN_SIZE = 4;
  const MAX_SIZE = 30;
  const MIN_OPACITY = 0.3;

  // Use of node.weight when all node weights are set to 10?

  // NODE_OPACITY = Math.max(Math.min(Math.log(node.weight) / MAX_WEIGHT, 1) * 1, MIN_OPACITY);
  let NODE_OPACITY = 1;

  /* const NODE_SIZE = Math.max(
    Math.min(Math.log(node.neighbors.length + 1) / MAX_NEIGHBORS, 1) * MAX_SIZE * 1.25,
    MIN_SIZE
  ); */

  // Clamp the node size.
  const NODE_SIZE = Math.max(
    MIN_SIZE,
    Math.min(getAdjacentNodes(node, graph).length * 0.8, MAX_SIZE)
  );

  /* if (focusNodeOnHover && isNode(hoveredElement)) {
    // overrides the node.weight settings
    if (highlightNodes?.length === 0) {
      NODE_OPACITY = 1;
    } else {
      NODE_OPACITY = NODE_OPACITY_FADE;
    }
  } */

  // This is in place as node.x and node.y initially are not intialized
  if (node.x && node.y) {
    if (highlightNodes?.includes(node) && node.id !== hoveredElement?.id) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = tinycolor('#FFCC33').setAlpha(0.85).toRgbString();
      ctx.fill();
      NODE_OPACITY = 1;
    }

    if (queriedNodeIds?.has(node.id)) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'red';
      ctx.fill();
      NODE_OPACITY = 1;
    }
    //}
    if (node.tagged_notes && isGraphTagged) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.6, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'green';
      ctx.fill();
    }
    if (statsHighlightNodes && node.id && statsHighlightNodes['centrality'][node.id]) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'blue';
      ctx.fill();
    }
    if (highlightNodes?.includes(node) && node.id === hoveredElement?.id) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'red';
      ctx.fill();
      NODE_OPACITY = 1;
    }
    if (mergeNodes?.includes(node)) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'blue';
      ctx.fill();
    }

    let baseColor = [0, 0, 0];

    // This is a workaround -> GAMMA CLUSTER DOES NOT HAVE ":" SEPARATOR FOR TYPES
    // Instead of passing group data down
    const nodeBaseType = node.type.includes(':')
      ? node.type.substring(0, node.type.indexOf(':'))
      : node.type;

    let curIcon: HTMLImageElement | null | undefined;

    switch (nodeBaseType) {
      case 'STRING':
        baseColor = STRING;
        break;

      case 'GO':
        baseColor = GO;
        break;

      case 'LITERATURE':
        baseColor = LITERATURE;
        break;

      case 'HPA':
        baseColor = HPA;
        break;

      case 'HGNC':
        baseColor = HGNC;
        break;

      case 'Uniprot':
        baseColor = Uniprot;
        break;
      case 'NExTNet':
      case 'NExTNet: Literature': // Gamma unique
      case 'Scientific Literature': {
        const img = new Image(100, 100);
        img.src = LitIcon3;
        curIcon = img;
        baseColor = NExTNet;
        break;
      }
      case 'Protein': {
        // Gamma unique
        const img = new Image(200, 200);
        img.src = ProteinIcon;
        curIcon = img;
        baseColor = Protein;
        break;
      }
      case 'Gene': {
        // Gamma unique
        const img = new Image(200, 200);
        img.src = GeneIcon;
        curIcon = img;
        baseColor = Gene;
        break;
      }
      case 'Pathway': {
        // Gamma unique
        const img = new Image(200, 200);
        img.src = MSigDBIcon;
        curIcon = img;
        baseColor = Pathway;
        break;
      }
      case 'Disease': {
        const img = new Image(200, 200);
        img.src = DiseaseIcon;
        curIcon = img;
        baseColor = Disease;
        break;
      }
      case 'Drug': {
        const img = new Image(200, 200);
        img.src = DrugIcon;
        curIcon = img;
        baseColor = Drug;
        break;
      }
      case 'Pathogen': {
        const img = new Image(200, 200);
        img.src = PathogenIcon;
        curIcon = img;
        baseColor = Pathogen;
        break;
      }

      //[ProteinIcon, GeneIcon, LitIcon3, MSigDBIcon, DiseaseIcon, DrugIcon, PathogenIcon];
      default:
        curIcon = null;
        baseColor = [0, 0, 0];
    }

    // Small outline for sharpness
    // snazzy!
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_SIZE * 1.02, 0, 2 * Math.PI, false);
    //ctx.fillStyle = 'black';
    ctx.fillStyle = tinycolor(`rgb(${baseColor.join(',')})`)
      .darken(7)
      .toRgbString();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_SIZE, 0, 2 * Math.PI, false);

    let opacity = NODE_SIZE === MIN_SIZE ? 0.6 : NODE_OPACITY;
    let opacityDelta = -0.02; //TODO: Refactor this.
    let expandingOpacity = 1;

    if (expandingNode?.id === node.id) {
      opacity = expandingOpacity + opacityDelta;

      if (opacity < MIN_OPACITY) {
        opacity = MIN_OPACITY;
        opacityDelta = -opacityDelta;
        //setOpacityDelta(-opacityDelta);
      } else if (opacity >= 1) {
        opacity = 1;
        opacityDelta = -opacityDelta;
        //setOpacityDelta(-opacityDelta);
      }

      expandingOpacity = opacity;
    }

    if (deletingNode?.id === node.id) {
      let deletingOpacity = 1;
      opacity = deletingOpacity + opacityDelta;

      if (opacity < MIN_OPACITY) {
        opacity = MIN_OPACITY;
        opacityDelta = -opacityDelta;
        //setOpacityDelta(-opacityDelta);
      } else if (opacity >= 1) {
        opacity = 1;
        opacityDelta = -opacityDelta;
        //setOpacityDelta(-opacityDelta);
      }

      deletingOpacity = opacity;
    }

    const rgb = rgba2rgb(
      [baseColor[0] ?? 0, baseColor[1] ?? 0, baseColor[2] ?? 0, opacity],
      [255, 255, 255]
    );
    // Ternary is for pointer area painting
    ctx.fillStyle = color ? color : `rgb(${rgb.join(',')})`;
    ctx.fill();

    // Handles giving nodes names, CHANGE BY WEIGHT
    let label = formatNode(node);

    if (node.type === 'Pathway') {
      label = node.name.split('_').slice(0, 2).join(' ');
    }

    const fontSize = 1.8 * Math.log(100 / globalScale);
    ctx.font = `${fontSize}px`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = tinycolor(`rgb(${baseColor.join(',')})`)
      .darken(26)
      .toRgbString();
    ctx.fillText(label, node.x, node.y + NODE_SIZE + 10);

    if (curIcon && NODE_SIZE > MIN_SIZE + 5) {
      ctx.drawImage(
        curIcon,
        node.x - NODE_SIZE * 0.7,
        node.y - NODE_SIZE * 0.7,
        NODE_SIZE * 1.4,
        NODE_SIZE * 1.4
      );
    }
  }
};

export { paintNode };
