import { Listbox as HUListbox, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { type FC, Fragment, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { Item } from './components';
import type { ListboxProps } from './listbox.types';

/**
 * The 'Listbox' component allows you to pick an option from a pre-defined list.
 *
 * @author Malik Alimoekhamedov
 * @see ListboxProps
 */
const Listbox: FC<ListboxProps> = ({ items, label, value, onChange, className }) => {
  const [selectedOption, setSelectedOption] = useState(value ?? items[0]);

  return (
    <HUListbox
      value={selectedOption}
      onChange={(value) => {
        setSelectedOption(value);
        onChange && onChange(value);
      }}
    >
      <div className={clsx('relative', className)}>
        {label && (
          <HUListbox.Label className={'mb-1 text-sm font-medium text-neutral-700'}>
            {label}
          </HUListbox.Label>
        )}
        <HUListbox.Button
          className={
            'relative flex w-fit cursor-default select-none gap-x-2 rounded-md bg-white px-4 py-2 font-medium text-neutral-700 shadow-md focus:outline-none focus-visible:border-sky-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-sky-300 sm:text-sm'
          }
        >
          <span>{selectedOption?.title}</span>
          <FiChevronDown className={'h-5 w-5 text-neutral-400'} aria-hidden={'true'} />
        </HUListbox.Button>
        <Transition
          as={Fragment}
          leave={'transition ease-in duration-100'}
          leaveFrom={'opacity-100'}
          leaveTo={'opacity-0'}
        >
          <HUListbox.Options
            className={
              'absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-neutral-200 focus:outline-none sm:text-sm'
            }
          >
            {items.map((item) => (
              <Item
                key={item.title}
                title={item.title}
                value={item}
                disabled={item === selectedOption}
              />
            ))}
          </HUListbox.Options>
        </Transition>
      </div>
    </HUListbox>
  );
};

export { Listbox };
