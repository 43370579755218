import type { EntityType } from 'types';

const restructureDesiredDatasetsParameters = (entityTypes: ReadonlyArray<EntityType>) =>
  entityTypes.reduce<
    Record<
      'DISEASES' | 'DRUGS' | 'GENES' | 'LIT' | 'PATHOGENS' | 'PATHWAYS' | 'PROTEINS',
      boolean
    >
  >(
    (prev, curr) => {
      switch (curr) {
        case 'Disease':
          return { ...prev, DISEASES: true };
        case 'Drug':
          return { ...prev, DRUGS: true };
        case 'Gene':
          return { ...prev, GENES: true };
        case 'NExTNet: Literature':
        case 'Scientific Literature':
          return { ...prev, LIT: true };
        case 'Pathogen':
          return { ...prev, PATHOGENS: true };
        case 'Pathway':
          return { ...prev, PATHWAYS: true };
        case 'Protein':
          return { ...prev, PROTEINS: true };
        default:
          return prev;
      }
    },
    {
      DISEASES: false,
      DRUGS: false,
      GENES: false,
      LIT: false,
      PATHOGENS: false,
      PATHWAYS: false,
      PROTEINS: false,
    }
  );

export { restructureDesiredDatasetsParameters };
