import { clsx } from 'clsx';
import { Hint } from 'components';
import { type FC } from 'react';

import type { SliderProps } from './slider.types';

/**
 * Slider (range input).
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see SliderProps
 */
const Slider: FC<SliderProps> = ({
  className,
  label,
  id,
  hint,
  disabled,
  min,
  max,
  value,
  onChange,
}) => (
  <div className={clsx('flex flex-col gap-y-2', className)}>
    <div className={'flex flex-nowrap items-center justify-between'}>
      <label htmlFor={id} className={'select-none text-sm font-medium text-neutral-700'}>
        {label}
      </label>
      {hint && <Hint text={hint} />}
    </div>
    <span className={'text-2xl font-extrabold text-neutral-800'}>{value ?? 0}</span>
    <input
      id={id}
      className={clsx(
        'h-1 appearance-none rounded-full bg-neutral-200 ring-1 ring-inset ring-neutral-300/30',
        '[&::-moz-range-thumb]:appearance-none [&::-ms-thumb]:appearance-none [&::-webkit-slider-thumb]:appearance-none',
        '[&::-moz-range-thumb]:w-4 [&::-ms-thumb]:w-4 [&::-webkit-slider-thumb]:w-4',
        '[&::-moz-range-thumb]:h-4 [&::-ms-thumb]:h-4 [&::-webkit-slider-thumb]:h-4',
        '[&::-moz-range-thumb]:bg-sky-600 [&::-ms-thumb]:bg-sky-600 [&::-webkit-slider-thumb]:bg-sky-600',
        '[&::-moz-range-thumb]:ring-1 [&::-ms-thumb]:ring-1 [&::-webkit-slider-thumb]:ring-1',
        '[&::-moz-range-thumb]:ring-inset [&::-ms-thumb]:ring-inset [&::-webkit-slider-thumb]:ring-inset',
        '[&::-moz-range-thumb]:ring-sky-700/30 [&::-ms-thumb]:ring-sky-700/30 [&::-webkit-slider-thumb]:ring-sky-700/30',
        '[&::-moz-range-thumb]:shadow [&::-ms-thumb]:shadow [&::-webkit-slider-thumb]:shadow',
        '[&::-moz-range-thumb]:shadow-sky-700/50 [&::-ms-thumb]:shadow-sky-700/50 [&::-webkit-slider-thumb]:shadow-sky-700/50',
        '[&::-moz-range-thumb]:rounded-full [&::-ms-thumb]:rounded-full [&::-webkit-slider-thumb]:rounded-full'
      )}
      type={'range'}
      disabled={disabled}
      min={min}
      max={max}
      onChange={({ target: { value } }) => onChange(Number(value))}
    />
    <span
      className={'flex flex-nowrap items-center justify-between text-xs text-neutral-600'}
    >
      <span>{min}</span>
      <span>{max}</span>
    </span>
  </div>
);

export { Slider };
