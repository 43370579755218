import './index.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app.component';

const root = document.getElementById('root');
root &&
  createRoot(root).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
