import {
  Disease,
  Drug,
  Gene,
  NExTNet,
  Pathogen,
  Pathway,
  Protein,
} from 'constants/colors';
import tinycolor from 'tinycolor2';
import type { EntityType } from 'types';

import type { Link } from '../graph.types';
import { isNode } from './is-node';

/**
 * Determine graph link colour.
 *
 * @author Malik Alimoekhamedov
 * @category Utils
 * @param link Link to paint.
 * @returns RGBA string value for the link colour.
 */
const paintLink = (link: Link) => {
  const ALPHA = 0.2;
  const source = link.source;

  if (isNode(source)) {
    switch (source.type as EntityType) {
      case 'Disease':
        return tinycolor(`rgb(${Disease.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'Protein':
        return tinycolor(`rgb(${Protein.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'Drug':
        return tinycolor(`rgb(${Drug.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'Gene':
        return tinycolor(`rgb(${Gene.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'NExTNet: Literature':
      case 'Scientific Literature':
        return tinycolor(`rgb(${NExTNet.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'Pathogen':
        return tinycolor(`rgb(${Pathogen.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      case 'Pathway':
        return tinycolor(`rgb(${Pathway.join(',')})`)
          .setAlpha(ALPHA)
          .toRgbString();
      default:
        break;
    }
  }

  return tinycolor('gray').setAlpha(ALPHA).toRgbString();
};

export { paintLink };
