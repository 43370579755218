import { I18n } from 'aws-amplify/utils';
import { Button, Input, PasswordRequirements } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import { type SignUpValidationForm, SignUpValidationSchema } from '../auth.validators';
import { Container } from '../components';

const initialValues: SignUpValidationForm = {
  email: '',
  password: '',
  repeatPassword: '',
};

/**
 * Sign up page.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const SignUp: FC = () => {
  const navigate = useNavigate();
  const { signUp } = useAuthContext();

  const handleSignUp = async (values: SignUpValidationForm) => {
    await signUp(values, () =>
      navigate('/auth/email-confirmation', {
        state: { email: values.email, username: values.email },
      })
    );
  };

  const { handleChange, handleSubmit, values, errors, isSubmitting } = useFormik({
    initialValues,
    validationSchema: SignUpValidationSchema,
    onSubmit: handleSignUp,
    validateOnChange: false,
  });

  return (
    <Container title={I18n.get('Sign Up')}>
      <div className={'flex flex-wrap gap-6 md:flex-nowrap'}>
        <form onSubmit={handleSubmit} className={'w-full space-y-6 md:w-1/2'}>
          <Input
            name={'email'}
            label={I18n.get('Email')}
            onChange={handleChange}
            placeholder={I18n.get('Enter your email')}
            value={values.email}
            error={errors.email}
            type={'email'}
          />
          <Input
            name={'password'}
            label={I18n.get('Password')}
            onChange={handleChange}
            placeholder={I18n.get('Enter new password')}
            value={values.password}
            error={errors.password}
            type={'password'}
          />
          <Input
            name={'repeatPassword'}
            label={I18n.get('Repeat Password')}
            onChange={handleChange}
            placeholder={I18n.get('Repeat password')}
            value={values.repeatPassword}
            error={errors.repeatPassword}
            type={'password'}
          />
          <Button
            isLoading={isSubmitting}
            type={'submit'}
            className={'flex w-full justify-center'}
            disabled={
              !values.email ||
              !values.password ||
              !values.repeatPassword ||
              Object.values(errors).some((error) => !!error)
            }
            icon={<FiLogIn />}
          >
            {I18n.get(isSubmitting ? 'Signing Up...' : 'Sign Up')}
          </Button>
          <p
            className={
              'flex select-none justify-between gap-4 text-center text-sm text-neutral-600'
            }
          >
            {I18n.get('Already have an account?')}{' '}
            <Link
              to={'/auth/sign-in'}
              className={'font-medium text-blue-600 hover:text-blue-400'}
            >
              {I18n.get('Sign In')}
            </Link>
          </p>
        </form>
        <PasswordRequirements className={'md:w-1/2'} />
      </div>
    </Container>
  );
};

export { SignUp };
