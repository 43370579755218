import type { loader } from 'app.loader';
import { I18n } from 'aws-amplify/utils';
import { Button, Checkbox, Input } from 'components';
import { Listbox } from 'pages/components';
import { Modal } from 'pages/components/modal';
import { type FC } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { SubscriptionPlan } from 'types';

import { type SettingsProps } from './settings.types';

const BREADTH_VALUES = [
  { title: I18n.get('Faster (Fewer Results)'), value: 300 },
  { title: I18n.get('Fast'), value: 500 },
  { title: I18n.get('Slowest (More Results)'), value: 1000 },
];

/**
 * Search settings.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Settings: FC<SettingsProps> = ({ open, onClose, onChange, onSearch, search }) => {
  const { user } = useRouteLoaderData<typeof loader>('root');

  return (
    <Modal
      className={'flex flex-col gap-y-4'}
      title={I18n.get('Advanced Search')}
      open={open}
      onClose={onClose}
    >
      <Input
        label={I18n.get('Search Query')}
        required={true}
        icon={<FiSearch />}
        placeholder={I18n.get('Search Nextnet')}
        name={'query'}
        id={'query'}
        type={'search'}
        defaultValue={search.query}
        onChange={({ target: { value } }) => onChange({ ...search, query: value })}
        onKeyUp={(event) => {
          event.key === 'Enter' && onSearch();
        }}
      />
      <Listbox
        label={I18n.get('Amount of Results')}
        value={BREADTH_VALUES.find(({ value }) => value === search.settings.breadth)}
        items={BREADTH_VALUES}
        onChange={(value) =>
          onChange({
            ...search,
            settings: { ...search.settings, breadth: value.value as 300 | 500 | 1000 },
          })
        }
      />
      <h2 className={'text-sm font-medium text-neutral-700'}>
        {I18n.get('Premium Results Scope')}
      </h2>
      <div className={'flex gap-x-4'}>
        <Checkbox
          name={'literature'}
          label={I18n.get('Literature')}
          state={
            search.settings.scope.includes('NExTNet: Literature') ||
            search.settings.scope.includes('Scientific Literature')
              ? 'CHECKED'
              : 'UNCHECKED'
          }
          disabled={user?.subscriptionPlan !== SubscriptionPlan.FREEMIUM_X_EDU}
          //checked={searchData.queryStringParameters.datasets['LIT']}
          onChange={() =>
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope:
                  search.settings.scope.includes('NExTNet: Literature') ||
                  search.settings.scope.includes('Scientific Literature')
                    ? search.settings.scope.filter(
                        (item) =>
                          !['NExTNet: Literature', 'Scientific Literature'].includes(item)
                      )
                    : [
                        ...search.settings.scope,
                        'NExTNet: Literature',
                        'Scientific Literature',
                      ],
              },
            })
          }
        />
        {user?.subscriptionPlan !== SubscriptionPlan.FREEMIUM_X_EDU && (
          <span className={'flex gap-x-2 font-light text-neutral-700'}>
            <Link
              className={'font-medium text-sky-700'}
              to={'https://nextnetinc.com/contact/'}
              target={'_blank'}
            >
              {I18n.get('Subscribe now')}
            </Link>
            <span>{I18n.get('for full insights')}</span>
          </span>
        )}
      </div>
      <h2 className={'text-sm font-medium text-neutral-700'}>
        {I18n.get('Results Scope')}
      </h2>
      <div className={'flex flex-wrap gap-4'}>
        <Checkbox
          //id={'DrugCheckbox'}
          //checked={searchData.queryStringParameters.datasets['DRUGS']}
          //onChange={(e) => setField('DRUGS', e.target.checked)}
          name={'drugs'}
          label={I18n.get('Drugs')}
          state={search.settings.scope.includes('Drug') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() =>
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Drug')
                  ? search.settings.scope.filter((item) => item !== 'Drug')
                  : [...search.settings.scope, 'Drug'],
              },
            })
          }
        />
        <Checkbox
          //id={'ProteinCheckbox'}
          //checked={searchData.queryStringParameters.datasets['PROTEINS']}
          //onChange={(e) => setField('PROTEINS', e.target.checked)}
          name={'proteins'}
          label={I18n.get('Proteins')}
          state={search.settings.scope.includes('Protein') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() => {
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Protein')
                  ? search.settings.scope.filter((item) => item !== 'Protein')
                  : [...search.settings.scope, 'Protein'],
              },
            });
          }}
        />

        <Checkbox
          //checked={searchData.queryStringParameters.datasets['GENES']}
          //onChange={(e) => setField('GENES', e.target.checked)}
          name={'genes'}
          label={I18n.get('Genes')}
          state={search.settings.scope.includes('Gene') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() => {
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Gene')
                  ? search.settings.scope.filter((item) => item !== 'Gene')
                  : [...search.settings.scope, 'Gene'],
              },
            });
          }}
        />
        <Checkbox
          //checked={searchData.queryStringParameters.datasets['DISEASE']}
          //onChange={(e) => setField('DISEASE', e.target.checked)}
          name={'diseases'}
          label={I18n.get('Diseases')}
          state={search.settings.scope.includes('Disease') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() => {
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Disease')
                  ? search.settings.scope.filter((item) => item !== 'Disease')
                  : [...search.settings.scope, 'Disease'],
              },
            });
          }}
        />
        <Checkbox
          //checked={searchData.queryStringParameters.datasets['PATHOGENS']}
          //onChange={(e) => setField('PATHOGENS', e.target.checked)}
          name={'Pathogen'}
          label={I18n.get('Pathogen')}
          state={search.settings.scope.includes('Pathogen') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() => {
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Pathogen')
                  ? search.settings.scope.filter((item) => item !== 'Pathogen')
                  : [...search.settings.scope, 'Pathogen'],
              },
            });
          }}
        />
        <Checkbox
          //checked={searchData.queryStringParameters.datasets['PATHWAYS']}
          //onChange={(e) => setField('PATHWAYS', e.target.checked)}
          name={'Pathway'}
          label={I18n.get('Pathway')}
          state={search.settings.scope.includes('Pathway') ? 'CHECKED' : 'UNCHECKED'}
          onChange={() => {
            onChange({
              ...search,
              settings: {
                ...search.settings,
                scope: search.settings.scope.includes('Pathway')
                  ? search.settings.scope.filter((item) => item !== 'Pathway')
                  : [...search.settings.scope, 'Pathway'],
              },
            });
          }}
        />
      </div>
      <Button
        icon={<FiSearch />}
        type={'submit'}
        disabled={search.settings.scope.length === 0}
        onClick={onSearch}
      >
        {I18n.get('Search')}
      </Button>
    </Modal>
  );
};

export { Settings };
