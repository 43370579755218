import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import type { FC } from 'react';

import type { PasswordRequirementsProps } from './password-requirements.types';

/**
 * A simple component you can use every time you need to display
 * minimal password strength requirements. It's useful for authentication
 * flows and in-app password management.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see PasswordRequirementsProps
 */
const PasswordRequirements: FC<PasswordRequirementsProps> = ({ className }) => (
  <div
    className={clsx(
      'flex flex-col gap-y-2 text-sm font-light text-neutral-400 lg:w-1/2',
      className
    )}
  >
    <h2 className={'text-normal font-medium text-neutral-700'}>
      {I18n.get('Password Requirements')}
    </h2>
    <p>{I18n.get('Your password should comply with the following security measures:')}</p>
    <ol className={'list-inside list-decimal'}>
      <li>{I18n.get('Minimum 8 symbols')}</li>
      <li>{I18n.get('Contains at least 1 number')}</li>
      <li>
        {I18n.get(
          `Contains at least 1 special character such as ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =`
        )}
      </li>
      <li>{I18n.get('Contains at least 1 uppercase letter')}</li>
      <li>{I18n.get('Contains at least 1 lowercase letter')}</li>
    </ol>
  </div>
);

export { PasswordRequirements };
