import { Tab } from '@headlessui/react';
import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC } from 'react';
import { FiArrowDownCircle, FiArrowUpCircle, FiDisc, FiLifeBuoy } from 'react-icons/fi';

import { BioentityListItem } from './components';
import { type StatisticsProps } from './statistics.types';

/**
 * Graph statistics component. Or is it metrics? The vocabulary's not quite set.
 *
 * @author Malik Alimoekhamedov
 * @see StatisticsProps
 */
const Statistics: FC<StatisticsProps> = ({ className, categories }) => (
  <Tab.Group
    as={'div'}
    className={clsx('flex h-full flex-col gap-y-4 p-2 md:p-4', className)}
  >
    <Tab.List
      className={'flex flex-none flex-wrap gap-2 transition-opacity duration-500'}
    >
      <Tab
        className={
          'flex flex-nowrap items-center gap-x-1 rounded-md px-3 py-2 text-sm font-medium transition-colors ui-selected:bg-gray-100 ui-selected:text-gray-700 ui-not-selected:bg-transparent ui-not-selected:text-gray-500 ui-not-selected:hover:text-gray-700'
        }
      >
        <FiDisc />
        <span className={'select-none whitespace-nowrap'}>{I18n.get('Centrality')}</span>
      </Tab>
      <Tab
        className={
          'flex flex-nowrap items-center gap-x-1 rounded-md px-3 py-2 text-sm font-medium transition-colors ui-selected:bg-gray-100 ui-selected:text-gray-700 ui-not-selected:bg-transparent ui-not-selected:text-gray-500 ui-not-selected:hover:text-gray-700'
        }
      >
        <FiLifeBuoy />
        <span className={'select-none whitespace-nowrap'}>
          {I18n.get('Degree (All)')}
        </span>
      </Tab>
      <Tab
        className={
          'flex flex-nowrap items-center gap-x-1 rounded-md px-3 py-2 text-sm font-medium transition-colors ui-selected:bg-gray-100 ui-selected:text-gray-700 ui-not-selected:bg-transparent ui-not-selected:text-gray-500 ui-not-selected:hover:text-gray-700'
        }
      >
        <FiArrowDownCircle />
        <span className={'select-none whitespace-nowrap'}>{I18n.get('Degree (In)')}</span>
      </Tab>
      <Tab
        className={
          'flex flex-nowrap items-center gap-x-1 rounded-md px-3 py-2 text-sm font-medium transition-colors ui-selected:bg-gray-100 ui-selected:text-gray-700 ui-not-selected:bg-transparent ui-not-selected:text-gray-500 ui-not-selected:hover:text-gray-700'
        }
      >
        <FiArrowUpCircle />
        <span className={'select-none whitespace-nowrap'}>
          {I18n.get('Degree (Out)')}
        </span>
      </Tab>
    </Tab.List>
    <Tab.Panels
      className={
        'flex h-full flex-1 flex-col overflow-hidden transition-opacity duration-500'
      }
    >
      <Tab.Panel className={'flex h-full flex-1 flex-col gap-y-4'}>
        <p
          className={
            'flex-none rounded-md bg-blue-50 px-[14px] py-2 text-sm text-blue-400 ring-1 ring-inset ring-blue-300'
          }
        >
          {I18n.get('The number of unique edges passing through a node.')}
        </p>
        <div className={'flex h-full flex-1 flex-col gap-y-4 overflow-y-scroll'}>
          {categories?.centrality.map((entity) => (
            <BioentityListItem {...entity} key={entity.name} />
          ))}
        </div>
      </Tab.Panel>
      <Tab.Panel className={'flex h-full max-h-full flex-1 flex-col gap-y-4'}>
        <p
          className={
            'flex-none rounded-md bg-blue-50 px-[14px] py-2 text-sm text-blue-400 ring-1 ring-inset ring-blue-300'
          }
        >
          {I18n.get('The total number of inbound and outbound edges for a node.')}
        </p>
        <div
          className={'flex h-full max-h-full flex-1 flex-col gap-y-4 overflow-y-scroll'}
        >
          {categories?.degree.map((entity) => (
            <BioentityListItem {...entity} key={entity.name} />
          ))}
        </div>
      </Tab.Panel>
      <Tab.Panel className={'flex h-full max-h-full flex-1 flex-col gap-y-4'}>
        <p
          className={
            'flex-none rounded-md bg-blue-50 px-[14px] py-2 text-sm text-blue-400 ring-1 ring-inset ring-blue-300'
          }
        >
          {I18n.get('The total number of inbound edges for a node.')}
        </p>
        <div
          className={'flex h-full max-h-full flex-1 flex-col gap-y-4 overflow-y-scroll'}
        >
          {categories?.degreeIn.map((entity) => (
            <BioentityListItem {...entity} key={entity.name} />
          ))}
        </div>
      </Tab.Panel>
      <Tab.Panel className={'flex h-full max-h-full flex-1 flex-col gap-y-4'}>
        <p
          className={
            'flex-none rounded-md bg-blue-50 px-[14px] py-2 text-sm text-blue-400 ring-1 ring-inset ring-blue-300'
          }
        >
          {I18n.get('The total number of outbound edges for a node.')}
        </p>
        <div
          className={'flex h-full max-h-full flex-1 flex-col gap-y-4 overflow-y-scroll'}
        >
          {categories?.degreeOut.map((entity) => (
            <BioentityListItem {...entity} key={entity.name} />
          ))}
        </div>
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
);

export { Statistics };
