import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC, useState } from 'react';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

import type { LegendProps } from './legend.types';

const Dot: FC = () => (
  <svg viewBox={'0 0 2 2'} className={'h-2 w-2 fill-current'}>
    <circle cx={1} cy={1} r={1} />
  </svg>
);

/**
 * Graph legend.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Legend: FC<LegendProps> = ({ className, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <div className={'relative'}>
        <ul
          className={clsx(
            'absolute right-0 top-0 flex w-24 select-none flex-col gap-1 rounded-md bg-neutral-100/70 px-3.5 py-2.5 text-xs ring-1 ring-inset ring-neutral-200 backdrop-blur lg:w-auto lg:flex-row lg:gap-4'
          )}
        >
          {isOpen ? (
            <>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-protein'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Protein')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-disease'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Disease')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-drug'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Drug')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-pathway'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Pathway')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-pathogen'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Pathogen')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-gene'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Gene')}</span>
              </li>
              <li className={'flex flex-nowrap items-center gap-x-1.5 text-literature'}>
                <Dot />
                <span className={'text-neutral-500'}>{I18n.get('Literature')}</span>
              </li>
            </>
          ) : (
            <li className={'flex flex-nowrap items-center gap-x-1.5 text-neutral-300'}>
              <Dot />
              <span className={'text-neutral-500'}>{I18n.get('Legend')}</span>
            </li>
          )}
        </ul>
        <button
          className={
            'absolute -right-2.5 -top-2.5 flex items-center rounded-full bg-neutral-50 p-1.5 text-neutral-400 ring-1 ring-inset ring-neutral-200 hover:text-neutral-500 hover:shadow-sm hover:ring-neutral-300'
          }
          onClick={() => setIsOpen(!isOpen)}
          onKeyUp={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <FiMinimize2 /> : <FiMaximize2 />}
        </button>
      </div>
    </div>
  );
};

export { Legend };
