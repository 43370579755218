import { clsx } from 'clsx';
import { type ComponentProps, type FC, useState } from 'react';

import { Item, Metadata, ViewToggle } from './components';
import type { ListViewProps } from './list-view.types';

/**
 * Search results presented as a list.
 *
 * @author Malik Alimoekhamedov
 * @see ListViewProps
 */
const ListView: FC<ListViewProps> = ({ items, className, searchTerms }) => {
  const [isModalPanelOpen, setIsModalPanelOpen] = useState(false);
  const [expandedListItem, setExpandedListItem] = useState<ComponentProps<typeof Item>>();
  const [view, setView] = useState<'LIST' | 'GRID'>('LIST');

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      <ViewToggle className={'ml-auto justify-self-end'} view={view} onChange={setView} />
      <ul
        className={clsx(
          '-mx-2 gap-6 overflow-y-scroll px-2 pb-6 md:-mx-4 md:px-4',
          view === 'LIST'
            ? 'flex flex-col'
            : '3xl:grid-cols-4 grid grid-cols-1 place-items-stretch md:grid-cols-2 lg:grid-cols-3'
        )}
      >
        {items?.map((item) => (
          <Item
            {...item}
            key={item.id}
            onClick={() => {
              setExpandedListItem(item);
              setIsModalPanelOpen(true);
            }}
            searchTerms={searchTerms}
          />
        ))}
      </ul>
      <Metadata
        isOpen={isModalPanelOpen}
        onClose={() => setIsModalPanelOpen(false)}
        searchTerms={searchTerms}
        sentence={expandedListItem?.sentence ?? ''} // TODO: This is no good.
        context={expandedListItem?.context ?? ''} // TODO: This is no good.
        type={expandedListItem?.type ?? 'Scientific Literature'} // TODO: This is no good.
        {...expandedListItem}
      />
    </div>
  );
};

export { ListView };
