import { Popover, Transition } from '@headlessui/react';
import type { FC } from 'react';
import { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { usePopper } from 'react-popper';

import type { HintProps } from './hint.types';

/**
 * 'Hint' component.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see HintProps
 */
const Hint: FC<HintProps> = ({ className, text, iconSize = 5 }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className={`${className} z-10`}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={`flex ${
              open ? 'text-blue-500' : 'text-gray-400'
            } transition-colors hover:text-blue-700 focus:outline-none`}
          >
            <FiHelpCircle className={`w-${iconSize}`} />
          </Popover.Button>
          <Transition
            show={open}
            enter={'transition ease-out duration-100'}
            enterFrom={'transform opacity-0 scale-95'}
            enterTo={'transform opacity-100 scale-100'}
            leave={'transition ease-in duration-75'}
            leaveFrom={'transform opacity-100 scale-100'}
            leaveTo={'transform opacity-0 scale-95'}
          >
            <Transition.Child>
              <Popover.Panel
                className={
                  'animate-fade absolute m-1 flex min-w-max gap-x-2 rounded-md bg-blue-100 p-4 text-blue-700 shadow-lg shadow-blue-400/10'
                }
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <FiHelpCircle
                  className={'h-5 w-5 flex-shrink-0 text-blue-500'}
                  aria-hidden={'true'}
                />
                <p className={'max-w-lg text-sm font-light'}>{text}</p>
              </Popover.Panel>
            </Transition.Child>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export { Hint };
