import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { useAuthContext } from 'context';
import { Payment } from 'pages/utils';
import type { FC } from 'react';
import { FiAward } from 'react-icons/fi';

/* import { Link } from 'react-router-dom'; */
import placeholderPNG from './assets/literature-nodes.png';
import placeholderWEBP from './assets/literature-nodes.webp';

/**
 * A juicy CTA nudging users to go PRO.
 *
 * @author Malik Alimoekhamedov
 */
const Upgrade: FC = () => {
  const { user } = useAuthContext();

  return (
    <div
      className={
        'noise relative isolate overflow-hidden rounded-lg bg-sky-950 px-6 pt-16 shadow-2xl sm:px-16 md:rounded-2xl md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0'
      }
    >
      <svg
        viewBox={'0 0 1024 1024'}
        className={
          'absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0'
        }
        aria-hidden={'true'}
      >
        <circle cx={512} cy={512} r={512} fill={'url(#gradient)'} fillOpacity={'0.7'} />
        <defs>
          <radialGradient id={'gradient'}>
            <stop stopColor={'#0369a1'} />
            <stop offset={1} stopColor={'#10b981'} />
          </radialGradient>
        </defs>
      </svg>
      <div
        className={
          'mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left'
        }
      >
        <h2
          className={
            'select-none text-4xl font-black tracking-tight text-white/95 md:text-6xl'
          }
        >
          {I18n.get('Discover More.')}
        </h2>
        <p className={'mt-6 select-none text-base leading-7 text-neutral-200/70'}>
          {I18n.get(
            `Get unlimited access to knowledge extracted from millions of vetted scientific publication, molecular data, and much more.`
          )}
        </p>
        <div
          className={'mt-10 flex items-center justify-center gap-x-6 lg:justify-start'}
        >
          <Button
            icon={<FiAward />}
            colorTheme={'GRAY'}
            onClick={() => {
              user && new Payment(user).createCheckoutSession();
            }}
          >
            {I18n.get('Go PRO')}
          </Button>
          {/* <Link
            className={
              'select-none text-neutral-50 transition-colors hover:text-neutral-400'
            }
            to={'/'}
          >
            {I18n.get('Learn more')} →
          </Link> */}
        </div>
      </div>
      <div className={'relative mt-16 h-80 lg:mt-8'}>
        <picture>
          <source
            className={
              'absolute left-0 top-0 w-[57rem] max-w-none rounded-lg bg-white/5 opacity-95 shadow-xl ring-1 ring-white/10'
            }
            width={1824}
            height={1080}
            srcSet={placeholderWEBP}
          />
          <img
            className={
              'absolute left-0 top-0 w-[57rem] max-w-none rounded-lg bg-white/5 opacity-95 shadow-xl ring-1 ring-white/10'
            }
            width={1824}
            height={1080}
            alt={'Web application screenshot'}
            src={placeholderPNG}
          />
        </picture>
      </div>
    </div>
  );
};

export { Upgrade };
