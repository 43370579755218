// Loaders
import { loader as loaderDiscover } from './discover';

const loaders = { discover: loaderDiscover };

export { loaders };

// Routes
export { routes as authRoutes } from './auth';

// Pages
export { Discover } from './discover';
export { Modal } from './components';
export { Account } from './account';
export { Notes } from './notes';
export { Dashboard } from './dashboard';
