import { signOut } from 'aws-amplify/auth';
import { Navigate, Outlet, redirect, type RouteObject } from 'react-router-dom';

import { Authorization } from './authorization';
import { EmailConfirmation } from './email-confirmation';
import { ForgotPassword } from './forgot-password';
import { ResetPassword } from './reset-password';
import { SignIn } from './sign-in';
import { SignUp } from './sign-up';
import { Success } from './success';

/**
 * Authentication flow-related routes.
 *
 * @author Malik Alimoekhamedov
 * @category Routes
 */
const routes: ReadonlyArray<RouteObject> = [
  {
    path: '/auth',
    element: <Outlet />,
    children: [
      { path: 'sign-in', element: <SignIn /> },
      { path: 'sign-up', element: <SignUp /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'email-confirmation', element: <EmailConfirmation /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'authorization', element: <Authorization /> },
      { path: 'success', element: <Success /> },
      {
        path: 'sign-out',
        action: async () => {
          await signOut();
          return redirect('/auth/sign-in');
        },
      },
      { path: '*', element: <Navigate to={'sign-in'} /> },
    ],
  },
];

export { routes };
