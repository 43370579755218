import type { JWT } from 'aws-amplify/auth';

/**
 * Cognito user pool groups.
 *
 * @author Malik Alimoekhamedov
 */
enum SubscriptionPlan {
  DEFAULT = 'default',
  DELTA_CLUSTER = 'DELTA_CLUSTER',
  FAUNA_CLUSTER = 'FAUNA_CLUSTER',
  FREEMIUM = 'FREEMIUM',
  FREEMIUM_ADMIN = 'FREEMIUM_ADMIN',
  FREEMIUM_X_EDU = 'FREEMIUM_X_EDU',
  GAMMA_CLUSTER = 'GAMMA_CLUSTER',
  GAMMA_CLUSTER_DEV = 'GAMMA_CLUSTER_DEV',
  H3_CLUSTER = 'H3_CLUSTER',
  SEMANTIC_SCHOLAR_TEST_CLUSTER = 'SEMANTIC_SCHOLAR_TEST_CLUSTER',
}

/**
 * Cognito user account type.
 *
 * @author Malik Alimoekhamedov
 */
type UserAccount = {
  readonly id: string;
  readonly email: string;
  readonly username: string;
  readonly avatar?: string;
  readonly city?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly timeZone?: string;
  readonly country?: string;
  readonly company?: string;
  readonly subscriptionPlan?: SubscriptionPlan;
  readonly jwtToken?: JWT;
};

export type { UserAccount };
export { SubscriptionPlan };
