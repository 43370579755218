import { I18n } from 'aws-amplify/utils';
import { Input } from 'components';
import { type FC } from 'react';
import { FiSearch } from 'react-icons/fi';

import { type SearchWithinProps } from './search-within.types';

/**
 * Utility component allowing for operations specific to the graph
 * currently displayed.
 *
 * @author Malik Alimoekhamedov
 * @see SearchWithinProps
 */
const SearchWithin: FC<SearchWithinProps> = ({ onChange, ...rest }) => (
  <Input
    {...rest}
    icon={<FiSearch />}
    placeholder={I18n.get('Search in Graph')}
    type={'search'}
    onChange={(event) => onChange(event.target.value)}
  />
);

export { SearchWithin };
