import { I18n } from 'aws-amplify/utils';
import { boolean, object, ref, string } from 'yup';
import { ObjectSchema } from 'yup';

import { type SignInValidationForm } from './sign-in';

const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

type SignUpValidationForm = {
  readonly email: string;
  readonly password: string;
  readonly repeatPassword: string;
};

type ForgotPasswordValidationForm = {
  readonly email: string;
};

type EmailConfirmationValidationForm = {
  readonly code: string;
};

type ResetPasswordValidationForm = {
  readonly email: string;
  readonly code: string;
  readonly password: string;
  readonly repeatPassword: string;
};

type ChangePasswordValidationForm = {
  readonly currentPassword: string;
  readonly newPassword: string;
  readonly confirmNewPassword: string;
};

type AuthorizationValidationForm = {
  readonly email: string;
  readonly password: string;
  readonly newPassword: string;
  readonly repeatPassword: string;
  readonly shouldPersist: boolean;
};

const SignInValidationSchema: ObjectSchema<SignInValidationForm> = object().shape({
  email: string().email().required().label(I18n.get('Email')),
  password: string().required().min(8).label(I18n.get('Password')),
  shouldPersist: boolean().required(),
});

const SignUpValidationSchema: ObjectSchema<SignUpValidationForm> = object().shape({
  email: string()
    .email()
    .required(I18n.get('Email is required.'))
    .label(I18n.get('Email')),
  password: string()
    .required(I18n.get('Email is required.'))
    .matches(strongPasswordRegex, I18n.get('Week password.'))
    .label(I18n.get('Password')),
  repeatPassword: string()
    .required()
    .oneOf([ref('password'), 'dummyPassword'], 'Passwords must match.')
    .label(I18n.get('Repeat password')),
});

const ForgotPasswordSchema: ObjectSchema<ForgotPasswordValidationForm> = object().shape({
  email: string().email().required().label(I18n.get('Email')),
});

const EmailConfirmationSchema: ObjectSchema<EmailConfirmationValidationForm> =
  object().shape({
    code: string()
      .matches(/^[0-9]+$/, I18n.get('Must be only digits.'))
      .min(6, I18n.get('Must be exactly 6 digits.'))
      .max(6, I18n.get('Must be exactly 6 digits.'))
      .required()
      .label(I18n.get('Code')),
  });

const ResetPasswordSchema: ObjectSchema<SignUpValidationForm> = object().shape({
  email: string()
    .email()
    .required(I18n.get('Email is required.'))
    .label(I18n.get('Email')),
  code: string()
    .matches(/^[0-9]+$/, I18n.get('Must be only digits.'))
    .min(6, I18n.get('Must be exactly 6 digits.'))
    .max(6, I18n.get('Must be exactly 6 digits.'))
    .required()
    .label(I18n.get('Code')),
  password: string()
    .required(I18n.get('Password is required.'))
    .min(8, I18n.get('The password should be at least 8 characters long'))
    .label(I18n.get('Password')),
  repeatPassword: string()
    .required()
    .oneOf([ref('password'), 'dummyPassword'], I18n.get('Passwords must match.'))
    .label(I18n.get('Repeat password')),
});

const ChangePasswordValidationSchema: ObjectSchema<ChangePasswordValidationForm> = object(
  {
    currentPassword: string().trim(),
    newPassword: string().trim(),
    confirmNewPassword: string().trim(),
  }
).shape({
  currentPassword: string()
    .required(I18n.get('Enter current password'))
    .min(8, I18n.get('The password should be at least 8 characters long'))
    .label(I18n.get('Current Password')),
  newPassword: string()
    .required(I18n.get('Enter new password'))
    .min(8, I18n.get('The password should be at least 8 characters long'))
    .label(I18n.get('New Password')),
  confirmNewPassword: string()
    .required(I18n.get('Enter new password again'))
    .min(8, I18n.get('The password should be at least 8 characters long'))
    .oneOf([ref('newPassword'), 'dummyPassword'], I18n.get('Passwords must match.'))
    .label(I18n.get('Confirm New Password')),
});

const AuthorizationValidationSchema: ObjectSchema<AuthorizationValidationForm> =
  object().shape({
    email: string()
      .email()
      .required(I18n.get('Email is required.'))
      .label(I18n.get('Email')),
    password: string()
      .required(I18n.get('Password is required.'))
      .min(8)
      .label(I18n.get('Password')),
    newPassword: string()
      .required(I18n.get('Password is required.'))
      .matches(strongPasswordRegex, I18n.get('Week password.'))
      .label(I18n.get('New password')),
    repeatPassword: string()
      .required()
      .oneOf([ref('repeatPassword'), 'dummyPassword'], I18n.get('Passwords must match.'))
      .label(I18n.get('Repeat password')),
    shouldPersist: boolean().required(),
  });

export {
  SignInValidationSchema,
  SignUpValidationSchema,
  ForgotPasswordSchema,
  ChangePasswordValidationSchema,
  EmailConfirmationSchema,
  ResetPasswordSchema,
  AuthorizationValidationSchema,
  strongPasswordRegex,
};

export type {
  SignInValidationForm,
  SignUpValidationForm,
  ForgotPasswordValidationForm,
  ChangePasswordValidationForm,
  EmailConfirmationValidationForm,
  ResetPasswordValidationForm,
  AuthorizationValidationForm,
};
