import { Dialog, Transition } from '@headlessui/react';
import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC, Fragment, type PropsWithChildren } from 'react';
import { FiX } from 'react-icons/fi';

import type { ModalProps } from './modal.types';

/**
 * 'Modal' component
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ModalProps
 */
const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  className,
  onClose,
  open = false,
  title,
  description,
  showCloseIcon = true,
}) => (
  <Transition.Root show={open} as={Fragment} appear>
    <Dialog
      as={'div'}
      className={
        'fixed left-0 top-0 z-10 flex h-screen w-full items-center justify-center'
      }
      onClose={onClose}
    >
      <Transition.Child
        as={Fragment}
        enter={'ease-out duration-300'}
        enterFrom={'opacity-0'}
        enterTo={'opacity-100'}
        leave={'ease-in duration-200'}
        leaveFrom={'opacity-100'}
        leaveTo={'opacity-0'}
      >
        <div
          className={
            'fixed inset-0 bg-neutral-500 bg-opacity-75 backdrop-blur-xl transition-opacity'
          }
        />
      </Transition.Child>
      <Transition.Child
        as={'div'}
        className={
          'inset-0 m-auto flex h-fit w-fit min-w-[288px] max-w-xl flex-col gap-y-4 overflow-y-auto rounded-lg bg-white p-6 shadow-xl'
        }
        enter={'ease-out duration-300'}
        enterFrom={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
        enterTo={'opacity-100 translate-y-0 sm:scale-100'}
        leave={'ease-in duration-200'}
        leaveFrom={'opacity-100 translate-y-0 sm:scale-100'}
        leaveTo={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
      >
        {showCloseIcon && (
          <button
            type={'button'}
            className={
              'absolute right-6 top-6 rounded text-neutral-400 ring-blue-500 hover:text-neutral-500 focus:text-blue-500 focus:outline-none focus:ring-2'
            }
          >
            <span className={'sr-only'}>{I18n.get('Close')}</span>
            <FiX className={'h-6 w-6'} type={'button'} onClick={onClose} />
          </button>
        )}
        {title && (
          <Dialog.Title
            className={clsx(
              'select-none text-lg font-medium text-neutral-600',
              showCloseIcon ? 'w-[calc(100%-24px)]' : 'w-full'
            )}
          >
            {title}
          </Dialog.Title>
        )}
        {description && (
          <Dialog.Description className={'select-none text-sm text-neutral-400'}>
            {description}
          </Dialog.Description>
        )}
        <Dialog.Panel className={className}>{children}</Dialog.Panel>
      </Transition.Child>
    </Dialog>
  </Transition.Root>
);

export { Modal };
