import loadable from '@loadable/component';
import { I18n } from 'aws-amplify/utils';
import { Shell } from 'pages/components';
import { type FC } from 'react';
import { Navigate, Outlet, type RouteObject, useLocation } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';

import { loader as loaderRoot } from './app.loader';
import { authRoutes, Dashboard, loaders } from './pages';
const Discover = loadable(async () => {
  const { Discover } = await import('./pages');
  return Discover;
});

const Account = loadable(async () => {
  const { Account } = await import('./pages');
  return Account;
});

/**
 * Top-level application router.
 *
 * @author Malik Alimoekhamedov
 * @category Routers
 */
const ProtectedRoutes: FC = () => {
  const { user } = useLoaderData<typeof loaderRoot>();
  const location = useLocation();

  return user ? (
    <Shell>
      <Outlet />
    </Shell>
  ) : (
    // The location is provided to redirect the user to where he was interrupted
    // by the login prompt. Maybe this is not necessary if there's no pre-login-wall
    // pages at all. To be discussed. In the meantime, better safe than sorry.
    <Navigate to={'/auth/sign-in'} state={{ from: location }} />
  );
};

const routes: Array<RouteObject> = [
  ...authRoutes,
  {
    id: 'root',
    path: '/',
    loader: loaderRoot,
    element: <ProtectedRoutes />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
        handle: { crumb: { to: '/dashboard', label: I18n.get('Dashboard') } },
      },
      {
        path: 'discover',
        element: <Discover />,
        loader: loaders.discover,
        handle: { crumb: { to: '/discover', label: I18n.get('Discover') } },
      },
      {
        path: 'account',
        element: <Account />,
        handle: { crumb: { to: '/account', label: I18n.get('Account') } },
      },
      { path: '*', element: <Navigate to={'/dashboard'} /> },
    ],
  },
];

export { routes };
