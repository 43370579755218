import { type FC } from 'react';

import { NodeTypePill } from '../../../../../components';
import type { SeparatorProps } from './separator.types';

/**
 * Separator for item and metadata component
 *
 * @author Malik Alimoekhamedov, Emmanuel Alofe
 * @see SeparatorProps
 */
const Separator: FC<SeparatorProps> = ({
  type,
  journalTitle,
  yearPublished,
  volume,
  issue,
}) => (
  <div className={'flex flex-wrap items-center gap-2 md:gap-4'}>
    {type && <NodeTypePill type={type} />}
    {journalTitle && (
      <>
        <svg
          viewBox={'0 0 2 2'}
          className={'invisible h-0.5 w-0.5 fill-current md:visible'}
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        <span className={'text-xs italic text-neutral-400'}>{journalTitle}</span>
      </>
    )}
    {volume && (
      <>
        <svg
          viewBox={'0 0 2 2'}
          className={'invisible h-0.5 w-0.5 fill-current md:visible'}
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        <span className={'text-xs italic text-neutral-400'}>Volume {volume}</span>
      </>
    )}
    {issue && (
      <>
        <svg
          viewBox={'0 0 2 2'}
          className={'invisible h-0.5 w-0.5 fill-current md:visible'}
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        <span className={'text-xs italic text-neutral-400'}>Issue {issue}</span>
      </>
    )}
    {yearPublished && (
      <>
        <svg
          viewBox={'0 0 2 2'}
          className={'invisible h-0.5 w-0.5 fill-current md:visible'}
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        <time
          className={'text-xs font-normal text-neutral-400'}
          dateTime={yearPublished.toString()}
        >
          {yearPublished}
        </time>
      </>
    )}
  </div>
);

export { Separator };
