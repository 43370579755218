import { I18n } from 'aws-amplify/utils';
import type { FC } from 'react';

import { Upgrade } from './components';

/**
 * User dashboard page.
 *
 * @author Malik Alimoekhamedov
 */
const Dashboard: FC = () => (
  <section>
    <h1
      className={
        'mb-4 text-2xl font-semibold leading-7 text-neutral-900 sm:truncate sm:text-3xl sm:tracking-tight'
      }
    >
      {I18n.get('Dashboard')}
    </h1>
    <Upgrade />
  </section>
);

export { Dashboard };
