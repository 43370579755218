import { I18n } from 'aws-amplify/utils';
import { Button, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import {
  type ChangePasswordValidationForm,
  ChangePasswordValidationSchema,
} from 'pages/auth';
import type { FC } from 'react';
import toast from 'react-hot-toast';
import { FiSave } from 'react-icons/fi';

/**
 * Password management widget for the user account page.
 *
 * @author Malik Alimoekhamedov
 */
const PasswordManagement: FC = () => {
  const { changePassword } = useAuthContext();
  const { handleSubmit, errors, values, handleChange } =
    useFormik<ChangePasswordValidationForm>({
      initialValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
      validationSchema: ChangePasswordValidationSchema,
      onSubmit: (values) =>
        changePassword(values, () =>
          toast.success(I18n.get('Password successfully reset'))
        ),
    });

  return (
    <section className={'rounded-md bg-white px-6 pb-6 pt-4 shadow-lg'}>
      <h1 className={'mb-4 text-lg font-semibold text-neutral-700'}>
        {I18n.get('Change Password')}
      </h1>
      <form className={'flex flex-col gap-y-8'} onSubmit={handleSubmit}>
        <div className={'flex flex-col gap-8 lg:flex-row lg:gap-12'}>
          <div className={'flex flex-1 flex-col gap-y-4'}>
            <Input
              id={'currentPassword'}
              name={'currentPassword'}
              label={I18n.get('Current Password')}
              placeholder={I18n.get('Current Password')}
              type={'password'}
              required
              value={values.currentPassword}
              error={errors.currentPassword}
              onChange={handleChange}
            />
            <Input
              id={'newPassword'}
              name={'newPassword'}
              label={I18n.get('New Password')}
              placeholder={I18n.get('New Password')}
              type={'password'}
              required
              value={values.newPassword}
              error={errors.newPassword}
              onChange={handleChange}
            />
            <Input
              id={'confirmNewPassword'}
              name={'confirmNewPassword'}
              label={I18n.get('Confirm New Password')}
              placeholder={I18n.get('Confirm New Password')}
              type={'password'}
              required
              value={values.confirmNewPassword}
              error={errors.confirmNewPassword}
              onChange={handleChange}
            />
          </div>
          <div
            className={
              'flex flex-col gap-y-2 text-sm font-light text-neutral-400 lg:w-1/2'
            }
          >
            <h2 className={'text-normal font-medium text-neutral-700'}>
              {I18n.get('Password Requirements')}
            </h2>
            <p>
              {I18n.get(
                'Your password should comply with the following security measures:'
              )}
            </p>
            <ol className={'list-inside list-decimal'}>
              <li>{I18n.get('Minimum 8 symbols')}</li>
              <li>{I18n.get('Contains at least 1 number')}</li>
              <li>
                {I18n.get(
                  `Contains at least 1 special character such as ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =`
                )}
              </li>
              <li>{I18n.get('Contains at least 1 uppercase letter')}</li>
              <li>{I18n.get('Contains at least 1 lowercase letter')}</li>
            </ol>
          </div>
        </div>
        <Button
          className={'lg:w-fit'}
          type={'submit'}
          icon={<FiSave />}
          disabled={Object.entries(errors).length > 0}
        >
          {I18n.get('Update Password')}
        </Button>
      </form>
    </section>
  );
};

export { PasswordManagement };
