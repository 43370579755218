import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { useFormik } from 'formik';
import { Modal } from 'pages/components/modal';
import { type FC } from 'react';
import { FiPenTool } from 'react-icons/fi';
import sanitizeHTML from 'sanitize-html';

import type { AnnotationDialogProps } from './annotation-dialog.types';

/**
 * A modal dialog allowing users to annotate graph elements.
 *
 * @author Malik Alimoekhamedov
 * @see AnnotationDialogProps
 */
const AnnotationDialog: FC<AnnotationDialogProps> = (props) => {
  const { handleSubmit, handleChange, values } = useFormik<{
    readonly annotation?: string;
  }>({
    initialValues: { annotation: undefined },
    onSubmit: ({ annotation }) =>
      annotation ? props.onSubmit(sanitizeHTML(annotation.trim())) : undefined,
  });

  return (
    <Modal
      {...props}
      title={I18n.get('Annotate Element')}
      description={I18n.get(
        'You can annotate nodes and edges of the graph with private notes.'
      )}
      showCloseIcon={false}
    >
      <form onSubmit={handleSubmit}>
        <textarea
          className={'mb-4 w-full rounded-md border border-gray-300 text-gray-500'}
          name={'annotation'}
          value={values.annotation}
          placeholder={I18n.get('Note down interesting findings regarding this node.')}
          rows={6}
          onChange={handleChange}
        />
        <Button
          className={'w-full'}
          isLoading={props.isLoading}
          type={'submit'}
          disabled={!values.annotation}
          icon={<FiPenTool />}
        >
          {I18n.get(props.isLoading ? 'Annotating' : 'Annotate')}
        </Button>
      </form>
    </Modal>
  );
};

export { AnnotationDialog };
