import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import { SubscriptionPlan, type UserAccount } from 'types';

/**
 * @author Malik Alimoekhamedov
 * @category Utils
 */
const getUser = async () => {
  try {
    const currentUser = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();
    const authSession = await fetchAuthSession();

    return {
      username: currentUser.username,
      id: currentUser.userId,
      email: userAttributes.email ?? '',
      avatar: userAttributes.picture,
      firstName: userAttributes.name,
      lastName: userAttributes.family_name,
      jwtToken: authSession.tokens?.idToken,
      subscriptionPlan: SubscriptionPlan.FREEMIUM_X_EDU,
      // TODO: Uncomment when we switch back to restricting access based on Stripe.
      //subscriptionPlan: userAttributes['custom:group_id'] as SubscriptionPlan,
    } satisfies UserAccount;
  } catch (error) {
    console.error(error);
  }
};

export { getUser };
