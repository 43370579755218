import { Cache } from 'aws-amplify/utils';
import { IS_MAIN_MENU_COLLAPSED, REGISTERED_LANGUAGE } from 'constants/cache';
import { getSupportedLanguages, registerLocale } from 'i18n-iso-countries';
import { type LoaderFunction, redirect } from 'react-router-dom';
import { getUser } from 'utils';

const getPreferredLanguage = () => {
  const preferredLanguage = navigator.language.slice(0, 2);
  return getSupportedLanguages().includes(preferredLanguage) ? preferredLanguage : 'en';
};

/**
 * Application's root loader.
 *
 * @author Malik Alimoekhamedov
 */
const loader = (async () => {
  // Language
  const preferredLanguage = getPreferredLanguage();
  await Cache.getItem(REGISTERED_LANGUAGE, {
    callback: () => preferredLanguage,
  });
  registerLocale(require(`i18n-iso-countries/langs/${preferredLanguage}.json`));

  // User
  const user = await getUser();

  if (user) {
    // Main Menu
    const isMainMenuCollapsed: boolean = await Cache.getItem(IS_MAIN_MENU_COLLAPSED, {
      callback: () => false,
    });

    return { user, isMainMenuCollapsed };
  }

  return redirect('/auth/sign-in');
}) satisfies LoaderFunction;

export { loader };
