import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import type { FC } from 'react';
import { FiCheck } from 'react-icons/fi';
import { redirect } from 'react-router-dom';

import { Container } from '../components';

/**
 * Successful authentication.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Success: FC = () => (
  <Container title={I18n.get('Welcome')}>
    <div className={'flex flex-col items-center'}>
      <div
        className={'bg-green-100 flex h-12 w-12 items-center justify-center rounded-full'}
      >
        <FiCheck className={'text-green-500'} width={24} />
      </div>
      <h1 className={'mt-5 text-lg font-medium'}>{I18n.get('Welcome to Nextnet')}</h1>
      <p className={'mt-2 text-center font-light text-neutral-500'}>
        {I18n.get('Thank you for creating an account on Nextnet')}
      </p>
      <Button
        onClick={() => redirect('sign-in')}
        className={'mt-6 w-full justify-center'}
        icon={<FiCheck />}
      >
        {I18n.get('OK')}
      </Button>
    </div>
  </Container>
);

export { Success };
