import { clsx } from 'clsx';
import { type FC } from 'react';

import { type ButtonProps } from './button.types';

//TODO: Enrich with labels, conditional animations, tooltips and a11y.
/**
 * Graph controls' button component.
 *
 * @author Malik Alimoekhamedov
 * @see ButtonProps
 */
const Button: FC<ButtonProps> = ({ onClick, title, icon, className, disabled }) => (
  <button
    className={clsx(
      'relative flex h-9 w-9 items-center justify-center rounded-full bg-white/30 shadow-md ring-1 ring-inset backdrop-blur-sm',
      disabled
        ? 'cursor-not-allowed ring-neutral-200/60 [&>*]:text-neutral-200/60'
        : 'ring-neutral-600 transition-colors hover:ring-neutral-400 [&>*]:transition-colors [&>*]:hover:text-neutral-400',
      className
    )}
    disabled={disabled}
    title={title}
    onClick={onClick}
    onKeyUp={onClick}
  >
    {icon}
    <span
      className={
        'absolute mt-16 w-[50px] whitespace-nowrap rounded bg-white py-0.5 text-[9px] text-neutral-600 shadow-sm ring-1 ring-inset ring-neutral-50'
      }
    >
      {title}
    </span>
  </button>
);

export { Button };
