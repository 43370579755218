import { Disclosure } from '@headlessui/react';
import { I18n } from 'aws-amplify/utils';
import { type FC } from 'react';

import { Category } from '../category';
import type { PathwayProps } from './pathway.types';

const formatMSigDB = (MSigString: string) => {
  const tmp = MSigString.split(', ');
  const MSigArr = [];

  for (const gene of tmp) {
    MSigArr.push({ link: `${gene}`, gene: `${gene}, ` }); // `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`
  }

  const MSigMap = MSigArr.map(({ link, gene }) => (
    <a key={gene} href={link} target={'_blank'} rel={'noreferrer'}>
      {gene}
    </a>
  ));

  return MSigMap;
};

const Pathway: FC<PathwayProps> = ({ categories, genes, source }) => (
  <>
    {Object.entries(categories).map((category) => (
      <Category
        key={category[0]}
        name={category[0] as 'Primitive Information'}
        data={category[1]}
      />
    ))}
    {source && (
      <div className={'text-sm'}>
        <span className={'font-bold'}>{I18n.get('source')}:</span>
        <a href={source} target={'_blank'} rel={'noreferrer'}>
          {source}
        </a>
      </div>
    )}
    {genes && (
      <Disclosure>
        <Disclosure.Button>{I18n.get('Genes')}</Disclosure.Button>
        <Disclosure.Panel>
          {formatMSigDB(genes.replace(/'/g, '').slice(1, -1))}
        </Disclosure.Panel>
      </Disclosure>
    )}
  </>
);

export { Pathway };
