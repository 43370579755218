import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'components';
import { type FC, Fragment } from 'react';
import { FiX } from 'react-icons/fi';
import sanitizeHTML from 'sanitize-html';

import type { SidePopupProps } from './side-popup.types';

/**
 * A vertical animated popup on the right side of the screen
 *
 * @author Malik Alimoekhamedov, Emmanuel Alofe
 * @category Components
 * @see SidePopupProps
 */
const SidePopup: FC<SidePopupProps> = ({
  isOpen = false,
  onClose,
  children,
  title,
  titleHTML,
}) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog as={'div'} className={'relative z-10'} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter={'ease-out duration-300'}
        enterFrom={'opacity-0'}
        enterTo={'opacity-100'}
        leave={'ease-in duration-200'}
        leaveFrom={'opacity-100'}
        leaveTo={'opacity-0'}
      >
        <div className={'fixed inset-0 bg-black/50'} />
      </Transition.Child>
      <Transition.Child
        as={'div'}
        className={'fixed inset-y-0 right-0 w-full shadow-xl md:w-2/3 lg:w-1/2 xl:w-1/3'}
        enter={'transform transition ease-in-out duration-500 sm:duration-700'}
        enterFrom={'translate-x-full'}
        enterTo={'translate-x-0'}
        leave={'transform transition ease-in-out duration-500 sm:duration-700'}
        leaveFrom={'translate-x-0'}
        leaveTo={'translate-x-full'}
      >
        {/* <button
          type={'button'}
          className={
            'absolute right-4 top-4 rounded text-neutral-400 ring-blue-500 hover:text-neutral-500 focus:text-blue-500 focus:outline-none focus:ring-2'
          }
        >
          <span className={'sr-only'}>{I18n.get('Close')}</span>
          <FiX className={'h-6 w-6'} type={'button'} onClick={onClose} />
        </button> */}
        <div
          className={'fixed right-1 top-1 z-10 rounded bg-white/80 md:right-2 md:top-2'}
        >
          <Button
            icon={<FiX />}
            shape={'BORDERLESS'}
            colorTheme={'GRAY'}
            onClick={() => onClose(!isOpen)}
          />
        </div>
        <Dialog.Panel
          className={
            'pointer-events-auto h-full overflow-y-scroll bg-white p-4 md:p-5 lg:p-6'
          }
        >
          <Dialog.Title
            as={'h1'}
            className={
              'mb-4 mr-8 line-clamp-6 font-medium text-neutral-700 md:mr-9 md:line-clamp-none md:text-lg md:leading-snug [&>em]:bg-yellow-200 [&>em]:font-semibold [&>em]:not-italic'
            }
            dangerouslySetInnerHTML={
              !title && titleHTML
                ? {
                    __html: sanitizeHTML(titleHTML),
                  }
                : undefined
            }
          >
            {title}
          </Dialog.Title>
          {children}
        </Dialog.Panel>
      </Transition.Child>
    </Dialog>
  </Transition>
);

export { SidePopup };
