import { ConsoleLogger, I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { useAuthContext } from 'context';
import { type FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { FiUpload } from 'react-icons/fi';
import { GraphSaver } from 'utils';

import { type GraphUploadProps } from './graph-upload.types';

//TODO: Figure out why this is even needed? If it's to restore the history, this is a very convoluted way to do it.

const logger = new ConsoleLogger('GraphUpload');

/**
 * A widget allowing users to upload graphs saved on their computers.
 *
 * @author Malik Alimoekhamedov
 * @see GraphUploadProps
 */
const GraphUpload: FC<GraphUploadProps> = ({ onSuccessfulUpload }) => {
  const { user } = useAuthContext();
  const fileUploadInputRef = useRef<HTMLInputElement>(null);
  const graphSaver = (() => user && new GraphSaver(user))();

  const handleUpload = async (file: File) => {
    if (file.type !== 'application/json') {
      logger.error('Please upload a .json file');
      toast.error('Please upload a .json file');
    } else {
      if (graphSaver) {
        const json = await new Response(file).json();

        graphSaver.updateSearchSetting(json.searchSettings);
        onSuccessfulUpload(json);
      }
    }
  };

  return (
    <>
      <Button
        icon={<FiUpload />}
        colorTheme={'GRAY'}
        shape={'BORDERLESS'}
        aria-label={I18n.get('Upload Graph')}
        onClick={() => fileUploadInputRef.current?.click()}
      />
      <input
        ref={fileUploadInputRef}
        hidden
        accept={'.json'}
        multiple={false}
        type={'file'}
        onChange={({ target: { files } }) => files && files[0] && handleUpload(files[0])}
      />
    </>
  );
};

export { GraphUpload };
