import { NOTES_API } from 'constants/global';
import { useAuthContext } from 'context/auth';
import { type FC, useEffect, useState } from 'react';
import { redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import styled from 'styled-components';

import addThumbnail from './thumbnails/add.png';
import tcellThumbnail from './thumbnails/tcell.png';

const StyledKeyInputSpace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 50%;
  // height: 50%; Take out the height as this will add to the bottom margin effectively (sets max height and width, creating a box)
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;
  margin-bottom: 5vh;

  & > h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const StyledCardSpace = styled.div`
  // display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-left: 5vw;
  margin-right: 5vw;
`;

const StyledTitle = styled.h1`
  display: block;
  font-weight: bold;
  fontsize: 12px;
`;

const StyledText = styled.h1`
  display: block;
  font-weight: normal;
  font-size: 5px;
`;

//Note in React {title} will give the title explcitly
//However, props or any keyword without "{ }" gives the collection of objects present

// var str2 = str1.replace(/(([^\s]+\s\s*){20})(.*)/,"$1…");

//TODO: This component doesn't seem to be used anywhere. Phase it out?
/**
 * Notes page component.
 */
const Notes: FC = () => {
  const { user } = useAuthContext();
  const [searchInput, setSearchInput] = useState('');
  const [notePreviews, setNotePreviews] = useState<
    Record<
      string,
      {
        readonly postID: string;
        readonly title: string;
        readonly timestamp: string; //TODO: Should be Date.
        readonly description: string;
      }
    >
  >({});
  const [loaded, setLoaded] = useState(false);

  const searchByTitleAPI = async () => {
    const response = await fetch(NOTES_API + 'quill-note-title', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        Authorization: user?.jwtToken?.toString() ?? '',
        title: searchInput,
      },
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json();
  };

  const searchByTitle = () => {
    if (searchInput === '') {
      return;
    }

    searchByTitleAPI()
      .then((data) => {
        data.unshift(0); // buffer for creating a new note
        setNotePreviews(data);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };

  useEffect(() => {
    //if title box is filled, retrieve by title, otherwise by time

    const getByTime = async () => {
      const response = await fetch(NOTES_API + 'quill-note-time', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          Authorization: user?.jwtToken?.toString() ?? '',
        },
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    };

    if (searchInput === '') {
      getByTime()
        .then((data) => {
          data.unshift(0); // buffer for creating a new note
          setNotePreviews(data);
          setLoaded(true);
          // //console.log(notePreviews); // JSON data parsed by `data.json()` call
        })
        .catch((err) => {
          // //console.log(err);
        });
    }
  }, [searchInput, user?.jwtToken]);

  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  // //console.log(notePreviews)

  //if search pressed, call the async function and update state
  //otherwise useffect on search bar, call async if empty

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <StyledKeyInputSpace>
        <StyledTitle style={{ fontSize: '30px' }}>User Notes</StyledTitle>
        <StyledText style={{ fontSize: '20px' }}>
          Document Findings, Queries, and Graph States
        </StyledText>
        <InputGroup>
          <Input
            placeholder={'Search entry by title'}
            value={searchInput}
            onChange={handleSearchChange}
          />
          <Button onClick={searchByTitle} size={'sm'}>
            Search
          </Button>
          {/* <Button onClick={createNewNote} size="sm" style={{marginLeft: "1vw"}}>
                    Create New Note
                </Button> */}
        </InputGroup>
      </StyledKeyInputSpace>

      {!loaded ? (
        <div />
      ) : (
        <StyledCardSpace>
          <Row>
            {Object.values(notePreviews).map((note, i) => (
              <Col key={i} sm={'4'} md={'3'}>
                <Card
                  style={{ cursor: 'pointer', height: '85%' }}
                  onClick={
                    i === 0
                      ? () => redirect('notes/create-note')
                      : () => redirect(`notes/${note.postID}`)
                  }
                >
                  <CardImg
                    top
                    width={'100%'}
                    src={i === 0 ? addThumbnail : tcellThumbnail}
                    alt={'Card image cap'}
                  />
                  <CardBody>
                    <CardTitle tag={'h5'} style={{ fontSize: '19px', fontWeight: 600 }}>
                      {i === 0 ? 'Create New Note' : note.title}
                    </CardTitle>
                    <CardSubtitle tag={'h6'} className={'text-muted mb-2'}>
                      {note.timestamp}
                    </CardSubtitle>
                    <CardText style={{ paddingBottom: '5vh' }}>
                      {i === 0
                        ? 'With user notes you can document personal insights from the knowledge graph.'
                        : note.description}
                    </CardText>
                  </CardBody>
                </Card>
                <br />
                {/* 
                                Current system works by a row, with renders of columns on xs=3 for each component
                                
                                Using a break is a shortcut method to working with an empty bottom line
                                It would take more work to set up the maps to correspond to unique rows */}
              </Col>
            ))}
          </Row>
        </StyledCardSpace>
      )}
    </div>
  );
};

export { Notes };
