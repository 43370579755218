import { get, post } from 'aws-amplify/api';
import { ConsoleLogger, I18n } from 'aws-amplify/utils';
import { FREEMIUM } from 'constants/global';
import { isNode } from 'pages/discover/components/graph-view/components/graph/utils';
import type { SearchSettings } from 'pages/discover/discover.types';
import type { ComponentProps } from 'react';
import { toast } from 'react-hot-toast';
import {
  type APIResponseFetch,
  type APIResponseFill,
  SubscriptionPlan,
  type UserAccount,
} from 'types';

import {
  Graph,
  type Link,
  type Node,
} from '../pages/discover/components/graph-view/components';
import { restructureDesiredDatasetsParameters } from './restructure-desired-datasets-parameter';
import { runQueryThroughChatGPT } from './run-query-through-chatgpt';

const logger = new ConsoleLogger('GraphFetcher');

class GraphFetcher {
  graphData: ComponentProps<typeof Graph>['graphData'];
  user?: UserAccount;
  fetchedData: any;

  constructor(user: UserAccount) {
    this.user = user;
    this.fetchedData = {};
  }

  fetchFillGraph = async (data: SearchSettings, callback: () => undefined) => {
    if (this.user?.jwtToken) {
      const restructuredEntityTypesDataset = restructureDesiredDatasetsParameters(
        data.entityTypes
      );

      try {
        // Step 1: Process the search query by ChatGPT.
        const query = await toast.promise(
          runQueryThroughChatGPT(data.queryStringParameters.actors),
          {
            error: I18n.get(`We couldn't dissect your query. Searching as is.`),
            loading: I18n.get(`Analysing your query.`),
            success: I18n.get(`Successfully dissected your query.`),
          }
        );

        // Step 2: Fetch relevant nodes and edges.
        const { body: bodyFetch } = await toast.promise(
          get({
            apiName: 'main',
            options: {
              queryParams: {
                query,
                expanded_nodeids: JSON.stringify(
                  data.queryStringParameters.expanded_nodeids
                ),
                USER_GROUP: this.user.subscriptionPlan ?? SubscriptionPlan.FREEMIUM,
                DATASETS: data.entityTypes.join(','),
                breadth: data.breadth.toString(),
              },
            },
            path: '/freemium_fetch',
          }).response,
          {
            error: I18n.get(`Concepts and connections couldn't be fetched.`),
            loading: I18n.get(`Fetching concepts and connections.`),
            success: I18n.get(`Concepts and connections successfully fetched.`),
          }
        );

        const responseFetchJSON = await bodyFetch.json();
        this.fetchedData = responseFetchJSON as unknown as APIResponseFetch;

        // Step 3: Save user's latest query for persistance and analytics.
        await toast.promise(this.updateUserLastQuery(data.queryStringParameters.actors), {
          error: I18n.get(`Couldn't save your query.`),
          loading: I18n.get(`Persisting your query.`),
          success: I18n.get(`Query persisted.`),
        });

        // Step 4: Enrich previously fetched nodes and edges with more metadata.
        const { body: bodyFill } = await toast.promise(
          post({
            apiName: 'main',
            path: '/freemium_fill',
            options: {
              body: {
                edge_ids: this.fetchedData.edges,
                node_ids: this.fetchedData.nodes,
                USER_GROUP: this.user?.subscriptionPlan ?? SubscriptionPlan.FREEMIUM,
                DATASETS: restructuredEntityTypesDataset,
              },
            },
          }).response,
          {
            error: I18n.get(`Results enriching failed.`),
            loading: I18n.get(`Enriching results.`),
            success: I18n.get(`Successfully enriched results.`),
          }
        );

        const responseFillJSON = await bodyFill.json();
        this.graphData = this.formatGraph(
          responseFillJSON as unknown as APIResponseFetch
        );

        callback();
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
          toast.error(
            I18n.get(`An error occurred. Retry if you don't see what you're looking for.`)
          );
        }
      }
    }
  };

  fetchNodeEdgeInfo = async (
    uploadedData: any,
    callback: () => void,
    error: () => void
  ) => {
    const USER_NAME = this.user?.email;

    const PAYLOAD = {
      USER_GROUP: this.user?.subscriptionPlan,
      USER_NAME: USER_NAME,
      formattedGraphData: uploadedData.formattedGraphData,
    };

    const GRAPH_FETCH_OPTIONS = {
      method: 'POST',
      mode: 'cors' as RequestMode, // no-cors, *cors, same-origin
      headers: {
        Authorization: this.user?.jwtToken?.toString() ?? '',
      },
      // Combine search data with first cognito group name
      body: JSON.stringify(PAYLOAD),
    };

    const { body } = await post({
      apiName: 'main',
      path: '/freemium_upload',
      options: { body: GRAPH_FETCH_OPTIONS },
    }).response;

    const response = await body.json();
    this.graphData = this.formatGraph(response as unknown as APIResponseFill);

    callback();
  };

  updateUserLastQuery = async (query: string) => {
    await post({
      apiName: 'main',
      path: '/userupdate',
      options: {
        body: {
          cluster: FREEMIUM,
          id: this.user?.email ?? '',
          query: query,
          mode: 'write',
        },
      },
    }).response;
  };

  fetchGraph = async (data: SearchSettings, callback: () => void, error: () => void) => {
    const USER_NAME = this.user?.email;

    const parsedDatasets = data.entityTypes.reduce<
      Record<
        'DISEASES' | 'DRUGS' | 'GENES' | 'LIT' | 'PATHOGENS' | 'PATHWAYS' | 'PROTEINS',
        boolean
      >
    >(
      (prev, curr) => {
        switch (curr) {
          case 'Disease':
            return { ...prev, DISEASES: true };
          case 'Drug':
            return { ...prev, DRUGS: true };
          case 'Gene':
            return { ...prev, GENES: true };
          case 'NExTNet: Literature':
          case 'Scientific Literature':
            return { ...prev, LIT: true };
          case 'Pathogen':
            return { ...prev, PATHOGENS: true };
          case 'Pathway':
            return { ...prev, PATHWAYS: true };
          case 'Protein':
            return { ...prev, PROTEINS: true };
          default:
            return prev;
        }
      },
      {
        DISEASES: false,
        DRUGS: false,
        GENES: false,
        LIT: false,
        PATHOGENS: false,
        PATHWAYS: false,
        PROTEINS: false,
      }
    );

    const PAYLOAD = {
      USER_GROUP: this.user?.subscriptionPlan,
      query: await runQueryThroughChatGPT(data.queryStringParameters.actors),
      expanded_nodeids: data.queryStringParameters.expanded_nodeids,
      breadth: data.breadth,
      USER_NAME: USER_NAME,
      DATASETS: parsedDatasets,
    };

    const { body } = await get({
      apiName: 'main',
      path: 'freemium_graph',
      options: {
        body: JSON.stringify(PAYLOAD),
      },
    }).response;

    const response = await body.json();

    if (response && typeof response === 'string') {
      const body = JSON.parse(response);
      this.graphData = this.formatGraph(body);
      callback();
    }
  };

  formatGraph = (rawData: APIResponseFill): ComponentProps<typeof Graph>['graphData'] => {
    const nodeMap: Map<string | number, Node> = new Map();
    const linkMap: Map<string | number, Link> = new Map();

    const graphData: ComponentProps<typeof Graph>['graphData'] = {
      links: [],
      nodes: [],
    };
    //node is each element in nodes, defined by an array of node metadata
    const formattedNodes = rawData.nodes.map<Node>((node) => ({
      ...node,
      kind: 'node',
      merged: false,
      neighbors: [],
      links: [],
      doiReferences: node.doi,
    }));

    // Construct nodeMap. This is for (roughly) O(1) access
    // Hash with its ID and type- this is because node id's can be the same across types
    formattedNodes.forEach((node) => {
      node.id && nodeMap.set(node.id, node);
    });

    rawData.edges.forEach((edge) => {
      const sourceNode = nodeMap.get(edge.source);
      const targetNode = nodeMap.get(edge.target);

      if (sourceNode && targetNode) {
        const formattedLink =
          edge.type === 'Scientific Literature' || edge.type === 'NExTNet: Literature'
            ? {
                ...edge,
                kind: 'link',
                source: sourceNode,
                target: targetNode,
              }
            : {
                ...edge,
                kind: 'link',
                source: sourceNode,
                target: targetNode,
                journal_title: edge['Journal Title'],
                year_published: edge.Year,
                volume: edge.Volume,
                issue: edge.Issue,
                page: edge.Page,
              };

        graphData.links.push(formattedLink);
      }
    });

    graphData.links.forEach((link) => {
      linkMap.set(link.id, link);
    });

    for (const link of graphData.links) {
      const sourceNode = link.source;
      const targetNode = link.target;

      if (isNode(sourceNode) && isNode(targetNode)) {
        sourceNode.neighbors.push(targetNode);
        sourceNode.links.push(link);

        if (sourceNode.id !== targetNode.id) {
          targetNode.neighbors.push(sourceNode);
          targetNode.links.push(link);
        }
      }
    }

    return { nodes: formattedNodes, links: graphData.links };
  };
}

export { GraphFetcher };
