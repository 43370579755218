import type { SearchSettings } from 'pages/discover/discover.types';
import { type ComponentProps } from 'react';
import type { FormattedGraphType } from 'utils/types';

import { Graph } from '../graph.component';
import { isNode } from './is-node';

const formatGraphData = (
  searchSettings: SearchSettings,
  graphData: Required<ComponentProps<typeof Graph>>['graphData']
) => {
  // let formattedGraphData: GraphDataStringified = {
  //     links: [],
  //     nodes: [],
  //     errorMessage: "",
  //     keywords: [],
  // };

  const formattedGraphData: FormattedGraphType = {
    links: [],
    nodes: [],
  };

  // //console.log(graphData)
  // let testNode : Node = graphData.nodes[0]
  // //console.log({...testNode, links:'g', neighbors:'g'})

  formattedGraphData.nodes = graphData.nodes.map((node) => {
    return {
      id: node.id,
      x: node.x,
      y: node.y,
      // ...node,
      // links: node.links.map(link => link.id),
      // neighbors: node.neighbors.map(neighbor => neighbor.id)
    };
  });

  formattedGraphData.links = graphData.links.map((link) => {
    if (link.type === 'NExTNet Semantic Link') {
      return {
        label: 'NExTNet Semantic Link',
        type: 'NExTNet Semantic Link',
        target: isNode(link.target) ? link.target.id : undefined,
        source: isNode(link.source) ? link.source.id : undefined,
        id: link.id,
        name: 'NExTNet Semantic Link',
      };
    } else {
      return {
        id: link.id,
        // ...link,
        // source: link.source.id,
        // target: link.source.target
      };
    }
  });

  const PAYLOAD = {
    searchSettings: searchSettings,
    formattedGraphData: formattedGraphData,
  };

  return PAYLOAD;
  //this.loadGraph(PAYLOAD.formattedGraphData, null, null); // build error: Argument of type 'null' is not assignable to parameter of type '() => void'.
  // this.loadGraph(JSON.stringify(PAYLOAD), ()=>{}, ()=>{})
};

export { formatGraphData };
