const formatFindContext = (
  text: string,
  arg1?: string,
  arg2?: string,
  rel?: string,
  findNode?: string
) => {
  const formattedString: Array<JSX.Element> = [];
  const indices = [];

  //key point, we need to use indexOf since regex with parentheses can incur bugs

  if (findNode) {
    let position = text.indexOf(findNode);

    while (position !== -1) {
      indices.push(position);
      position = text.indexOf(findNode, position + 1);
    }
  }

  const indicesArg1 = []; // for context and sentence indices
  const indicesArg2 = [];
  const indicesRel = [];

  if (arg1) {
    let position = text.indexOf(arg1);
    while (position !== -1) {
      indicesArg1.push(position);
      position = text.indexOf(arg1, position + 1);
    }
  }

  if (arg2) {
    let position = text.indexOf(arg2);
    while (position !== -1) {
      indicesArg2.push(position);
      position = text.indexOf(arg2, position + 1);
    }
  }

  if (rel) {
    let position = text.indexOf(rel);
    while (position !== -1) {
      indicesRel.push(position);
      position = text.indexOf(rel, position + 1);
    }
  }

  // //console.log(indicesArg1, indicesArg2, indicesRel)

  for (let i = 0; i < text.length; i++) {
    let boldFlag = 0;

    indicesArg1.forEach((idx) => {
      if (arg1 && i >= idx && i < idx + arg1.length) {
        boldFlag = 2;
      }
    });
    indicesArg2.forEach((idx) => {
      if (arg2 && i >= idx && i < idx + arg2.length) {
        boldFlag = 2;
      }
    });
    indicesRel.forEach((idx) => {
      if (rel && i >= idx && i < idx + rel.length) {
        boldFlag = 2;
      }
    });

    indices.forEach((idx) => {
      if (findNode && i >= idx && i < idx + findNode.length) {
        boldFlag = 1;
      }
    });

    if (boldFlag === 1) {
      formattedString.push(<strong style={{ color: 'Red' }}>{text[i]}</strong>);
    } else if (boldFlag === 2) {
      formattedString.push(
        <strong style={{ color: 'MediumSeaGreen' }}>{text[i]}</strong>
      );
    } else {
      formattedString.push(<>{text[i]}</>);
    }
  }

  return formattedString;
};

export { formatFindContext };
