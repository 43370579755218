import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC } from 'react';
import { FiDownload, FiMaximize, FiZoomIn, FiZoomOut } from 'react-icons/fi';

import { Button } from '../button';
import { type ControlsProps } from './controls.types';

/**
 * Various controls allowing users to interact with currently displayed graph.
 *
 * @author Malik Alimoekhamedov
 * @see ControlsProps
 */
const Controls: FC<ControlsProps> = ({
  onZoomIn,
  onZoomOut,
  onReframe,
  /* onSave, */
  onDownload,
  onUpload,
  className,
}) => (
  <div className={clsx('flex gap-x-4', className)}>
    <Button
      icon={<FiZoomIn />}
      title={I18n.get('Zoom In')}
      onClick={onZoomIn}
      disabled={!onZoomIn}
    />
    <Button
      icon={<FiZoomOut />}
      title={I18n.get('Zoom Out')}
      onClick={onZoomOut}
      disabled={!onZoomOut}
    />
    <Button icon={<FiMaximize />} title={I18n.get('Reframe')} onClick={onReframe} />
    {/* <Button icon={<FiSave />} title={I18n.get('Save')} onClick={onSave} /> */}
    <Button icon={<FiDownload />} title={I18n.get('Download')} onClick={onDownload} />
    {/* <Button icon={<FiUpload />} title={I18n.get('Upload')} onClick={onUpload} /> */}
  </div>
);

export { Controls };
