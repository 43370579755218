import { replacePmcWithDoi } from '../../../../../../../utils';
import { formatFindContext } from '../../../utils';

const formatMSigDB = (MSigString: string) => {
  const tmp = MSigString.split(', ');
  const MSigArr = [];

  for (const gene of tmp) {
    MSigArr.push({ link: `${gene}`, gene: `${gene}, ` }); // `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`
  }

  const MSigMap = MSigArr.map(({ link, gene }) => (
    <a key={gene} href={link} target={'_blank'} rel={'noreferrer'}>
      {gene}
    </a>
  ));

  return MSigMap;
};

const formatDOILinks = (DOILinks: string) => {
  const tmp = DOILinks.split(', ').slice(3, -2);
  const tmp_len = tmp.length;
  const DOIMap: Array<JSX.Element> = [];

  for (let i = 0; i < tmp_len; i++) {
    if (i === tmp_len - 1) {
      DOIMap.push(
        <a href={`https://doi.org/${tmp[i]}`} target={'_blank'} rel={'noreferrer'}>
          {tmp[i]}
        </a>
      );
    } else {
      DOIMap.push(
        <a href={`https://doi.org/${tmp[i]}`} target={'_blank'} rel={'noreferrer'}>
          {tmp[i]}
        </a>
      ); // https://doi.org/${DOI}
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      DOIMap.push(<a>, </a>);
    }
  }

  return DOIMap;
};

const formatProteinFunctions = (proteinFunc: string) => {
  // //console.log(proteinFunc.split(/PubMed:\d+/))
  // //console.log(proteinFunc.match(/PubMed:\d+/g))

  const proteinText = proteinFunc.split(/PubMed:\d+/);
  const pubMedIDs = proteinFunc.match(/PubMed:\d+/g);

  const formatted: Array<JSX.Element> = [];

  for (let i = 0; i < proteinText.length; i++) {
    formatted.push(<span>{formatFindContext(proteinText[i] ?? '')}</span>);

    if (pubMedIDs && i < pubMedIDs.length - 1) {
      const id = pubMedIDs[i]?.slice(7); // slicing to remove the initial "PubMed:"
      formatted.push(
        <a href={`${id}`} target={'_blank'} rel={'noreferrer'}>
          {pubMedIDs[i]}
        </a>
      ); // https://pubmed.ncbi.nlm.nih.gov/
    }
  }

  return <div> {formatted} </div>;
  // let tmp = proteinFunc.split('PubMed')
  // let MSigArr = []

  // for (let gene of tmp) {
  //     MSigArr.push({ 'link': `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`, 'gene': `${gene}, ` })
  // }

  // const MSigMap = MSigArr.map(({ link, gene }) => <a href={link} target="_blank">{gene}</a>)

  // return MSigMap
};

const formatFields = (data: Record<string, string>) => {
  const {
    neighbors,
    links,
    id,
    x,
    y,
    vx,
    vy,
    fx,
    fy,
    index,
    merged,
    nodelist,
    source,
    target,
    __indexColor,
    __controlPoints,
    __photons,
    negated,
    context_rel,
    passive,
    arg2,
    arg1,
    rel,
    tag,
    label,
    type,
    weight,
    name,
    kind,
    members_mapping,
    members_ezid,
    ...rest
  } = data;

  const result = Object.entries(rest).reduce<
    Record<
      string,
      { readonly value: string | JSX.Element | JSX.Element[]; readonly link?: string }
    >
  >((prev, curr) => {
    if (curr[0] in ['context', 'sentence']) {
      if (rest.arg1 && rest.arg2 && rest.rel) {
        return {
          ...prev,
          [curr[0]]: { value: formatFindContext(curr[1].replaceAll('\\n', ' ')) }, //TODO: Don't return any JSX.
        };
      }
    }

    if (curr[0] === 'path') {
      return {
        //hardcoding adjustment requested by Steven
        // https://www.ncbi.nlm.nih.gov/labs/pmc/articles/
        ...prev,
        'Link to Manuscript': { value: curr[1], link: replacePmcWithDoi(curr[1]) },
      };
    }

    if (curr[0] === 'members_symbolized') {
      // for msigdb genes, which will include several
      return { ...prev, [curr[0]]: { value: formatMSigDB(curr[1]) } };
    }

    if (curr[0].slice(-3) === 'url') {
      //url needed for MSIGDB links
      return { ...prev, [curr[0]]: { value: curr[1], link: curr[1] } };
    }

    if (curr[0] === 'function') {
      return { ...prev, [curr[0]]: { value: formatProteinFunctions(curr[1]) } };
    }

    if (curr[0] === 'doi') {
      return { ...prev, [curr[0]]: { value: formatDOILinks(curr[1]) } };
    }

    // this switch controls whether we introduce granularity for the find feature
    // currently no granularity exists
    if (curr[0] in ['fullName', 'Protein_names']) {
      return { ...prev, [curr[0]]: { value: formatFindContext(curr[1]) } };
    }

    if (curr[1].charAt(0) in ['[', '{']) {
      return {
        ...prev,
        [curr[0]]: { value: curr[1].replace(/'/g, '').slice(1, -1) },
      };
    }

    const format_lists = [
      'members_symbolized',
      'members_ezid',
      'members_mapping',
      'members',
    ];

    if (format_lists.includes(curr[0])) {
      return { ...prev, [curr[0]]: { value: curr[1].replace(/,/g, ', ') } };
    }

    if (curr[1] !== 'NA') {
      switch (curr[0]) {
        case 'HPA':
          return {
            ...prev,
            [curr[0]]: {
              value: curr[1],
              link: `https://www.proteinatlas.org/${curr[1]}`,
            },
          };
        case 'symbol': //HGNC genes
        case 'Gene': //Genes derived from proteins
          return {
            ...prev,
            [curr[0]]: {
              value: curr[1],
              link: `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${curr[1]}`,
            },
          };
      }
    }

    if (curr[0] === 'Uniprot' && curr[1] !== 'NA') {
      const URL = [];

      const tmp = curr[1].split(', ');
      for (const protein of tmp) {
        URL.push({ link: `${protein}`, protein: `${protein}` }); // https://www.uniprot.org/uniprot/
      }

      const URLMap = URL.map(({ link, protein }) => (
        <a
          key={protein}
          href={`https://www.uniprot.org/uniprot/${link.slice(2, -2)}`}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {protein}
        </a>
      ));

      return { ...prev, [curr[0]]: { value: URLMap.slice(2, -2) } };
    }

    return {};
  }, {});

  return result;
};

export { formatFields };
