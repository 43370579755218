import { get } from 'aws-amplify/api';
import { ConsoleLogger, I18n } from 'aws-amplify/utils';
import { toast } from 'react-hot-toast';

const logger = new ConsoleLogger('runQueryThroughChatGPT');

// HACK: Move this logic to the back-end.
const runQueryThroughChatGPT = async (query: string) => {
  try {
    const { body } = await get({
      apiName: 'main',
      path: '/chatgpt_query',
      options: {
        queryParams: { query },
      },
    }).response;

    const response = (await body.json()) as unknown as
      | Record<'keywords', ReadonlyArray<string>>
      | undefined;

    if (response && response['keywords']) {
      return response['keywords'].join(',');
    }

    return query;
  } catch (error) {
    logger.error(error);

    if (error instanceof Error) {
      toast.error(I18n.get(`We couldn't dissect your query. Searching as is.`));
    }

    return query;
  }
};

export { runQueryThroughChatGPT };
