import { I18n } from 'aws-amplify/utils';
import { Button, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiCheck, FiRefreshCw } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { EmailConfirmationSchema } from '../auth.validators';
import { Container } from '../components';

const initialValues = {
  code: '',
  email: '',
};

/**
 * EmailConfirmation component.
 *
 * @author Malik Alimoekhamedov
 * @see EmailConfirmationProps
 */
const EmailConfirmation: FC = () => {
  const { sendCode, resendCode } = useAuthContext();
  const [shouldDisplayEmailFields, setShouldDisplayEmailField] = useState(false);
  //const [shouldDisplayCodeFields, setShouldDisplayCodeFields] = useState(false);
  const navigate = useNavigate();

  //FIXME: Get rid of the forced typing once react-router-dom fixed the 'unknown' state typing issue.
  const { state } = useLocation() as { readonly state: { readonly email: string } };

  const handleResendCode = async (username: string) =>
    await resendCode(username, () => {
      toast.success(I18n.get('New verification code sent. Check your email.'));
    });

  const { handleChange, handleSubmit, values, errors, setFieldError, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: EmailConfirmationSchema,
      onSubmit: async (values) => {
        try {
          await sendCode({ ...values, email: state?.email ?? values.email }, () => {
            toast.success(I18n.get('Account verification succeeded. Please sign in.'));
            navigate('/auth/sign-in', { state });
          });
        } catch (error) {
          setFieldError('code', 'Error while confirming email');
        }
      },
      validateOnChange: false,
    });

  useEffect(() => {
    if (!state?.email) {
      setShouldDisplayEmailField(true);
    }
  }, [state?.email]);

  return (
    <Container title={I18n.get('Enter Verification Code')}>
      <form className={'w-full max-w-lg space-y-6'} onSubmit={handleSubmit}>
        {shouldDisplayEmailFields && (
          <Input
            name={'email'}
            label={I18n.get('Your email')}
            description={I18n.get('Please provide your email again')}
            value={values.email}
            error={errors.email}
            onChange={handleChange}
          />
        )}
        <Input
          name={'code'}
          label={I18n.get('Code')}
          value={values.code}
          error={errors.code}
          onChange={handleChange}
          required
          description={I18n.get(
            'Check for an email from ”no-reply@verificationemail.com”, you should receive the confirmation code.'
          )}
        />
        <Button
          className={'flex w-full justify-center'}
          type={'submit'}
          isLoading={isSubmitting}
          disabled={isSubmitting || !!errors.code || !!errors.email || !values.code}
          icon={<FiCheck />}
        >
          {I18n.get(isSubmitting ? 'Confirming...' : 'Confirm')}
        </Button>
        <Button
          className={'mx-auto'}
          icon={<FiRefreshCw />}
          shape={'BORDERLESS'}
          onClick={() => handleResendCode(state.email.replace('@', '_'))}
          colorTheme={'RED'}
          type={'button'}
        >
          {I18n.get('Resend Code')}
        </Button>
        <Link className={'text-blue-500'} to={I18n.get('Back to Sign in')}></Link>
      </form>
    </Container>
  );
};

export { EmailConfirmation };
