import type { loader } from 'app.loader';
import { Cache, I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { IS_MAIN_MENU_COLLAPSED } from 'constants/cache';
/* import { NotificationPuck } from 'pages/components/notification-puck'; */
import { type FC, useState } from 'react';
import {
  FiAlignJustify,
  FiAlignLeft,
  /* FiBell, */
  /* FiBook, */
  FiFolderPlus,
  /* FiHeadphones, */
  FiLayout,
  FiSearch,
  FiStar,
  FiUsers,
} from 'react-icons/fi';
import { useRouteLoaderData } from 'react-router-typesafe';

import companyLogo from './assets/company-logo.png';
import companyName from './assets/company-name.png';
import { Item, UserMenu } from './components';

/**
 * Main application menu.
 *
 * @author Malik Alimoekhamedov
 */
const MainMenu: FC = () => {
  const { isMainMenuCollapsed } = useRouteLoaderData<typeof loader>('root');
  const [isCollapsed, toggleIsCollapsed] = useState(isMainMenuCollapsed);

  const handleToggleIsCollapsed = async () => {
    toggleIsCollapsed(!isCollapsed);
    await Cache.setItem(IS_MAIN_MENU_COLLAPSED, !isCollapsed);
  };

  return (
    <nav
      id={'main-menu'}
      className={clsx(
        'flex h-screen flex-col content-start gap-y-2 pb-4 pt-6 transition-all duration-500',
        isCollapsed ? 'pl-2 md:w-16' : 'pl-5 md:w-56'
      )}
    >
      <div
        className={clsx(
          'relative -mt-1 mb-4 flex h-5 flex-nowrap items-center justify-start transition-all duration-500',
          isCollapsed ? 'w-12 gap-x-0 px-4' : 'w-48 gap-x-1.5 pl-[18px]'
        )}
      >
        <img
          src={companyLogo}
          alt={'Nextnet'}
          draggable={false}
          className={clsx(
            'transition-all duration-500',
            isCollapsed ? 'mx-0 h-0 w-0 opacity-0' : 'mx-1 h-5 w-5 opacity-100'
          )}
        />
        <img
          src={companyName}
          alt={'Nextnet'}
          draggable={false}
          className={clsx(
            'transition-all duration-500',
            isCollapsed ? 'w-0 opacity-0' : 'w-12 opacity-100'
          )}
        />
        <span
          className={clsx(
            '-mt-3 rounded bg-red-100 text-center text-[8px] font-bold uppercase text-red-500 ring-1 ring-inset ring-red-200 transition-all duration-500',
            isCollapsed ? 'w-0 opacity-0' : 'w-7 opacity-100'
          )}
        >
          beta
        </span>
        <button
          className={clsx(
            'absolute top-0 ml-auto h-5 w-5 justify-self-end text-neutral-600 transition-all duration-500 hover:text-neutral-400',
            isCollapsed ? 'right-3' : 'right-0'
          )}
          onClick={handleToggleIsCollapsed}
          onKeyUp={handleToggleIsCollapsed}
        >
          {isCollapsed ? (
            <FiAlignLeft className={'h-5 w-5'} />
          ) : (
            <FiAlignJustify className={'h-5 w-5'} />
          )}
        </button>
      </div>
      <Item
        to={'/dashboard'}
        icon={<FiLayout />}
        title={I18n.get('Dashboard')}
        isCollapsed={isCollapsed}
      />
      <Item
        to={'/discover'}
        icon={<FiSearch />}
        title={I18n.get('Discover')}
        isCollapsed={isCollapsed}
      />
      <Item
        to={'/my-data'}
        icon={<FiFolderPlus />}
        title={I18n.get('My Data')}
        isComingSoon
        isCollapsed={isCollapsed}
      />
      <Item
        to={'/my-team'}
        icon={<FiUsers />}
        title={I18n.get('My Team')}
        isComingSoon
        isCollapsed={isCollapsed}
      />
      <Item
        to={'/favorites'}
        icon={<FiStar />}
        title={I18n.get('Favorites')}
        isComingSoon
        isCollapsed={isCollapsed}
      />
      {/* <Item
      to={'/notifications'}
      icon={
        <span className={'relative block h-4'}>
          <FiBell />
          <NotificationPuck className={'absolute -right-2 -top-5'} />
        </span>
      }
      title={I18n.get('Notifications')}
      isComingSoon
    /> */}
      {/* <Item to={'/learn'} icon={<FiBook />} title={I18n.get('Learn')} isComingSoon /> */}
      {/*  <Item
      to={'/support'}
      icon={<FiHeadphones />}
      title={I18n.get('Support')}
      isComingSoon
    /> */}
      <UserMenu className={'align-self-end z-10 mt-auto'} isCollapsed={isCollapsed} />
    </nav>
  );
};

export { MainMenu };
