import { type Reducer } from 'react';
import type { GraphMetricsAPIResponse } from 'types';

import type { Node } from './components/graph/graph.types';

enum VIEW {
  GRAPH,
  STATISTICS,
}

type State = {
  readonly view?: VIEW;
  readonly statistics?: GraphMetricsAPIResponse;
  readonly focusedNode?: Node;
};

type ActionToggleView = {
  readonly type: 'TOGGLE_VIEW';
  readonly view?: State['view'];
};

type ActionSetStatistics = {
  readonly type: 'SET_STATISTICS';
  readonly statistics: State['statistics'];
};

type ActionSetFocusedNode = {
  readonly type: 'SET_FOCUSED_NODE';
  readonly node?: State['focusedNode'];
};

type Action = ActionToggleView | ActionSetStatistics | ActionSetFocusedNode;

const INITIAL_STATE: State = {
  view: VIEW.GRAPH,
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_VIEW':
      return {
        ...state,
        view: action.view === VIEW.STATISTICS ? VIEW.STATISTICS : VIEW.GRAPH,
      };
    case 'SET_STATISTICS':
      return {
        ...state,
        statistics: action.statistics ?? undefined,
      };
    case 'SET_FOCUSED_NODE':
      return {
        ...state,
        focusedNode: action.node ?? undefined,
      };
    default:
      return state;
  }
};

export { INITIAL_STATE, reducer, VIEW };
