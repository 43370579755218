import { routes } from 'app.routes';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
import { Loader } from 'components';
import { AuthProvider } from 'context';
import { Modal } from 'pages/components';
import type { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import * as config from './config';

Amplify.configure(
  process.env.ENV === 'prd' ? config.PRD_AMPLIFY_CONFIG : config.DEV_AMPLIFY_CONFIG,
  {
    API: {
      REST: {
        headers: async () => ({
          Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() ?? '',
        }),
      },
    },
  }
);

const router = createBrowserRouter(routes, {
  future: {
    // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
    v7_normalizeFormMethod: true,
  },
});

const App: FC = () => (
  <AuthProvider>
    <RouterProvider
      router={router}
      fallbackElement={
        <Modal open={true} onClose={() => undefined} showCloseIcon={false}>
          <div className={'flex flex-nowrap gap-x-2'}>
            <Loader />
            <span>{I18n.get('Loading...')}</span>
          </div>
        </Modal>
      }
    />
    <Toaster position={'top-right'} />
  </AuthProvider>
);

export { App };
