import { get } from 'aws-amplify/api';
import { NOTES_API } from 'constants/global';
import {
  Graph,
  graphUtils,
  type Link,
  type Node,
} from 'pages/discover/components/graph-view/components';
import type { SearchSettings } from 'pages/discover/discover.types';
import { type ComponentProps } from 'react';
import type { APIResponseFill, UserAccount } from 'types';

import type { FormattedGraphType, GraphData } from './types';

class GraphSaver {
  user: UserAccount;
  graphData: ComponentProps<typeof Graph>['graphData'];
  rawData: APIResponseFill;
  searchSettings: SearchSettings;

  constructor(user: UserAccount) {
    this.user = user;
    this.graphData = {
      nodes: [],
      links: [],
    };
    this.rawData = {
      edges: [],
      nodes: [],
    };
    this.searchSettings = {
      queryStringParameters: {
        actors: '',
        expanded_nodeids: [],
      },
      entityTypes: [
        'Disease',
        'Drug',
        'Gene',
        'NExTNet: Literature',
        'Pathogen',
        'Pathway',
        'Protein',
        'Scientific Literature',
      ],
      fetch_type: 'none',
      breadth: 300,
      WEIGHT_PERCENTILE_START: 5,
      WEIGHT_PERCENTILE_END: 100000,
    };
  }

  // TODO
  // NEED TO KEEP TRACK TREE STATE OR NOT
  formatGraphData = (searchSettings: SearchSettings, graphData: GraphData) => {
    const formattedGraphData: FormattedGraphType = {
      links: [],
      nodes: [],
    };

    formattedGraphData.nodes = graphData.nodes.map((node) => {
      return {
        id: node.id,
        x: node.x,
        y: node.y,
        // ...node,
      };
    });

    formattedGraphData.links = graphData.links.map((link) => {
      if (link.type === 'Semantic Link') {
        return {
          label: 'Semantic Link',
          type: 'Semantic Link',
          target: link.target.id,
          source: link.source.id,
          id: link.id,
          name: 'Semantic Link',
        };
      } else {
        return { id: link.id };
      }
    });

    const PAYLOAD = {
      searchSettings: searchSettings,
      formattedGraphData: formattedGraphData,
    };

    return PAYLOAD;
  };

  updateSearchSetting = (searchSetting: any) => {
    this.searchSettings = searchSetting;
  };

  formatRawData = (
    rawData: APIResponseFill
  ): ComponentProps<typeof Graph>['graphData'] => {
    const nodeMap: Map<string, Node> = new Map();
    const linkMap: Map<string, Link> = new Map();

    const graphData: ComponentProps<typeof Graph>['graphData'] = {
      links: [],
      nodes: [],
    };
    //node is each element in nodes, defined by an array of node metadata
    rawData.nodes.forEach((node) => {
      const newNode = Object.create({});

      for (const [key, value] of Object.entries(node)) {
        newNode[key] = value;
      }

      newNode.kind = 'node';
      newNode.merged = false; // flag we implement to detect if merged or not
      newNode.id = node.id;
      newNode.neighbors = [];
      newNode.links = [];
      newNode.name = node.name;
      newNode.type = node.type; //controls the color here

      graphData.nodes.push(newNode);
    });

    // Construct nodeMap. This is for (roughly) O(1) access
    // Hash with its ID and type- this is because node id's can be the same across types
    graphData.nodes.forEach((node) => {
      typeof node.id === 'string' && nodeMap.set(node.id, node);
    });

    rawData.edges.forEach((edge) => {
      const sourceNode = nodeMap.get(edge.source);
      const targetNode = nodeMap.get(edge.target);

      const newLink = Object.create({});

      if (sourceNode && targetNode) {
        // this copies all link entries
        for (const [key, value] of Object.entries(edge)) {
          newLink[key] = value;
        }

        // we overwrite the link entries here
        newLink.kind = 'link';
        newLink.id = edge.id;
        newLink.name = edge.name; //NEED TO INTRODUCE EDGE NAMES IN PAYLOAD
        newLink.source = sourceNode;
        newLink.target = targetNode;
        newLink.type = edge.type;

        graphData.links.push(newLink);
      }
    });

    graphData.links.forEach((link) => {
      linkMap.set(link.id, link);
    });

    for (const link of graphData.links) {
      if (graphUtils.isNode(link.source) && graphUtils.isNode(link.target)) {
        const sourceNode = link.source;
        const targetNode = link.target;

        sourceNode.neighbors.push(targetNode);
        sourceNode.links.push(link);

        if (sourceNode.id !== targetNode.id) {
          targetNode.neighbors.push(sourceNode);
          targetNode.links.push(link);
        }
      }
    }

    // Let's find n
    const CLUSTER_CUTOFF = 5; // If a node has more neighbors than this, it counts as a cluster
    const clusterNodes: Array<Node> = [];

    graphData.nodes.forEach((node) => {
      if (node.neighbors.length > CLUSTER_CUTOFF) {
        clusterNodes.push(node);
      }
    });

    clusterNodes.sort((a, b) => {
      if (a.type > b.type) {
        return -1;
      } else if (a.type < b.type) {
        return 1;
      } else {
        return 0;
      }
    });

    const radialArrange = (
      radius: number,
      center: [number, number],
      nodes: Array<Node>,
      final: boolean
    ) => {
      const n = nodes.length;

      for (let k = 0; k < n; k++) {
        const xPos = center[0] + Math.cos((2 * k * Math.PI) / n) * radius;
        const yPos = center[1] + Math.sin((2 * k * Math.PI) / n) * radius;

        const node = nodes[k];
        if (node) {
          if (final) {
            node.fx = xPos;
            node.fy = yPos;
          } else {
            node.x = xPos;
            node.y = yPos;
          }
        }
      }
    };

    radialArrange(400, [0, 0], clusterNodes, true);

    clusterNodes.forEach((cluster) => {
      if (cluster.fx !== undefined && cluster.fy !== undefined) {
        radialArrange(50, [cluster.fx, cluster.fy], cluster.neighbors, false);
      }
    });

    return graphData;
  };

  saveGraph = async (
    searchSettings: SearchSettings,
    graphData: Required<ComponentProps<typeof Graph>>['graphData']
  ) => {
    const PAYLOAD = graphUtils.formatGraphData(searchSettings, graphData);

    await this.saveGraphAPI(PAYLOAD);
  };

  saveGraphAPI = async (payload: object) => {
    if (this.user.jwtToken) {
      const data = {
        userID: 'ee',
        description: 'testD',
        title: 'test',
        graph: payload,
      };

      const response = await fetch(NOTES_API + 'quill-note', {
        method: 'POST',
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.user.jwtToken.toString(),
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json();
    }
  };

  fetchNodeEdgeInfo = async (data: any, callback: () => void, error: () => void) => {
    if (this.user.jwtToken) {
      const PAYLOAD = {
        USER_GROUP: this.user.subscriptionPlan,
        formattedGraphData: data,
      };

      const { body } = await get({
        apiName: 'main',
        path: '/freemium_upload',
        options: { body: JSON.stringify(PAYLOAD) },
      }).response;
      const response = await body.json();

      if (response && typeof response === 'string') {
        const body = JSON.parse(response);
        this.rawData = body;
        this.graphData = this.formatRawData(this.rawData);
        callback();
      }
    }
  };
}

export { GraphSaver };
