import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC } from 'react';
import { NavLink } from 'react-router-dom';

import type { ItemProps } from './item.types';

/**
 * Main menu item.
 *
 * @author Malik Alimoekhamedov
 * @see ItemProps
 */
const Item: FC<ItemProps> = ({
  icon,
  to,
  className,
  title,
  isPro,
  isComingSoon,
  isCollapsed,
}) => (
  <NavLink
    className={({ isActive }) =>
      clsx(
        'group flex flex-nowrap items-center rounded-md py-2 transition-all duration-500',
        isActive
          ? 'bg-white shadow-xl shadow-neutral-200 hover:ring-1 hover:ring-inset hover:ring-emerald-50'
          : 'bg-none',
        isComingSoon && 'pointer-events-none cursor-not-allowed opacity-30',
        isCollapsed ? 'w-12 gap-x-0 px-2' : 'w-48 gap-x-3 px-4',
        className
      )
    }
    to={to}
  >
    {({ isActive }) => (
      <>
        <div
          className={clsx(
            'rounded-md p-2 [&>*]:opacity-70',
            isActive
              ? 'bg-gradient-to-br from-indigo-500 from-10% to-sky-500 to-90% text-white shadow-md shadow-sky-200 group-hover:from-sky-500 group-hover:to-emerald-500 group-hover:text-white group-hover:shadow-emerald-200'
              : 'text-neutral-600 group-hover:text-neutral-400'
          )}
        >
          {icon}
        </div>
        <span
          className={clsx(
            'select-none truncate whitespace-nowrap text-sm transition-all duration-500',
            isActive
              ? 'font-medium text-sky-700 group-hover:text-emerald-700'
              : 'text-neutral-600 group-hover:translate-x-1 group-hover:text-neutral-400',
            isCollapsed ? 'w-0 opacity-0' : 'w-36 opacity-100'
          )}
        >
          {title}
        </span>
        {isComingSoon && (
          <span
            className={clsx(
              'ml-auto select-none justify-self-end rounded bg-sky-100 px-1 text-[8px] font-bold uppercase text-sky-500 ring-1 ring-inset ring-sky-200 transition',
              isCollapsed ? 'opacity-0' : 'opacity-100'
            )}
          >
            {I18n.get('SOON')}
          </span>
        )}
        {isPro && !isComingSoon && (
          <span
            className={
              'ml-auto select-none justify-self-end rounded bg-red-100 px-1 text-[8px] font-bold uppercase text-red-500 ring-1 ring-inset ring-red-200 transition group-hover:translate-x-1'
            }
          >
            {I18n.get('PRO')}
          </span>
        )}
      </>
    )}
  </NavLink>
);

export { Item };
