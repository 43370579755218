import { I18n } from 'aws-amplify/utils';
import { type FC } from 'react';

import { replacePmcWithDoi } from '../../../../../../../../utils';
import type { EdgeProps } from './edge.types';

const Edge: FC<EdgeProps> = ({ edge, onNodeClick }) => (
  <table
    className={
      'mt-6 w-full border-collapse divide-y divide-gray-100 border border-gray-100 bg-white text-left'
    }
  >
    <thead>
      <tr className={'bg-gray-100'}>
        <th className={'px-2 py-1 text-sm font-medium text-gray-600'}>
          {I18n.get('Attribute')}
        </th>
        <th className={'px-2 py-1 text-sm font-medium text-gray-600'}>
          {I18n.get('Value')}
        </th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(edge).map(([key, value]) => {
        //TODO: Refactor this garbage.
        if (
          ![
            '__indexColor',
            'id',
            'index',
            '__controlPoints',
            '__photons',
            'Unnamed: 0',
            'negated',
            'link',
            'tag',
            'passive',
            'label',
            'type',
            'kind',
            'rel',
            'confidence',
            'context_rel',
          ].includes(key)
        ) {
          let formattedKey = key;
          let formattedValue: { readonly value: string; readonly link?: string } = {
            value,
          };

          switch (key) {
            case 'path': {
              formattedKey = 'link to manuscript';
              const link = `${replacePmcWithDoi(value)}`;
              formattedValue = { value: link, link }; // https://www.ncbi.nlm.nih.gov/labs/pmc/articles/
              break;
            }
            case 'context':
            case 'sentence':
              if (edge.arg1 && edge.arg2 && edge.rel) {
                formattedValue = {
                  value: `${value} ${edge.arg1} ${edge.arg2} ${edge.rel}`,
                };
              }
              break;
          }

          return (
            <tr key={key} className={'even:bg-gray-50'}>
              <th className={'px-2 py-1 text-sm font-normal text-gray-900'}>
                {formattedKey}
              </th>
              <th
                className={
                  'cursor-pointer px-2 py-1 text-sm font-normal text-gray-900 hover:text-gray-400'
                }
                onClick={
                  key === 'source' || key === 'target'
                    ? () => onNodeClick(value)
                    : undefined
                }
              >
                {formattedValue.link ? (
                  <a href={formattedValue.link} target={'_blank'} rel={'noreferrer'}>
                    {formattedValue.value}
                  </a>
                ) : key === 'source' || key === 'target' ? (
                  value.id
                ) : (
                  formattedValue.value
                )}
              </th>
            </tr>
          );
        } else {
          return null;
        }
      })}
    </tbody>
  </table>
);

export { Edge };
