// Created by @fan-matt 02/26/21

/*
    A file for our colors! Please pull them from here, and 
    add as necessary.

    Keep in mind accessibility and color blindness
*/

// export const PRIMARY = "white";
// export const SECONDARY = "DarkSlateGray"; //#2f4f4f
// export const SECONDARY_DESATURATED = "#758a8a"; // for hover icons
// export const TERTIARY = "rgb(8 , 46 , 46)";
// export const ACCENT1 = "#E66100";

// export const PRIMARY = "white";
// export const SECONDARY = "#355e81"; //#2f4f4f
// export const SECONDARY_DESATURATED = "#33526e"; // for hover icons
// export const TERTIARY = "#1b476b";
// export const ACCENT1 = "#E66100";

// export const TEXT_LIGHT = "#f7f7f7";
// export const TEXT_DARK = "#070707";

export const PRIMARY = 'white';
export const SECONDARY = '#24282b';
export const SECONDARY_DESATURATED = '#24282b'; // for hover icons
export const TERTIARY = '#24282b';
export const TERTIARY_DARK = '#101519';
export const ACCENT1 = '#F39237';

// lighter 4584e0
// darker 344bbf

export const TEXT_LIGHT = '#f7f7f7';
export const TEXT_MEDIUM = '#f2f2f2';
export const TEXT_DARK = '#070707';

// Node types need to be set up with arrays due to how the opacity calculation is structured

export const GO = [95, 158, 160];
export const HPA = [3, 25, 38];
export const STRING = [255, 102, 128];
export const LITERATURE = [64, 191, 74];

export const HGNC = [255, 171, 25]; //purple does not look as good
export const LIT = [255, 171, 25]; // Gamma only

export const Uniprot = [69, 132, 224];
export const vertex = [69, 132, 224]; // Gamma only

//FINAL COLOR SCHEMES

export const Protein = [178, 166, 135]; //OCHRE // Gamma only
export const NExTNet = [69, 132, 224]; //BLUE
export const Gene = [255, 171, 25]; //YELLOW
// export const MSigDB = [150, 220, 74]; //LIME
export const Pathway = [150, 220, 74]; //LIME
export const Disease = [228, 69, 58]; //Cinnabar
export const Drug = [143, 122, 205]; //Middle Blue Purple
export const Pathogen = [167, 174, 180]; //Silver Chalice

// export const MSigDB = [150, 210, 74]; //LIME
