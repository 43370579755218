import { get, post } from 'aws-amplify/api';
import { SubscriptionPlan, type UserAccount } from 'types';

/**
 * @author Emmanuel Alofe, Malik Alimoekhamedov
 */
class Payment {
  username: string;
  email: string;
  name: string;
  currentCheckoutSessionId: string;
  jwtToken?: string;

  constructor(user: UserAccount) {
    this.username = user.username;
    this.email = user.email;
    this.name = `${user.firstName ?? ''} ${user.lastName ?? ''}`;
    this.currentCheckoutSessionId = '';
    this.jwtToken = user.jwtToken?.toString() ?? '';
  }

  createCheckoutSession = async () => {
    const PAYLOAD = {
      username: this.username,
      email: this.email,
      name: this.name,
      currentCheckoutSessionId: this.currentCheckoutSessionId,
      mode: 'create-checkout-session',
    };

    const { body } = await post({
      apiName: 'main',
      path: '/payment',
      options: { body: JSON.stringify(PAYLOAD) },
    }).response;

    const response = await body.json();

    if (response && typeof response === 'string') {
      const parsedData = JSON.parse(response);
      const checkoutSessionUrl = parsedData.url;
      const checkoutSessionId = parsedData.id;
      this.currentCheckoutSessionId = checkoutSessionId;
      window.open(checkoutSessionUrl, '_blank', 'noreferrer');
    }
  };

  manageCustomerAccount = async () => {
    const username = this.username;
    const email = this.email;
    const name = this.name;

    const PAYLOAD = {
      username: username,
      email: email,
      name: name,
      mode: 'get-customer-portal',
    };
    const PAYMENT_API_OPTIONS = {
      method: 'POST',
      mode: 'cors' as RequestMode, // no-cors, *cors, same-origin
      headers: {
        Authorization: this.jwtToken ?? '',
      },
      // Combine search data with first cognito group name
      body: JSON.stringify(PAYLOAD),
    };

    const { body } = await post({
      apiName: 'main',
      path: '/payment',
      options: { body: PAYMENT_API_OPTIONS },
    }).response;

    const response = await body.json();

    if (response && typeof response === 'string') {
      const url = JSON.parse(response);
      window.open(url, '_blank', 'noreferrer');
    }
  };

  checkCustomerSubscription = async (username: string, email: string, name: string) => {
    const { body } = await get({
      apiName: 'main',
      path: '/payment',
      options: {
        body: JSON.stringify({
          username,
          email,
          name,
          mode: 'check-customer-subscription',
        }),
      },
    }).response;

    const response = await body.json();

    if (response && typeof response === 'string') {
      const subscription = JSON.parse(response);

      switch (subscription) {
        case 'basic free':
          return SubscriptionPlan.FREEMIUM;
        case 'academic free':
        case 'academic paid':
          return SubscriptionPlan.FREEMIUM_X_EDU;
        default:
          return SubscriptionPlan.FREEMIUM;
      }
    }

    return SubscriptionPlan.FREEMIUM;
  };
}

export { Payment };
