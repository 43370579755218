import { type ComponentProps } from 'react';
import type { APIResponseFill } from 'types';

import { Graph } from '../graph.component';
import type { Link, Node } from '../graph.types';
import { isNode } from './is-node';

const mapRawDataToGraphDataNew = ({
  nodes,
  edges,
}: APIResponseFill): ComponentProps<typeof Graph>['graphData'] => {
  const nodeMap: Map<string | number, Node> = new Map();
  const linkMap: Map<string | number, Link> = new Map();

  const graphData: ComponentProps<typeof Graph>['graphData'] = {
    links: [],
    nodes: [],
  };

  //node is each element in nodes, defined by an array of node metadata
  graphData.nodes = nodes.map((node) => ({
    ...node,
    kind: 'node',
    merged: false,
    neighbors: [],
    links: [],
  }));

  // Construct nodeMap. This is for (roughly) O(1) access
  // Hash with its ID and type- this is because node id's can be the same across types
  graphData.nodes.forEach((node) => {
    node.id && nodeMap.set(node.id, node);
  });

  graphData.links = edges.map((edge) => {
    // The backend's sending us 'source' and 'target' as strings.
    // However, we want the associated Nodes.
    // Hence the rest spread.
    const { source, target, ...rest } = edge;

    return {
      ...rest,
      kind: 'link',
      source: nodeMap.get(source),
      target: nodeMap.get(target),
    };
  });

  graphData.links.forEach((link) => {
    linkMap.set(link.id, link);
  });

  graphData.links.forEach((link) => {
    if (isNode(link.source) && isNode(link.target)) {
      link.source.neighbors.push(link.target);
      link.source.links.push(link);

      if (link.source.id !== link.target.id) {
        link.target.neighbors.push(link.source);
        link.target.links.push(link);
      }
    }
  });

  // Let's find n
  const CLUSTER_CUTOFF = 5; // If a node has more neighbors than this, it counts as a cluster
  const clusterNodes: Array<Node> = [];

  graphData.nodes.forEach((node) => {
    if (node.neighbors.length > CLUSTER_CUTOFF) {
      clusterNodes.push(node);
    }
  });

  clusterNodes.sort((a, b) => {
    if (a.type > b.type) {
      return -1;
    } else if (a.type < b.type) {
      return 1;
    } else {
      return 0;
    }
  });

  const radialArrange = (
    radius: number,
    center: [number, number],
    nodes: Array<Node>,
    final: boolean
  ) => {
    const n = nodes.length;

    for (let k = 0; k < n; k++) {
      const xPos = center[0] + Math.cos((2 * k * Math.PI) / n) * radius;
      const yPos = center[1] + Math.sin((2 * k * Math.PI) / n) * radius;

      const node = nodes[k];
      if (node) {
        if (final) {
          node.fx = xPos;
          node.fy = yPos;
        } else {
          node.x = xPos;
          node.y = yPos;
        }
      }
    }
  };

  radialArrange(400, [0, 0], clusterNodes, true);

  clusterNodes.forEach((cluster) => {
    if (cluster.fx !== undefined && cluster.fy !== undefined) {
      radialArrange(50, [cluster.fx, cluster.fy], cluster.neighbors, false);
    }
  });

  //console.log("PRINTING GRAPH DATA")
  //console.log(graphData)
  return graphData;
};

export { mapRawDataToGraphDataNew };
