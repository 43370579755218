import { I18n } from 'aws-amplify/utils';
import { Button, Checkbox, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { toast } from 'react-hot-toast';
import { FiKey } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
  type AuthorizationValidationForm,
  AuthorizationValidationSchema,
} from '../auth.validators';
import { Container } from '../components';
import { type AuthorizationProps } from './authorization.types';

const initialValues: AuthorizationValidationForm = {
  email: '',
  password: '',
  newPassword: '',
  repeatPassword: '',
  shouldPersist: true,
};

/**
 * Authorization flow-supporting component.
 *
 * @author Malik Alimoekhamedov
 * @see AuthorizationProps
 */
const Authorization: FC<AuthorizationProps> = ({ className }) => {
  const { authorize } = useAuthContext();
  const navigate = useNavigate();
  const { handleChange, handleSubmit, values, errors, isSubmitting } = useFormik({
    initialValues,
    validationSchema: AuthorizationValidationSchema,

    validateOnChange: false,
    onSubmit: async (values) => {
      await toast.promise(
        authorize(values, () => navigate('/')),
        {
          error: I18n.get('Something went wrong'),
          success: I18n.get('Authorized'),
          loading: I18n.get('Authorizing...'),
        }
      );
    },
  });

  return (
    <div className={className}>
      <Container
        title={I18n.get('Authorization')}
        subtitle={I18n.get('Welcome to Shield (powered by MPA)')}
      >
        <form onSubmit={handleSubmit} className={'space-y-6'}>
          <Input
            name={'email'}
            error={errors.email}
            value={values.email}
            label={I18n.get('Email address')}
            placeholder={'you@example.com'}
            onChange={handleChange}
          />
          <Input
            name={'password'}
            error={errors.password}
            value={values.password}
            label={I18n.get('Current password')}
            type={'password'}
            placeholder={'Enter your password'}
            onChange={handleChange}
          />
          <Input
            name={'newPassword'}
            error={errors.newPassword}
            value={values.newPassword}
            label={I18n.get('New password')}
            type={'password'}
            placeholder={'Enter your password'}
            onChange={handleChange}
            description={I18n.get(
              'Your password should be at least 8 characters long, have lower and uppercase letters as well as one or more digits'
            )}
          />
          <Input
            name={'repeatPassword'}
            error={errors.repeatPassword}
            value={values.repeatPassword}
            type={'password'}
            label={I18n.get('Confirm new password')}
            placeholder={'Enter your password'}
            onChange={handleChange}
          />
          <div className={'flex items-center'}>
            <Checkbox
              name={'shouldPersist'}
              state={values.shouldPersist ? 'CHECKED' : 'UNCHECKED'}
              onChange={() => handleChange({})}
            />
            <label htmlFor={'remember-me'} className={'ml-2 block text-sm text-gray-900'}>
              {I18n.get('Remember me')}
            </label>
          </div>
          <Button
            className={'flex w-full justify-center'}
            icon={<FiKey />}
            type={'submit'}
            isLoading={isSubmitting}
          >
            {I18n.get('Authorize')}
          </Button>
        </form>
      </Container>
    </div>
  );
};

export { Authorization };
