export const NOTES_API = process.env.REACT_APP_NOTESAPI;
export const FREEMIUM = 'FREEMIUM';
export const FREEMIUM_EDU = 'FREEMIUM_X_EDU';
export const CLUSTERS = [FREEMIUM, FREEMIUM_EDU];

const entityTypes = [
  'Disease',
  'Drug',
  'Gene',
  'NExTNet: Literature',
  'Pathogen',
  'Pathway',
  'Protein',
  'Scientific Literature',
] as const;

const linkTypes = [
  'Protein_to_Protein',
  'Gene_TO_Drug',
  'NExTNet Semantic Link',
  'Gene_TO_Disease',
  'Gene_TO_Pathway',
  'Scientific Literature',
  'NExTNet: Literature',
  'connected',
] as const;

export { entityTypes, linkTypes };
