/**
 * Emphasize search terms within the source string for highlighted display.
 *
 * @author Emmanuel Alofe, Malik Alimoekhamedov
 * @category Utils
 * @param searchTerms Array of strings representing terms to highlight (or emphasize) within the source sting.
 * @param source The source string to emphasize terms within.
 * @returns
 */
const emphasizeSearchTermsWithinAPhrase = (
  searchTerms: ReadonlyArray<string>,
  source: string
) => {
  let emphasizedString = source;
  searchTerms.forEach((searchTerm) => {
    if (searchTerm.trim().length > 2)
      emphasizedString = emphasizedString.replace(
        new RegExp(searchTerm, 'gi'),
        (term) => `<em>${term}</em>`
      );
  });

  return emphasizedString;
};

export { emphasizeSearchTermsWithinAPhrase };
