import { Switch } from '@headlessui/react';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { FiGrid, FiList } from 'react-icons/fi';

import type { ViewToggleProps } from './view-toggle.types';

/**
 * A toggle that allows for list view switching (list/grid).
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see ViewToggleProps
 */
const ViewToggle: FC<ViewToggleProps> = ({ view, className, onChange }) => (
  <Switch
    checked={view === 'GRID'}
    onChange={() => onChange(view === 'LIST' ? 'GRID' : 'LIST')}
    className={clsx(
      'group flex flex-nowrap gap-x-1 rounded-md bg-neutral-100/70 p-1 ring-1 ring-inset ring-neutral-200 backdrop-blur',
      className
    )}
  >
    <div
      className={clsx(
        'rounded p-2',
        view === 'LIST' ? 'bg-neutral-300' : 'bg-transparent'
      )}
    >
      <FiList className={'h-3 w-3'} />
    </div>
    <div
      className={clsx(
        'rounded p-2',
        view === 'GRID' ? 'bg-neutral-300' : 'bg-transparent'
      )}
    >
      <FiGrid className={'h-3 w-3'} />
    </div>
  </Switch>
);

export { ViewToggle };
