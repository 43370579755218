/**
 * Returns the correct web url link for a scientific article
 *
 * @author Emmanuel Alofe, Malik Alimoekhamedov
 * @category Utils
 * @param url A string representing a web url
 * @returns The correct web url
 */
const replacePmcWithDoi = (url?: string) => {
  const prefix = 'https://www.ncbi.nlm.nih.gov/pmc/articles/';
  const len_prefix = prefix.length;
  if (url?.slice(len_prefix, len_prefix + 3) === '10.') {
    let doi = '';
    if (url?.slice(-1) === '/') {
      doi = url?.slice(len_prefix, -1);
    } else {
      doi = url?.slice(len_prefix);
    }
    return 'https://www.doi.org/' + doi;
  } else {
    return url;
  }
};

export { replacePmcWithDoi };
