import type { Link, Node } from '../graph.types';

/**
 * A simple TypeScript type-guard function verifying that the subject
 * of inspection is of 2D graph's Node type.
 *
 * @author Malik Alimoekhamedov
 * @see Node
 */
const isNode = (subject?: string | number | Node | Link): subject is Node =>
  subject && (subject as Node).kind === 'node' ? true : false;

export { isNode };
