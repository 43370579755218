import { type FC } from 'react';
import { Link } from 'react-router-dom';

import { Category } from '../category';
import type { LiteratureProps } from './literature.types';

/**
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Literature: FC<LiteratureProps> = ({ categories, doiReferences }) => (
  <>
    {Object.entries(categories).map((category) => (
      <Category
        key={category[0]}
        name={category[0] as 'Primitive Information'}
        data={category[1]}
      />
    ))}
    {doiReferences?.map((doiReference) => (
      <Link
        key={doiReference}
        to={'https://www.doi.org/' + doiReference}
        target={'_blank'}
      >
        {doiReference}
      </Link>
    ))}
  </>
);

export { Literature };
