import { clsx } from 'clsx';
import { type FC } from 'react';

import { type GaugeProps } from './gauge.types';

/**
 * Progress bar component.
 *
 * @author Malik Alimoekhamedov
 * @see GaugeProps
 */
const Gauge: FC<GaugeProps> = ({ className, entityType, value = 0, outOf }) => (
  <div
    className={clsx(
      'flex h-4 content-stretch rounded-full',
      {
        'bg-protein/20': entityType === 'Protein',
        'bg-drug/20': entityType === 'Drug',
        'bg-gene/20': entityType === 'Gene',
        'bg-pathway/20': entityType === 'Pathway',
        'bg-disease/20': entityType === 'Disease',
        'bg-literature/20':
          entityType === 'Scientific Literature' || entityType === 'NExTNet: Literature',
      },
      className
    )}
    aria-label={`${value}/${outOf}`}
  >
    <div
      className={clsx('min-w-[16px] rounded-full', {
        'bg-protein/50': entityType === 'Protein',
        'bg-drug/50': entityType === 'Drug',
        'bg-gene/50': entityType === 'Gene',
        'bg-pathway/50': entityType === 'Pathway',
        'bg-disease/50': entityType === 'Disease',
        'bg-literature/50':
          entityType === 'Scientific Literature' || entityType === 'NExTNet: Literature',
      })}
      style={{ width: Math.min((value / outOf) * 100, 100) }}
    />
  </div>
);

export { Gauge };
