import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { FiHome } from 'react-icons/fi';
import { Link, NavLink, useMatches } from 'react-router-dom';

import type { BreadcrumbsProps } from './breadcrumbs.types';

/**
 * Dynamic breadcrumbs navigation.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 * @see BreadcrumbsProps
 */
const Breadcrumbs: FC<BreadcrumbsProps> = ({ className }) => {
  const matches = useMatches();

  const crumbs = matches.filter((match) => match.handle);

  return (
    <nav className={clsx('flex', className)} aria-label={'Breadcrumbs'}>
      <ol className={'flex select-none items-center space-x-2'}>
        <li>
          <NavLink to={'/'} className={'text-neutral-400 hover:text-neutral-500'}>
            <FiHome className={'h-3 w-3 flex-shrink-0'} aria-hidden={'true'} />
            <span className={'sr-only'}>{I18n.get('Home')}</span>
          </NavLink>
        </li>
        {crumbs.map((crumb, index) => (
          // @ts-ignore
          <li className={'flex items-center'} key={index}>
            <svg
              className={'h-3 w-3 flex-shrink-0 text-neutral-300'}
              fill={'currentColor'}
              viewBox={'0 0 20 20'}
              aria-hidden={'true'}
            >
              <path d={'M5.555 17.776l8-16 .894.448-8 16-.894-.448z'} />
            </svg>
            <Link
              // @ts-ignore
              to={crumb.handle.to}
              className={
                'ml-2 text-xs font-medium text-neutral-500 hover:text-neutral-700'
              }
            >
              {/** @ts-ignore */}
              {crumb.handle.crumb.label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { Breadcrumbs };
