import { type ECharts, getInstanceByDom, init } from 'echarts';
import { useResizeObserver } from 'hooks';
import { type FC, memo, useEffect, useRef, useState } from 'react';

import type { EChartsContainerProps } from './echarts-container.types';

const EChartsContainer: FC<EChartsContainerProps> = memo(
  ({
    className,
    devicePixelRatio,
    onClick,
    option,
    renderer,
    width,
    height,
    isLoading = false,
    theme = 'default',
    getEchartsInstance,
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [chart, setChart] = useState<ECharts>();
    useResizeObserver(ref, () => chart?.resize());

    useEffect(() => {
      let chart: ECharts | undefined;

      if (ref.current) {
        chart = getInstanceByDom(ref.current);
      }

      if (ref.current) {
        if (!chart) {
          chart = init(ref.current, theme, {
            devicePixelRatio,
            renderer,
            width,
            height,
          });
        }

        chart.setOption(option);
        onClick && chart.on('click', onClick);
        isLoading ? chart.showLoading() : chart.hideLoading();
        getEchartsInstance && getEchartsInstance(chart);
        setChart(chart);
      }

      return () => chart && chart.dispose();
    }, [
      devicePixelRatio,
      getEchartsInstance,
      height,
      isLoading,
      onClick,
      option,
      renderer,
      theme,
      width,
    ]);

    return (
      <div
        style={{ width: width ? width : '100%', height: height ?? '700px' }}
        className={className}
        ref={ref}
      />
    );
  }
);

EChartsContainer.displayName = 'EChartsContainer';

export { EChartsContainer };
