import { clsx } from 'clsx';
import type { FC } from 'react';
import type { EntityType } from 'types';

import type { NodeTypePillProps } from './node-type-pill.types';

const types = {
  Disease: 'text-disease bg-disease/10 ring-disease/20',
  Drug: 'text-drug bg-drug/10 ring-drug/20',
  Protein: 'text-protein bg-protein/10 ring-protein/20',
  Gene: 'text-gene bg-gene/10 ring-gene/20',
  Pathway: 'text-pathway bg-pathway/10 ring-pathway/20',
  Pathogen: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  'Scientific Literature': 'text-literature bg-literature/10 ring-literature/10',
  'NExTNet: Literature': 'text-literature bg-literature/10 ring-literature/10',
} satisfies Record<EntityType, string>;

/**
 * A pill with its styling color coded based on node type.
 *
 * @author Malik Alimoekhamedov, Emmanuel Alofe
 * @category Components
 * @see NodeTypePillProps
 */
const NodeTypePill: FC<NodeTypePillProps> = ({ type }) => (
  <span className={'flex flex-wrap items-center gap-2 md:gap-4'}>
    {type && (
      <p
        className={clsx(
          types[type],
          'w-fit whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
        )}
      >
        {type}
      </p>
    )}
  </span>
);

export { NodeTypePill };
