/* eslint-disable no-self-assign */
/* eslint-disable no-fallthrough */
/* eslint-disable no-prototype-builtins */
import { I18n } from 'aws-amplify/utils';
import { Button } from 'components';
import { type ComponentProps, type FC, useEffect, useRef, useState } from 'react';
import { FiSkipBack, FiSkipForward, FiX } from 'react-icons/fi';
import { IoIosArrowDropdownCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { Rnd } from 'react-rnd';
import { Collapse } from 'reactstrap';
import type { EntityType } from 'types';
import type { CollapseToggle } from 'utils/types';

import type { Link, Node } from '../../graph.types';
import { formatFindContext, isNode } from '../../utils';
import { Edge, Node as NodeDetails } from './components';
import { type DetailsProps } from './details.types';
import { formatFields } from './utils/format-fields';

/* type Pathway = {
    genes: string;
    source: string;
}; */

/* const punchString = (str: string, substr: string): Array<string> => {
    let punchIndex: number = str.indexOf(substr);

    if (punchIndex === -1) {
        return [str];
    } else {
        return [str.substring(0, punchIndex), substr].concat(
            punchString(str.substring(punchIndex + substr.length), substr)
        );
    }
}; */

/* function punchActants(base: Array<string>, punch: string): Array<string> {
    let result: Array<Array<string>> = [];

    base.forEach((el) => result.push(punchString(el, punch)));

    return result.flat();
} */

/*
    The component for the details pane
    Props:
        className: The usual, useful for styled-components
*/
const Details: FC<DetailsProps> = ({
  item,
  onNodeClick,
  onLinkClick,
  onClose,
  onNavigateBackward,
  onNavigateForward,
}) => {
  // Build incident nodes table
  const [categoryToggle, setCategoryToggle] = useState<CollapseToggle>({});

  // history is used to keep track of the visited nodes/links in details pane
  // use this array like a linked list (slice to remove memory)
  // viewed item is used to toggle the item the ptr examines
  const [history, setHistory] = useState<Array<any>>([null]);
  const [historyPtr, setHistoryPtr] = useState<number | null>(null);
  const [viewedItem, setViewedItem] = useState<Node | Link | undefined>(item);
  const [sliceControl, setSliceControl] = useState<boolean>(true);

  const [scrollHist, setScrollList] = useState<Array<number>>([]);

  const [size, setSize] = useState<Required<ComponentProps<typeof Rnd>>['size']>();
  const [position, setPosition] = useState<
    Required<ComponentProps<typeof Rnd>>['position']
  >({
    x: 17,
    y: 70,
  });

  const ref = useRef<HTMLElement>(null);

  /* const HEADERS = ["type", "kind", "weight"]; */

  /* const PROTEIN_CATEGORIES = [
        "Primitive Information",
        "Tissue RNA Presence",
        "Blood RNA Presence",
        "Cell RNA Presence",
        "Single Cell RNA Presence",
        "Extended RNA Presence Metrics",
        "Pathology Prognostics (scroll down for tumor-specific metrics)",
        "Alternate Identifiers to Databases",
        "Evidence",
        "Uncategorized Features",
    ]; */

  /* const GENE_CATEGORIES = [
        "Primitive Information",
        "Alternate Identifiers to Similar Databases",
        "Tertiary Identifiers",
        "Metadata",
    ]; */

  // Whenever we add to the history, we need to first remove items above our current ptr
  // JS will cleanup from memory

  useEffect(() => {
    // checking for null items (background) and clicking on the same node
    // if (historyPtr != null && item != null && item.id === history[historyPtr].id) {
    //     return
    // }

    // initializing history
    if (historyPtr === null) {
      setHistory([item]);
      setScrollList([0]);
      //ref.current?.scrollTop = 0;
      setHistoryPtr(0);
    } else {
      // We only slice if a new node is visited (clicking on a new node instead of arrows)
      // The slice control is toggled if arrows are pressed as to maintain history
      // We additionally check to not implement another slice if this is the same node being examined
      if (sliceControl && item.id !== history[historyPtr].id) {
        setHistory([...history.slice(0, historyPtr + 1), item]);

        const curScroll = ref.current?.scrollTop;

        curScroll && setScrollList([...scrollHist.slice(0, historyPtr), curScroll, 0]);
        setHistoryPtr(historyPtr + 1);
      }

      //ref.current?.scrollTop = scrollHist[historyPtr];
      setSliceControl(true);
    }
  }, [history, historyPtr, item, scrollHist, sliceControl]);

  // Updates the currently viewed item whenever historyPtr is changed by the arrows
  useEffect(() => {
    if (historyPtr === null) {
      setViewedItem(undefined);
    } else {
      setViewedItem(history[historyPtr]);
      //onNodeClick(history[historyPtr]);
    }

    // const inputEl = useRef(null);
  }, [history, historyPtr]);

  // Each category (protein vs gene consists of a varying amoung of properties)
  // const handleCategories = (categories : Array<Category>) => {

  //     //Note ES6 does guarantee object order, but design wise better to use an array of objs
  //     return (
  //         Object.keys(categories).map((category : any) => {
  //             <div key={category}>
  //                 <Category category={category}/>
  //             </div>
  //         })
  //     )
  // }

  /*  const NextnetLink = ({ link }: NextnetLink) => {
        const data = link; // since we mapped an array of objects
        const id: string = data.id;

        if (data.arg1 === undefined || data.arg2 === undefined) {
            return <div />;
        }

        return (
            <div>
                <div
                    style={Flex}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        setCategoryToggle({ ...categoryToggle, [id]: !categoryToggle[id] });
                    }}>
                    <DropDown style={{ marginLeft: -1 }}>
                        {categoryToggle[id] ? (
                            <IoIosArrowDropdownCircle size={ICON_SIZE} />
                        ) : (
                            <IoIosArrowDroprightCircle size={ICON_SIZE} />
                        )}
                    </DropDown>

                    <StyledFieldTitle>
                        {data.arg1} <AiOutlineArrowRight size={"0.8rem"} style={{ marginBottom: "3px" }} /> {data.arg2}
                    </StyledFieldTitle>
                </div>
                <Collapse isOpen={categoryToggle[id]} style={{ paddingLeft: "20px" }}>
                    <div
                        onMouseDown={(e) => {
                            e.stopPropagation();
                        }}>
                        {formatFields(data)}
                    </div>
                </Collapse>
            </div>
        );
    }; */

  const MSigDBCategory = ({ catKey, catData }: { catKey: string; catData: any }) => {
    const id = catKey;

    return (
      <div>
        <button
          className={'flex content-center items-center'}
          onClick={(e) => {
            e.stopPropagation();
            setCategoryToggle({ ...categoryToggle, [id]: !categoryToggle[id] });
          }}
        >
          <h1 className={'text-sm font-bold'}>
            {categoryToggle[id] ? (
              <IoIosArrowDropdownCircle className={'h-4 w-4'} />
            ) : (
              <IoIosArrowDroprightCircle className={'h-4 w-4'} />
            )}
          </h1>
          <div className={'w-full text-sm'}>{`${id}`}</div>
        </button>
        <Collapse isOpen={categoryToggle[id]} style={{ paddingLeft: '20px' }}>
          <div
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            {Object.values(formatFields(catData)).map(({ value, link }, index) =>
              link ? (
                <a key={index} href={link}>
                  {value}
                </a>
              ) : (
                <span key={index}>{value}</span>
              )
            )}
          </div>
        </Collapse>
      </div>
    );
  };

  const formatStrArray = (strArray: string) => {
    return strArray.replace(/'/g, '').slice(1, -1);
  };

  const formatList = (strArray: string) => {
    return strArray.replace(/,/g, ', ');
  };

  const formatMSigDB = (MSigString: string) => {
    const tmp = MSigString.split(', ');
    const MSigArr = [];

    for (const gene of tmp) {
      MSigArr.push({ link: `${gene}`, gene: `${gene}, ` }); // `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`
    }

    const MSigMap = MSigArr.map(({ link, gene }) => (
      <a key={gene} href={link} target={'_blank'} rel={'noreferrer'}>
        {gene}
      </a>
    ));

    return MSigMap;
  };

  const formatDOILinks = (DOILinks: string) => {
    const tmp = DOILinks.split(', ').slice(3, -2);
    const tmp_len = tmp.length;
    const DOIMap: Array<JSX.Element> = [];

    for (let i = 0; i < tmp_len; i++) {
      if (i === tmp_len - 1) {
        DOIMap.push(
          <a href={`https://doi.org/${tmp[i]}`} target={'_blank'} rel={'noreferrer'}>
            {tmp[i]}
          </a>
        );
      } else {
        DOIMap.push(
          <a href={`https://doi.org/${tmp[i]}`} target={'_blank'} rel={'noreferrer'}>
            {tmp[i]}
          </a>
        ); // https://doi.org/${DOI}
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        DOIMap.push(<a>, </a>);
      }
    }

    return DOIMap;
  };

  const formatProteinFunctions = (proteinFunc: string) => {
    const proteinText = proteinFunc.split(/PubMed:\d+/);
    const pubMedIDs = proteinFunc.match(/PubMed:\d+/g);

    const formatted: Array<JSX.Element> = [];

    for (let i = 0; i < proteinText.length; i++) {
      formatted.push(<span>{formatFindContext(proteinText[i] ?? '')}</span>);

      if (pubMedIDs != null && i < pubMedIDs.length - 1) {
        const id = pubMedIDs[i]?.slice(7); // slicing to remove the initial "PubMed:"
        formatted.push(
          <a href={`${id}`} target={'_blank'} rel={'noreferrer'}>
            {pubMedIDs[i]}
          </a>
        ); // https://pubmed.ncbi.nlm.nih.gov/
      }
    }

    return <div>{formatted}</div>;
    // let tmp = proteinFunc.split('PubMed')
    // let MSigArr = []

    // for (let gene of tmp) {
    //     MSigArr.push({ 'link': `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${gene}`, 'gene': `${gene}, ` })
    // }

    // const MSigMap = MSigArr.map(({ link, gene }) => <a href={link} target="_blank">{gene}</a>)

    // return MSigMap
  };

  // default values infer the type, hence we do not need to specify them
  // const formatFindNode = ((text : string)  => {

  //     if (findNode === "") {
  //         return text
  //     }

  //     let formattedString: Array<JSX.Element> = [];

  //     let result, indices = []
  //     let regex = new RegExp(findNode, "gi");
  //     while ( (result = regex.exec(text)) ) {
  //         indices.push(result.index);
  //     }

  //     for (let i = 0; i < text.length; i++) {

  //         let boldFlag = 0

  //         indices.forEach(idx => {
  //             if (i >= idx && i < idx + findNode.length) {
  //                 boldFlag = 1
  //             }
  //         })

  //         if (boldFlag === 1) {
  //             formattedString.push(<strong style={{ color: "Red" }}>{text[i]}</strong>);
  //         }
  //         else{
  //             formattedString.push(<>{text[i]}</>);
  //         }
  //     }

  //     return formattedString
  // })

  useEffect(() => {
    const newCategoryToggle = Object.create({});
    // Iterate through categories and set the collapsed state accordingly

    if (isNode(viewedItem)) {
      if (viewedItem.kind === 'link') {
        // We do not wish to adjust the category dropdowns if working with a link
        return;
      }

      if (
        viewedItem.type === 'Protein' ||
        viewedItem.type === 'Gene' ||
        viewedItem.type === 'Disease' ||
        viewedItem.type === 'Drug' ||
        viewedItem.type === 'Pathogen'
      ) {
        for (const cat of Object.keys(viewedItem['categories'])) {
          newCategoryToggle[cat] = false;
        }
      } else if (
        viewedItem.type === 'NExTNet: Literature' ||
        viewedItem.type === 'Scientific Literature'
      ) {
        for (const link of viewedItem['links']) {
          // Going through each object in links
          newCategoryToggle[link.id] = false;
        }
      } else if (viewedItem.type === 'Pathway') {
        newCategoryToggle['gene'] = false;
      }
    }
    setCategoryToggle(newCategoryToggle);
  }, [viewedItem]);

  return (
    <Rnd
      className={
        'z-10 overflow-auto rounded-md bg-white/80 shadow-xl shadow-gray-200 ring-1 ring-gray-200 backdrop-blur-lg'
      }
      position={position}
      size={size}
      bounds={'parent'}
      minWidth={'320px'}
      minHeight={'52px'}
      maxHeight={'60vh'}
      maxWidth={'520px'}
      onDragStop={(_, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(_, __, ref) =>
        setSize({
          width: ref.style.width,
          height: ref.style.height,
        })
      }
    >
      <div
        className={
          'flex w-full flex-nowrap items-center gap-x-2 overflow-hidden rounded-t-md bg-gray-800 p-3'
        }
      >
        <Button
          colorTheme={'GRAY'}
          disabled={!onNavigateBackward}
          onClick={onNavigateBackward}
        >
          <FiSkipBack />
        </Button>
        <Button
          colorTheme={'GRAY'}
          disabled={!onNavigateForward}
          onClick={onNavigateForward}
        >
          <FiSkipForward />
        </Button>
        <span className={'overflow-hidden text-ellipsis whitespace-nowrap text-gray-300'}>
          {item.name}
        </span>
        {onClose && (
          <Button
            shape={'BORDERLESS'}
            className={'ml-auto justify-self-end'}
            onClick={onClose}
          >
            <FiX />
          </Button>
        )}
      </div>
      <div className={'flex h-[calc(60vh-56px)] flex-col gap-y-4 overflow-scroll p-3'}>
        <h1 className={'font-semibold'}>{item.type}</h1>
        <span className={'font-light text-neutral-600'}>{item.name}</span>
        {item['tagged_notes'] && (
          <div className={'text-sm'}>
            <span className={'font-bold'}>{I18n.get('Tagged Notes')}:</span>
            {item['tagged_notes']}
          </div>
        )}
        <div>
          {item.type && item.id && isNode(item) && (
            <NodeDetails
              id={item.id as string}
              categories={item.categories}
              links={item.links}
              onNodeClick={onNodeClick}
              onLinkClick={onLinkClick}
              type={item.type}
              genes={item.genes}
              doiReferences={item.doi}
            />
          )}
        </div>
        {!isNode(item) && <Edge edge={item} onNodeClick={onNodeClick} />}
      </div>
    </Rnd>
  );
};

export { Details };
