import { type ComponentProps, type Reducer } from 'react';
import { type ForceGraphProps } from 'react-force-graph-2d';
import type { GraphMetricsAPIResponse } from 'types';

import type { Settings } from './components';
import { ZOOM_LEVEL_MAX, ZOOM_LEVEL_MIN } from './graph.component';
import { type Link, type Node } from './graph.types';
import { getAdjacentNodes, isNode } from './utils';

type State = {
  readonly view: 'GRAPH' | 'TREE';

  /**
   * All graph's zoom-related values.
   */
  readonly zoom: Parameters<Required<ForceGraphProps<Node, Link>>['onZoom']>[number];

  /**
   * De-emphasize the rest of the graph upon hovering over a single node?
   *
   * @default true
   */
  readonly shouldDimTheRestOfTheGraphOnNodeHover: boolean;

  /**
   * What element is currently under the mouse pointer?
   *
   * @default undefined
   */
  readonly hoveredElement?: Node | Link;
  readonly focusedElement?: Node | Link;
  readonly highlightedNodes?: ReadonlyArray<Node>;
  readonly highlightedLinks?: ReadonlyArray<Link>;

  readonly queriedNodeIds?: ReadonlySet<string>;

  readonly data?: ForceGraphProps<Node, Link>['graphData'];
  readonly statistics?: GraphMetricsAPIResponse;

  readonly isStatisticsPanelOpen?: boolean;
  readonly isAnnotationPanelOpen?: boolean;
  readonly focusedNodeID?: string;
  readonly history?: ReadonlyArray<Node | Link>;
  readonly settings: Pick<
    ComponentProps<typeof Settings>,
    'repulsion' | 'nodeDistanceMax'
  > & {
    readonly isGraphSettingsPanelOpen: boolean;
    readonly CLUSTER: boolean;
    readonly FADE: boolean;
    readonly TAGGED: boolean;
  };
};

type ActionToggleView = {
  readonly type: 'TOGGLE_VIEW';
  readonly view?: State['view'];
};

type ActionZoomIn = { readonly type: 'ZOOM_IN' };

type ActionZoomOut = { readonly type: 'ZOOM_OUT' };

type ActionSetZoom = {
  readonly type: 'SET_ZOOM';
  readonly zoom?: State['zoom'];
};

type ActionSetFocusNoteOnHover = {
  readonly type: 'SET_FOCUS_NOTE_ON_HOVER';
  readonly shouldDimTheRestOfTheGraphOnNodeHover?: State['shouldDimTheRestOfTheGraphOnNodeHover'];
};

type ActionSetHoveredElement = {
  readonly type: 'SET_HOVERED_ELEMENT';
  readonly hoveredElement?: State['hoveredElement'];
};

type ActionSetHighlightedNodes = {
  readonly type: 'SET_HIGHLIGHTED_NODES';
  readonly nodes?: State['highlightedNodes'];
};

type ActionSetHighlightedLinks = {
  readonly type: 'SET_HIGHLIGHTED_LINKS';
  readonly links?: State['highlightedLinks'];
};

type ActionSetQueriedNodeIDs = {
  readonly type: 'SET_QUERIED_NODE_IDS';
  readonly queriedNodeIds?: State['queriedNodeIds'];
};

type ActionSetStatistics = {
  readonly type: 'SET_STATISTICS';
  readonly statistics: State['statistics'];
};

type ActionToggleStatisticsPanel = {
  readonly type: 'TOGGLE_STATISTICS_PANEL';
};

type ActionToggleAnnotationPanel = {
  readonly type: 'TOGGLE_ANNOTATION_PANEL';
};

type ActionSetFocusedElement = {
  readonly type: 'SET_FOCUSED_ELEMENT';
  readonly focusedElement?: State['focusedElement'];
};

type ActionHistorySwitch = {
  readonly type: 'HISTORY_SWITCH';
  readonly element: Node | Link;
};

type ActionSetSettings = {
  readonly type: 'SET_SETTINGS';
  readonly settings?: State['settings'];
};

type ActionToggleGraphSettingsPanel = {
  readonly type: 'TOGGLE_GRAPH_SETTINGS_PANEL';
  readonly isGraphSettingsPanelOpen?: State['settings']['isGraphSettingsPanelOpen'];
};

type Action =
  | ActionToggleView
  | ActionZoomIn
  | ActionZoomOut
  | ActionSetZoom
  | ActionSetFocusNoteOnHover
  | ActionSetHoveredElement
  | ActionSetHighlightedNodes
  | ActionSetHighlightedLinks
  | ActionSetQueriedNodeIDs
  | ActionSetStatistics
  | ActionToggleStatisticsPanel
  | ActionToggleAnnotationPanel
  | ActionToggleGraphSettingsPanel
  | ActionSetFocusedElement
  | ActionSetSettings
  | ActionHistorySwitch;

const INITIAL_STATE: State = {
  view: 'GRAPH',
  zoom: { k: 1, x: 0, y: 0 },
  shouldDimTheRestOfTheGraphOnNodeHover: true,
  isStatisticsPanelOpen: false,
  settings: {
    isGraphSettingsPanelOpen: false,
    repulsion: -800,
    nodeDistanceMax: 1000,
    CLUSTER: true, // TODO: Ditch?
    FADE: true, // TODO: Ditch?
    TAGGED: true, // TODO: Ditch?
  },
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_VIEW':
      return { ...state, view: action.view ?? 'GRAPH' };
    case 'ZOOM_IN':
      return {
        ...state,
        zoom: {
          ...state.zoom,
          k: state.zoom.k >= ZOOM_LEVEL_MAX ? ZOOM_LEVEL_MAX : state.zoom.k + 0.5,
        },
      };
    case 'ZOOM_OUT':
      return {
        ...state,
        zoom: {
          ...state.zoom,
          k: state.zoom.k <= ZOOM_LEVEL_MIN ? ZOOM_LEVEL_MIN : state.zoom.k - 0.5,
        },
      };
    case 'SET_ZOOM':
      return {
        ...state,
        zoom: {
          ...state.zoom,
          k: action.zoom
            ? action.zoom.k > ZOOM_LEVEL_MAX
              ? ZOOM_LEVEL_MAX
              : action.zoom.k < ZOOM_LEVEL_MIN
                ? ZOOM_LEVEL_MIN
                : action.zoom.k
            : 1,
        },
      };
    case 'SET_FOCUS_NOTE_ON_HOVER':
      return {
        ...state,
        focusNodeOnHover: action.shouldDimTheRestOfTheGraphOnNodeHover ?? true,
      };
    case 'SET_HOVERED_ELEMENT':
      return {
        ...state,
        hoveredElement: action.hoveredElement ?? undefined,
      };
    case 'SET_HIGHLIGHTED_NODES':
      return { ...state, highlightedNodes: action.nodes ?? undefined };
    case 'SET_HIGHLIGHTED_LINKS':
      return { ...state, highlightedLinks: action.links ?? undefined };
    case 'SET_QUERIED_NODE_IDS':
      return { ...state, queriedNodes: action.queriedNodeIds ?? undefined };
    case 'TOGGLE_STATISTICS_PANEL':
      return { ...state, isStatisticsPanelOpen: !state.isStatisticsPanelOpen };
    case 'TOGGLE_ANNOTATION_PANEL':
      return { ...state, isAnnotationPanelOpen: !state.isAnnotationPanelOpen };
    case 'TOGGLE_GRAPH_SETTINGS_PANEL':
      return {
        ...state,
        settings: {
          ...state.settings,
          isGraphSettingsPanelOpen: action.isGraphSettingsPanelOpen ?? false,
        },
      };
    case 'SET_FOCUSED_ELEMENT':
      return {
        ...state,
        focusedElement: action.focusedElement,
        highlightedNodes:
          state.data && action.focusedElement
            ? isNode(action.focusedElement)
              ? getAdjacentNodes(action.focusedElement, state.data)
              : isNode(action.focusedElement.source) &&
                  isNode(action.focusedElement.target)
                ? [(action.focusedElement.source, action.focusedElement.target)]
                : undefined
            : undefined,
        highlightedLinks: isNode(action.focusedElement)
          ? action.focusedElement?.links
          : action.focusedElement
            ? [action.focusedElement]
            : undefined,
        history: action.focusedElement
          ? state.history
            ? [...state.history, action.focusedElement]
            : [action.focusedElement]
          : state.history,
      };
    case 'HISTORY_SWITCH':
      return {
        ...state,
        focusedElement: state.history?.includes(action.element)
          ? action.element
          : state.focusedElement,
      };
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.settings ?? INITIAL_STATE.settings,
      };
    default:
      return state;
  }
};

export { INITIAL_STATE, reducer };
