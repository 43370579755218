import { I18n } from 'aws-amplify/utils';
import { type FC } from 'react';

// TODO: Beef this page up. It's supposed to provide the initial explanations,
// search examples one can launch automatically with a press of a button,
// what other people are searching or one's search history for quick re-run.

/**
 * A component displayed when there was no search performed yet.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const Placeholder: FC = () => (
  <div className={'flex w-full flex-1 items-center justify-center'}>
    <h1 className={'select-none text-xl font-medium text-gray-500'}>
      {I18n.get('Start your discovery now!')}
    </h1>
  </div>
);

export { Placeholder };
