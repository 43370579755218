import { I18n } from 'aws-amplify/utils';
import { Button, Input, Loader } from 'components';
import { type FC, useState } from 'react';
import { FiSearch, FiSliders } from 'react-icons/fi';

import { Settings } from './components';
import { type SearchControlsProps } from './search-controls.types';

// TODO: Populate the search field with auto suggestions.

/**
 * Main search field with options.
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const SearchControls: FC<SearchControlsProps> = ({
  search,
  onChange,
  onSearch,
  isLoading = false,
}) => {
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  return (
    <div className={'flex w-full max-w-lg flex-nowrap gap-x-2'}>
      <Input
        className={'flex-1'}
        icon={isLoading ? <Loader /> : <FiSearch />}
        placeholder={I18n.get('Search Nextnet')}
        name={'query'}
        id={'query'}
        type={'search'}
        disabled={isLoading}
        value={search.query}
        onChange={({ target: { value } }) => onChange({ ...search, query: value })}
        onKeyUp={(event) => {
          event.key === 'Enter' && onSearch();
        }}
      />
      {search.query.length > 0 && <Button icon={<FiSearch />} onClick={onSearch} />}
      <Settings
        open={isSettingsDialogOpen}
        search={search}
        onChange={onChange}
        onSearch={() => {
          onSearch();
          setIsSettingsDialogOpen(false);
        }}
        onClose={() => setIsSettingsDialogOpen(false)}
      />
      {search.query.length > 0 && (
        <Button
          icon={<FiSliders />}
          shape={'BORDERLESS'}
          colorTheme={'GRAY'}
          onClick={() => setIsSettingsDialogOpen(true)}
        />
      )}
    </div>
  );
};

export { SearchControls };
