import { I18n } from 'aws-amplify/utils';
import { Button, Checkbox, Input } from 'components';
import { useAuthContext } from 'context';
import { useFormik } from 'formik';
import { type FC } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { type SignInValidationForm, SignInValidationSchema } from '../auth.validators';
import { Container } from '../components';

const initialValues: SignInValidationForm = {
  email: '',
  password: '',
  shouldPersist: false,
};

/**
 * 'SignIn' dialog
 *
 * @author Malik Alimoekhamedov
 * @category Components
 */
const SignIn: FC = () => {
  const { signIn } = useAuthContext();
  const navigate = useNavigate();

  //FIXME: Get rid of the forced typing once react-router-dom fixed the 'unknown' state typing issue.
  const { state } = useLocation() as {
    readonly state: { readonly email: string };
  };

  const { handleChange, handleSubmit, values, errors, isSubmitting, setFieldValue } =
    useFormik({
      initialValues: { ...initialValues, email: state?.email ?? '' },
      validationSchema: SignInValidationSchema,
      validateOnChange: false,
      onSubmit: async (values, { setFieldError }) => {
        try {
          await signIn(values, () => navigate('/dashboard'));
        } catch (error) {
          if (error instanceof Error) {
            setFieldError('email', error.message);
          }
        }
      },
    });

  return (
    <Container title={I18n.get('Sign In')}>
      <form onSubmit={handleSubmit} className={'space-y-6'}>
        <Input
          name={'email'}
          label={I18n.get('Email')}
          placeholder={I18n.get('Enter your email')}
          onChange={handleChange}
          value={values.email}
          error={errors.email}
          type={'email'}
          required={true}
          autoComplete={'email'}
        />

        <Input
          name={'password'}
          label={I18n.get('Password')}
          placeholder={I18n.get('Enter your password')}
          onChange={handleChange}
          value={values.password}
          error={errors.password}
          type={'password'}
          required={true}
          autoComplete={'current-password'}
        />
        <div className={'flex items-center justify-between gap-2'}>
          <div className={'flex items-center'}>
            <Checkbox
              name={'shouldPersist'}
              state={values.shouldPersist ? 'CHECKED' : 'UNCHECKED'}
              onChange={() => setFieldValue('shouldPersist', !values.shouldPersist)}
            />
            <label
              htmlFor={'shouldPersist'}
              className={
                'ml-2 block select-none whitespace-nowrap text-sm text-neutral-900'
              }
            >
              {I18n.get('Remember me')}
            </label>
          </div>

          <div className={'text-sm'}>
            <Link
              to={'/auth/forgot-password'}
              className={
                'select-none whitespace-nowrap font-medium text-blue-600 hover:text-blue-400'
              }
            >
              {I18n.get(' Forgot password?')}
            </Link>
          </div>
        </div>

        <Button
          type={'submit'}
          className={'flex w-full justify-center'}
          isLoading={isSubmitting}
          icon={<FiLogIn />}
        >
          {isSubmitting ? I18n.get('Signing In...') : I18n.get('Sign In')}
        </Button>
        <p
          className={
            'flex select-none justify-between gap-4 text-center text-sm text-neutral-600'
          }
        >
          {I18n.get('Don’t have an account?')}
          <Link
            to={'/auth/sign-up'}
            className={'font-medium text-blue-600 hover:text-blue-400'}
          >
            {I18n.get('Sign Up')}
          </Link>
        </p>
      </form>
    </Container>
  );
};

export { SignIn };
