import { I18n } from 'aws-amplify/utils';
import { clsx } from 'clsx';
/* import { ContextualMenu } from 'pages/components/contextual-menu'; */
import { /* type ComponentProps, */ type FC } from 'react';
/* import { FiEye, FiStar, FiThumbsDown } from 'react-icons/fi'; */
import sanitizeHTML from 'sanitize-html';

import { emphasizeSearchTermsWithinAPhrase } from '../../utils';
import { Separator } from '../separator';
import type { ItemProps } from './item.types';

/* const contextualMenuItems: ComponentProps<typeof ContextualMenu>['items'] = [
  { icon: <FiEye />, onClick: () => console.log('Viewed'), title: I18n.get('View') },
  {
    icon: <FiThumbsDown />,
    onClick: () => console.log('Marked as irrelevant'),
    title: I18n.get('Mark as irrelevant'),
  },
  {
    icon: <FiStar />,
    onClick: () => console.log('Added to favourites'),
    title: I18n.get('Add to favourites'),
  },
]; */

/**
 * A unique list item for the list view of the discover section.
 *
 * @author Malik Alimoekhamedov, Emmanuel Alofe
 * @see ItemProps
 */
const Item: FC<ItemProps> = ({
  id,
  sentence,
  context,
  type,
  journalTitle,
  yearPublished,
  volume,
  issue,
  articleLink,
  page,
  authors,
  isPrivate = false,
  onClick,
  searchTerms,
}) => (
  <li className={'list-none'}>
    <button
      className={clsx(
        'group relative flex h-full w-full flex-col justify-start justify-items-start gap-y-3 rounded-md bg-white px-5 py-4 text-left text-sm shadow-lg ring-1 ring-inset hover:bg-sky-50 hover:ring-sky-100 md:p-6',
        isPrivate ? 'ring-emerald-600/20' : 'ring-neutral-100'
      )}
      onClick={onClick}
      onKeyUp={onClick}
    >
      {isPrivate && (
        <p
          className={
            'absolute -top-2.5 w-fit whitespace-nowrap rounded-md bg-emerald-50 px-1.5 py-0.5 text-xs font-medium capitalize text-emerald-800 ring-1 ring-inset ring-emerald-600/20'
          }
        >
          {I18n.get('Your data')}
        </p>
      )}
      <div className={'flex w-full justify-between'}>
        <h1
          className={
            'line-clamp-3 font-medium text-neutral-900 transition group-hover:text-sky-800 [&>em]:rounded-sm [&>em]:bg-yellow-200 [&>em]:font-semibold [&>em]:not-italic'
          }
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(
              searchTerms
                ? emphasizeSearchTermsWithinAPhrase(searchTerms, sentence)
                : sentence
            ),
          }}
        />
        {/* <ContextualMenu items={contextualMenuItems} /> */}
      </div>
      <span className={'flex flex-wrap items-center gap-2 md:gap-4'}>
        <Separator
          type={type}
          journalTitle={journalTitle}
          yearPublished={yearPublished}
          volume={volume}
          issue={issue}
        />
      </span>
      {/* <p className={'line-clamp-1 text-xs text-neutral-300'}>
        {Array.isArray(authors) ? authors?.join(', ') : authors}
      </p> */}
      <p
        className={
          'line-clamp-3 text-sm font-light text-neutral-400 transition group-hover:text-sky-500 [&>em]:rounded-sm [&>em]:bg-yellow-200 [&>em]:font-medium [&>em]:not-italic'
        }
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(
            searchTerms
              ? emphasizeSearchTermsWithinAPhrase(searchTerms, context)
              : context
          ),
        }}
      />
    </button>
  </li>
);

export { Item };
