import { Tab } from '@headlessui/react';
import type { loader } from 'app.loader';
import { ConsoleLogger } from 'aws-amplify/utils';
import { clsx } from 'clsx';
import { type FC, useCallback, useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { FiBarChart2, FiShare2 } from 'react-icons/fi';
import { useRouteLoaderData } from 'react-router-typesafe';
import type { UserAccount } from 'types';
import { GraphSaver } from 'utils';

import { Graph, graphUtils, Statistics } from './components';
import { INITIAL_STATE, reducer, VIEW } from './graph-view.state';
import { type GraphViewProps } from './graph-view.types';
import { fetchGraphStatistics, parseGraphMetricsAPIResponse } from './utils';

const getGraphSaver = (userAccount: UserAccount) => new GraphSaver(userAccount);

const logger = new ConsoleLogger('GraphView');

const GraphView: FC<GraphViewProps> = ({
  className,
  graphData,
  expandingNode,
  searchSettings,
  onExpandNode,
  ...props
}) => {
  //const [detailsCollapse, setDetailsCollapse] = useState<boolean>(false);
  const { user } = useRouteLoaderData<typeof loader>('root');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const graphSaver = useCallback(() => user && getGraphSaver(user), [user])();
  //handles saving of the graph, also needs to make sure graph is not empty, used to be toggleSave
  const handleSaveGraph = () =>
    graphData && graphSaver?.saveGraph(searchSettings, graphData);

  const handleDownload = () => {
    if (graphData) {
      const PAYLOAD = graphUtils.formatGraphData(searchSettings, graphData);
      handleDownloadFile(PAYLOAD); // calls function in outer scope (Search) to handle graph download if possible (as JSON for now)
    }
  };

  const handleDownloadFile = (PAYLOAD: any) => {
    // download file on react: https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server

    // create file in browser
    const fileName = 'graph';
    const json = JSON.stringify(PAYLOAD, (k, v) => (v === undefined ? null : v), 1); // (formattedGraphData, null, 2)
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  // Fetch graph metrics based on the fetched graph data.
  // TODO: This might need to be moved elsewhere, such as the graph fetcher, for example. Leaving it here for the sake of saving some time.
  useEffect(() => {
    const fetchData = async (user: Parameters<typeof fetchGraphStatistics>[1]) => {
      if (graphData) {
        const response = await fetchGraphStatistics(
          graphData,
          user,
          searchSettings.entityTypes
        );

        response?.node_metrics &&
          dispatch({
            type: 'SET_STATISTICS',
            statistics: response,
          });
      }
    };

    if (user && graphData) {
      try {
        fetchData(user);
      } catch (error) {
        logger.error(error);

        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    }
  }, [graphData, searchSettings.entityTypes, user]);

  return (
    <Tab.Group
      as={'div'}
      className={clsx('relative flex h-full flex-col items-start gap-4')}
      selectedIndex={state.view}
      onChange={(index: number) => dispatch({ type: 'TOGGLE_VIEW', view: index })}
    >
      <Tab.List
        className={
          'absolute right-4 top-4 z-10 flex gap-x-1 rounded-md bg-neutral-100/70 p-1 ring-1 ring-inset ring-neutral-200 backdrop-blur'
        }
      >
        <Tab
          className={
            'rounded bg-transparent p-2 transition-colors ui-selected:bg-neutral-300 ui-not-selected:bg-transparent'
          }
        >
          <FiShare2 className={'h-3 w-3'} />
        </Tab>
        <Tab
          className={
            'rounded p-2 transition-colors ui-selected:bg-neutral-300 ui-not-selected:bg-transparent'
          }
        >
          <FiBarChart2 className={'h-3 w-3'} />
        </Tab>
      </Tab.List>
      <Tab.Panels
        className={
          'absolute inset-0 flex-1 overflow-hidden rounded-md bg-white/75 shadow-lg ring-1 ring-inset ring-gray-100 backdrop-blur-xl'
        }
      >
        <Tab.Panel className={'h-full'}>
          <Graph
            {...props}
            graphData={graphData}
            onToggleView={() => dispatch({ type: 'TOGGLE_VIEW' })}
            onSave={handleSaveGraph}
            onDownload={handleDownload}
            onUpload={() => console.log('Uploaded') /* handleUpload */}
            focusedNode={state.focusedNode}
            onNodeClick={(node) => dispatch({ type: 'SET_FOCUSED_NODE', node })}
            onExpandNode={onExpandNode}
          />
        </Tab.Panel>
        <Tab.Panel className={'h-full'}>
          {state.statistics && (
            <Statistics
              categories={parseGraphMetricsAPIResponse(
                state.statistics.node_metrics,
                (nodeId) => {
                  const node = graphData?.nodes?.find((node) => node.id === nodeId);
                  dispatch({ type: 'TOGGLE_VIEW', view: VIEW.GRAPH });
                  dispatch({ type: 'SET_FOCUSED_NODE', node });
                }
              )}
            />
          )}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export { GraphView };
